/* *****************************************************************
* ButtonsOdc: Ant design radio, check buttons:
*
* See doc here:
*   - https://ant.design/components/radio/
*   - https://ant.design/components/checkbox/
*   - etc.
********************************************************************* */

import React, {useState} from "react";
import {Checkbox, Radio, Row } from "antd";

export const RadioListOdc = React.memo((props) => {
    /* --------------------------------------------------------------------
    *   List of radio buttons in a group.
    *   Props:
    *       - checkList: list of radio options. ex: ['Origin', 'Destination']
    *       - onCheck:      method on change, that takes the selected id from the option list
    *       - defaultIdx:   default value index to be selected
    *       - direction:    'vertical' or 'horizontal'. Default: null ==> adjust the direction automatically
    *       - apiProps: other props from API to define for ex the button style: see https://ant.design/components/radio#radiogroup
    * -------------------------------------------------------------------- */

    // const direction = props.direction ? props.direction : 'horizontal';
    const apiProps = props.apiProps? props.apiProps: {};

    const checkList = [...props.checkList];

    const defaultIdx = props.defaultIdx ? props.defaultIdx : 0;   // first value selected by default

    const [radioValue, setRadioValue] = useState(defaultIdx); // set to first radio group option by default

    const onCheck = (e) => {
        // console.log('\nradio checked in layout:', e.target.value);
        setRadioValue(e.target.value);
        // console.log('radio value in layout aft setting: ', radioValue); // will not appear directly: see https://stackoverflow.com/questions/54069253/the-usestate-set-method-is-not-reflecting-a-change-immediately
        props.onCheck && props.onCheck(e.target.value);
    };

    return (
        <Radio.Group onChange={onCheck} value={radioValue} {...apiProps}>
            {/*<Space direction={direction} align={'start'} size={'small'} wrap={true}>*/}
                {checkList.map((opt, idx) => {
                    return (
                        <Radio value={idx} key={opt.toString()}>{opt}</Radio>
                    );
                })}
            {/*</Space>*/}
        </Radio.Group>
    );
});


export const CheckListOdc = React.memo((props) => {
    /* --------------------------------------------------------------------
    *   List of check buttons.
    *   Props:
    *       - checkList:    list of check options. ex: ['Tube', 'Bus', 'Train']
    *       - onCheck:     method on change, that completes the list of selected ids from the check list
    *       - orientation: 'vertical' or 'horizontal'. Default: 'vertical'
    *       - apiProps: other props from API to define for ex the button style: see https://ant.design/components/checkbox
    -------------------------------------------------------------------- */
    const checkList = [...props.checkList];
    const orientation = props.orientation ? props.orientation : 'vertical';
    const apiProps = props.apiProps? props.apiProps: {};


    // const [checkedList, setCheckedList] = useState([]);

    const onCheck = (checkedValues) => {
        // console.log('Checkbox checked', checkedValues);
        // setCheckedList(checkedValues);
        props.onCheck(checkedValues);
    };

    const checkItems = <Checkbox.Group onChange={onCheck} {...apiProps}>
        {checkList.map((opt, idx) => {
            if (orientation === 'vertical') {
                return (
                    <Row key={idx}>
                        <Checkbox value={idx} key={opt.toString()}>{opt}</Checkbox>
                    </Row>
                )
            } else {
                return (
                    <Checkbox value={idx} key={opt.toString()}>{opt}</Checkbox>
                )
            }
        })}
    </Checkbox.Group>;

    return checkItems;
});
