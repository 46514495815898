/* *****************************************************************
* Generic javascript functions implemented by ODC
****************************************************************** */

/* ******************************************
*   Template function description
* Props:
*   - val: description
* ****************************************** */


// export class OdcCls {
//     /* *****************************************************************
//     * ODC Generic Class
//     *   props:
//     *       - xxx
//     *
//     * Example call:
//     *   - xxx
//     *   -
//     ****************************************************************** */
//
//     constructor(props) {
//         /* Description:
//         *   - hanlde other formats than csv
//         * */
//         this.debug = props.debug ? props.debug : true;
//     }
//
// }

import axios from "axios";


export class LocalStorageOdc {
    /* *****************************************************************
    * ODC Local Storage Class: ONLY LIMITED TO 5MB!!!! Use LocalDBodc for more storage!
    *   props:
    *       - sessionOnly: deletes data after the user closes the tab. Default: true
    *
    * Example call:
    *   - xxx
    *   -
    ****************************************************************** */

    constructor(props) {
        /* Description:
        *   - hanlde other formats than csv
        * */
        this.sessionOnly = props ? props.sessionOnly ? props.sessionOnly : true : true;
        // this.debug = props.debug ? props.debug : true;
    }

    setValue(key, value) {
        /* Sets the value to local storage */
        if (typeof window !== undefined) {
            this.sessionOnly ?
                window.sessionStorage.setItem(key, JSON.stringify(value)) :
                window.localStorage.setItem(key, JSON.stringify(value));
        } else {
            printMsg('Local storage not available', 'error');
        }
    }

    getValue(key) {
        /* Gets the value from local storage */
        if (typeof window !== undefined) {
            const value = this.sessionOnly ? window.sessionStorage.getItem(key) : window.localStorage.getItem(key);
            return value ? JSON.parse(value) : null;
        } else {
            printMsg('Local storage not available', 'error');
        }
    }

    removeValue(key) {
        /* Deletes the value from local storage */
        this.sessionOnly ? window.sessionStorage.removeItem(key) : window.localStorage.removeItem(key);
    }

    clear() {
        /* Clear all from local storage */
        this.sessionOnly ? window.sessionStorage.clear() : window.localStorage.clear();
    }

}


export function loadFromAPI(props) {
    /* ---------------------------------------
    *  Function that uses axios to connect to an API and load data.
    *  Returns a promise: needs to be called inside an async function:
    * Props:
    *   - url: API url (FULL URL!)
    *   - method: 'GET' (default), 'POST', 'PUT', 'DELETE'
    *   - debug: true or false: to show the messages
    * Use example:
           const ComponentUsingApiData = () => {
           const url = 'full_url';
           const [data, setData] = useState(null);

           useEffect(() => {
               (async () => {
                    const apiData = await loadFromAPI({url: url}); // *** here ***
                    if (apiData) {
                        setData(apiData);
                    }
                    else {
                        setData(null);
                    }
                })();
           }, []);

           return data? <Component data={data} />: null
    * --------------------------------------- */
    const debug = props.debug ? props.debug : true;
    const method = props.method ? props.method : 'GET';

    return new Promise(function (resolve, reject) {
        axios({
            method: method,
            url: props.url,
        })
            .then((response) => {
                const res = response.data;
                debug && console.log('Fetching data from ', props.url);
                resolve(res);
            }).catch((error) => {
            reject(error);
            if (error.response) {
                console.log(error.response);
                console.log(error.response.status);
                console.log(error.response.headers);
            }
        })
    })
}


export function isInstance(object, instanceType) {
    /* ---------------------------------------
    *  Checks the object instance.
    *   instanceType will be among:
    *       - undefined
    *       - null
    *       - str
    *       - array
    *       - json
    *   if instance type not found, returns '???'
    * --------------------------------------- */
    const strConstructor = "test".constructor;
    const arrayConstructor = [].constructor;
    const objectConstructor = ({}).constructor;

    function getInstanceType(object) {
        if (object === null) {
            return "null";
        }
        if (object === undefined) {
            return "undefined";
        }
        if (object.constructor === strConstructor) {
            return "str";
        }
        if (object.constructor === arrayConstructor) {
            return "array";
        }
        if (object.constructor === objectConstructor) {
            return "json";
        }
        {
            return "???";
        }
    }

    if (getInstanceType(object) === instanceType) {
        return true;
    }
    return false;
}


export function roundToMultiple(number, multiple) {
    const rndVal = Math.round(number / multiple) * multiple;
    return rndVal;
}


export function maxFromObject(obj, field) {
    /* ******************************************
    *   Function that returns the max value from a list of objects, for a given field
    *   Ex:
    *       - obj = [
    *                   {'name': 'rd30', 'value': 10},
    *                   {'name': 'rd30', 'value': 12},
    *                   {'name': 'rd20', 'value': 4}]
    *       - field = 'value'
    *       ==> function will return 12
    * Props:
    *   - val: description
    * ****************************************** */
    /* extract the max among objects: */
    return Math.max(...obj.map(item => item[field]));
}

export function copyObj(obj) {
    /* ---------------------------------------
    *  Function that deeply copies an object, included nested objects
    * --------------------------------------- */
    return JSON.parse(JSON.stringify(obj));
}


export function getFileName(filePath) {
    const fileName = filePath && filePath.replace(/^.*[\\\/]/, '');
    return fileName;
}


export function printMsg(msg, msgType, debug) {
    /* ---------------------------------------
    *  Function that logs a message to the console
    * Props:
    *   - msg:      the message to be displayed
    *   - msgType:  null, 'error' or 'warning'
    *   - debug:    if set to false, message is not displayed
    * --------------------------------------- */
    const showMsg = debug ? debug : true; // only shows the message if debug is true

    if (!showMsg) return;

    const msgHead = {
        'error': '************ ERROR *************',
        'warning': '************ WARNING *************',
    };
    const msgTail = {
        'error': '*********************************',
        'warning': '*********************************',
    };
    msgType && console.log(msgHead[msgType]);
    console.log(msg);
    msgType && console.log(msgTail[msgType]);
}


export function formatNumber(number, nbDigits, decSep) {
    /* ******************************************
    *   Returns a number fromatted: ex: 10000.454564 ==> "10 000.45"
    * Props:
    *   - number: the number to be formatted
    *   - decSep: thousands separators: default ' '
    *   - nbDigits: rounded value
    * ****************************************** */
    let sep = decSep ? decSep : ' ';
    let acc = nbDigits ? nbDigits : 0;

    let dictLocal = {' ': 'fr-FR', ',': 'en-GB'};
    let local = dictLocal[sep] ? dictLocal[sep] : 'fr-FR';

    // // console.log('local: ', local, number.toFixed(acc).toLocaleString());
    /* Test first if number is a number: */
    if (typeof (number) !== 'number') {
        printMsg(`${number} passed to function "formatNumber" is not a number`, 'warning');
        return number;
    }
    let numbStr = number.toLocaleString(local, {maximumFractionDigits: acc});
    return numbStr;
}


export function getRandomColor() {
    /* ---------------------------------------
    *  Function that returns a random color in HTML format
    * --------------------------------------- */
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
}


export function isFloat(val) {
    /* ******************************************
    *   Function taht checks if a value is a float
    * Props:
    *   - val: value of any type
    * ****************************************** */
    if ((Number(val) === val) && ((val % 1) !== 0)) {
        return true;
    } else {
        return false;
    }
}


export function startTime() {
    /* ******************************************
    *   used together with endTime() to compute function execution time:
    *   >> const st = startTime();
    *   >> await myFunction();
    *   >> endTime(st);
    * ****************************************** */
    return Date.now();
}


export function endTime(start_time, comment) {
    /* ******************************************
    *   used together with startTime() to compute function execution time:
    *   >> const st = startTime();
    *   >> await myFunction();
    *   >> endTime(st);
    * ****************************************** */
    const max_ms = 5000;    // below 5000ms, we show the ms

    const comment2 = comment ? `** ${comment} ** ` : '';
    const end_time = Date.now();
    const dt_ms = end_time - start_time;
    const dt = new Date(dt_ms);
    // console.log(end_time-start_time, 'ms');
    const time_str = `${("0" + dt.getUTCHours()).slice(-2)}:${("0" + dt.getUTCMinutes()).slice(-2)}:${("0" + dt.getUTCSeconds()).slice(-2)}`
    const ms_str = (dt_ms < max_ms) ? ` (${dt_ms} ms)` : '';

    console.log(`Exec time ${comment2}: ${time_str}${ms_str}`);

    return end_time;
}

/* *************************************************************************************************************
*   Global variables
* ************************************************************************************************************* */

/*  Bootstrap breakpoints in pixels */
export const gridBreakpoints = {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
    xxl: 1400,
};
