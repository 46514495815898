/* *****************************************************************
* Documentation page component implemented by ODC
* Custom mardown style defined in:
*   ./css/markdown.module.css
*
* TO DO: CHECK HOW TO CREATE CUSTOM DOC WITH TABLE OF CONTENT ON THE SIDE
*   https://blog.logrocket.com/create-table-contents-highlighting-react/
*   https://www.emgoto.com/react-table-of-contents/
*   https://docusaurus.io/docs/next/markdown-features
*   https://tweak-extension.com/blog/app-docusaurus-react
* See doc here:
*   https://github.com/remarkjs/react-markdown
*   table of content:    https://github.com/remarkjs/remark-toc
*   Markdown syntax:
*       https://www.markdownguide.org/basic-syntax
*   Github syntax:
*       https://github.com/remarkjs/remark-gfm
*       https://github.github.com/gfm/
*
*
*   List of available plugins:
*       https://github.com/remarkjs/remark/blob/main/doc/plugins.md#list-of-plugins
****************************************************************** */

import React, {useState, useEffect} from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from "remark-gfm"; /*  for github style compatibility   */
// import remarkToc from "remark-toc"; /*  for table of content generation */
import remarkMdx from "remark-mdx"; /*  for JSX syntax support in md    */
// import rehypeRaw from "rehype-raw";  /* for using raw html in markdown   */

/*  For maths:  */
// import remarkMath from "remark-math";
// import rehypeKatex from 'rehype-katex'
// import 'katex/dist/katex.min.css';

/*  For router links:   */
import rehypeSlug from "rehype-slug";
// import rehypeAutolinkHeadings from "rehype-autolink-headings";

// import { Remark} from "react-remark";

// import remarkImages from "remark-images";
// import remarkEmbedImages from "remark-embed-images"
// import remarkCollapse from "remark-collapse"; /*  for collapsible sections DOES NOT WORK */


// import remarkParse from 'remark-parse'
// import remarkRehype from 'remark-rehype'
// import rehypeStringify from 'rehype-stringify'

/*  ODC components: */
import docEx from "./doc_ex.md";
import style from './css/markdown.module.css';
import {endTime, loadFromAPI, startTime} from "../../jsOdcLib/GenericJsOdc";

/*  Default styles: see also .css/markdown.module.css for styles of sub items */
const styleContainerDef = {
    margin: 'auto', /*    to horizontally center the div  */
    width: '100%',   /*    to horizontally center the div  */
    maxWidth: 1000,
    borderWidth: 0,
    borderStyle: 'solid',
    padding: '15px 5px 0px 20px',
    marginTop: 20,
    borderRadius: 15,
    whiteSpace: 'auto',
    overflowX: 'wrap'
};

/*  Md tests:   */
// const testMd = 'test md code: `here is the code` **bold**';
const testMd = `
**Below me will be displayed as a block:**
$$
Block Math
$$

**Next to me will be displayed as inline ->** $Inline Math$
$$
s = ut + \\frac{1}{2}at^{2}
$$
`;


const imgFolder = process.env.REACT_APP_IMG_BUCKET;
/*  ------------------------------------------------------------------------------------ /
*   Heading renderer: THEY DO NOT WORK WITH CUSTOM HEADER IDs
*   ==> ODC IMPLEMENTATION TO RECOGNIZE THE FOLLOWING SYNTAX IN MARKDOWN:
*   # My Header [](#custom-id):
*  ------------------------------------------------------------------------------------ */
const CustomLinksOdc = props => {
    const children = React.Children.toArray(props.children);
    let id = props.id;
    /*  Get the custom ID defined after the title: # My Header [](#custom-id):  */
    // console.log('props: ', props);
    if (children.length > 1) {
        // console.log('children: ', children);
        // console.log(`old id:"${id}"`);
        let tmpId = children[1].props && children[1].props.href;
        // console.log('tmp id: ', tmpId);
        if (tmpId) {
            if (tmpId.charAt(0) === '#') {
                id = tmpId.replace('#', '');
                // console.log(`new id:"${id}"`);
            }
        }
    }
    return React.createElement('h' + props.level, {id: id}, props.children);
};

const ImgRenderOdc = props => {
    /*  Changes the src to Cloud Storage to avoid non-access issues...  */
    // const url_search = 'storage.cloud.google.com';  /*  for authenticated browsers  */
    // const url_repl = 'storage.googleapis.com';  /*  for all browsers if public: WARNING: images do not refresh instantaneously  */

    // console.log('props img: ', props);

    // let src = props.src.replace(url_search, url_repl);
    let src = `${imgFolder}/${props.src}`;
    // if (src !== props.src) {
    //    console.log('replacing "', props.src, '" by "', src, '"');
    // }
    return React.createElement('img', {src: src}, props.children);

};

function DocumentationOdc(props) {
    /* *****************************************************************
    * Documentation component
    * Props:
    *  - xxx
    ****************************************************************** */

    const styleContainer = props.styleContainer ? {...styleContainerDef, ...props.styleContainer} : styleContainerDef;
    const mdUrl = props.mdUrl ? props.mdUrl : docEx;
    // const linkTarget = props.linkTarget? props.linkTarget: '_blank';

    const [children, setChildren] = useState(null);

    /*  Load the markdown data: */
    useEffect(() => {
        (async () => {
            let st = startTime();
            const mdData = await loadFromAPI({url: mdUrl});
            mdData ? setChildren(mdData) : setChildren(null);
            endTime(st, 'markdown load');
        })();
    }, [mdUrl]);

    return (
        <div style={styleContainer}>
            <ReactMarkdown
                children={children}    //children testMd
                className={style.reactMarkDownOdc}  /*  to apply custom styles from './css/markdown.module.css'  */
                escapeHtml={false}
                transformImageUri={null}
                components={{
                    h1: CustomLinksOdc,
                    h2: CustomLinksOdc,
                    h3: CustomLinksOdc,
                    h4: CustomLinksOdc,
                    h5: CustomLinksOdc,
                    h6: CustomLinksOdc,
                    img: ImgRenderOdc,
                    // a: CustomLinksOdc
                }}
                remarkPlugins={[
                    remarkGfm,  /*  for GitHub style compatibility  */
                    remarkMdx,  /*  for JSX management inside markdown  */
                    // remarkMath, /*  for maths management (use with rehypeKatex) */
                    // remarkToc,
                    // remarkImages,
                    // remarkEmbedImages,
                    // remarkParse,
                    // remarkRehype,
                    // remarkCollapse,
                ]}
                rehypePlugins={[
                    // rehypeKatex,    /*  for maths management (use with remarkMath) */
                    rehypeSlug,     /*  for link to headings    */
                    // rehypeStringify,
                    // rehypeRaw,   /*  for raw html    */
                ]}
                // linkTarget={linkTarget}
                // renderers={{heading: HeadingRenderer}}
                hyperlink={true}
            />
        </div>
    )
};


export default React.memo(DocumentationOdc);