import React from "react";
import {DataFrame, readCSV, toJSON, merge, Dt } from "danfojs";
import {printMsg, loadFromAPI, isInstance} from "./GenericJsOdc";

        /* ******************************************
        *   Template function description
        * Props:
        *   - val: description
        * ****************************************** */

const PROVIDER = 'antd';  // ant design provider for data and column formatting

export class DfOdc {
    /* *****************************************************************
    * ODC Dataframe class (using danfojs)
    *   props:
    *       - this.load():
    *           - {url: url}: data url if returned by API
    *           - {file_path: file_path}: data file_path if stored as a local CSV (only CSV for now).
    *                   file_path must be defined as 'import file_path from "..//...relative path to file/file.csv";'
    *           - {data: data}: if dataframe to be converted to DfOdc
    * TO CONTINUE!!!
    * Example call:
    *
    *   - with url or file_path:
    *
    *      (async () => {
    *               const tmpDf = await new DfOdc().load({url: 'http://127.0.0.1:5000/survey_data'})
    *               setDfData(tmpDf.df);
    *               setColumns(tmpDf.columns);
    *           })();
    *
    *   - with data (need to set the columns):
    *
    *       const api = useApiOdc();
    *       (async () => {
    *            let url = '/survey_data';
    *            const response = await api.get({url: url});
    *            const tmpDf = await new DfOdc().load({data: response.body.data});
    *            tmpDf.setColumns(response.body.columns);                       // ********* here *************
    *        })();
    *
    ****************************************************************** */

    constructor(debug) {
        /* TO DO:
        *   - hanlde other formats than csv
        * */
        this.debug = debug ? debug : true;
        this.df = undefined;        // the df as danfo df, only with danfo methods
        this.columns = undefined;   // columns in JSON format (this.df.columns gives columns as an array)
        // this.length = undefined;    // number of records
    }

    // Load df from url or data:
    async load({data, url, file_path}) {
        let err = false;
        if (url) {
            /*  Load from API   */

            const apiData = await loadFromAPI({url: url});
            // console.log('response: ', apiData);
            if (apiData) {
                // printMsg(`Df data from api provider: ${apiData}`, null, this.debug);
                this.df = await new DataFrame(apiData.data);
                this.columns = apiData.columns;
            } else {
                err = true;
                console.log('error loading data from api provider: ');
            }
        } else if (file_path) {
            // continue here with options excel, csv json etc. ONLY CSV FOR NOW
// console.log('file_path: ', file_path);
            this.df = await readCSV(file_path);
// console.log('this.df: ', this.df);
            this.setColumns();

        } else if (data) {
            /* Check data format: */
            /*      Danfojs data :*/
            // console.log('danfo? ', (data.constructor.name === 'DataFrame'));
            if (data.constructor.name === 'DataFrame') {
                this.df = new DataFrame(toJSON(data));
            }
            /*      OTHER FORMATS TO DO!!!! :*/
            // columns as list of json need to be set through setColumns
            this.setColumns();
        }
        // this.df.head().print();
        if (!err) {
            const nb_rec = this.df.index.length;
            printMsg(`${nb_rec.toLocaleString()} records loaded succesfully`, null, this.debug);
        }
        return this;
    }

    // Number of records getter:
    get length() {
        const length = this.df && this.df.index.length;
        return length;
    }

    // JSON data getter:
    get json() {
        return toJSON(this.df);
    }

    // Set data (if not used as async function with this.load()):
    setData(data) {
        /* *******************************************************
        *   Set this.df according to data: only if data is ready and not loaded from file or server (if so, use
        *   this.load())
        * ******************************************************* */

        if (!isInstance(data, 'array')) {
            // Transform to array of JSON if dataframe:
            this.df = new DataFrame(toJSON(data));
        } else {
            // Simply load the data:
            this.df = new DataFrame(data);
        }
    }

    // Set columns:
    setColumns(columns) {
        /* ******************************************
        *   Sets the instance columns as JSON object, readable by ant design tables:
        * Ex:
        * columns = [
        *   {
        *   title: 'col_title',
        *   dataIndex: 'name_of_col_in_data_table',
        *   key: 'name_of_key_in_data_table',
        *   render: Js function to apply to the data for rendering
        *   },
        *   ...]
        *
        * Props:
        *   - columns: if set, already ok, if not, takes the columns from the df data and transforms to json
        * ****************************************** */
        this.columns = arrayToColumns({columns: this.df.columns, provider: PROVIDER});
    }

    // isIn(): missing in current version of Danfo:
    isIn(colName, listVal) {
        /* *******************************************************
        *   Equivalent to pandas df.loc[df[colName].isin(listVal)]
        * Returns a newDfOdc instance!!! pass .df to acces the data!
        * ******************************************************* */
        const dfObj = {};
        dfObj[colName] = listVal;
        const mDf = new DataFrame(dfObj);
        const subDf = merge({left: this.df, right: mDf, on: [colName], how: 'inner'});

        // Transform to DfOdc instance:
        const subDfOdc = new DfOdc();
        subDfOdc.setData(subDf);
        subDfOdc.columns = this.columns;
        // tmpDf.print();

        return subDfOdc;
    }

    toList(colName, unique) {
        /* *******************************************************
        *   Returns the list of values as an Array.
        *   If 'unique' = true, returns unique values
        * ******************************************************* */
        if (unique === undefined) unique = false;
        let listVal;
        if (!unique) {
            listVal = this.df[colName].values;
        } else {
            listVal = this.df[colName].unique().values;
        }
        // console.log('list values: ', listVal);
        return listVal;
    }

    aggByDt(options) {
        /* *******************************************************
        *   UPDATE TO HANDLE MULTIPLE SEGMENTATION: DAY + HOUR FOR EXAMPLE
        *   Converts the columns dtColStr into datetime values in the column dtColName
        * Props:
        *   - f_dt: name of the datetime field
        *   - dt_agg: aggregation type: 'hour', 'day', 'wd' (work day), 'we' etc. https://danfo.jsdata.org/api-reference/series#accessors
        *   - f_op: name or list of names of fields on which perform the operation
        *   - op_type: type of operation: 'count', 'sum' etc.
        * ******************************************************* */

        const f_dt = options.f_dt;
        const dt_agg = options.dt_agg;
        const f_op = options.f_op;
        const op_type = options.op_type;

        // Check if df is empty first:
        if (this.df.shape[0] == 0) {
            return null;
        }

        // Get the dt aggregated values:
        const dtSerie = new Dt(this.df[f_dt]);

        let dtAgg;
        // Aggregate by dt_agg:
        switch (dt_agg) {
            case 'hour':
                dtAgg = dtSerie.hours();
                break;
            case 'day':
                dtAgg = dtSerie.dayOfWeekName();
                break;
            default:
                printMsg(`Aggregation by ${dt_agg} still needs to be implemented... Will be agrgegated by hour in the meantime`, 'error');
                dtAgg = dtSerie.hours();
        }

        // Build a df
        const tmpObj = {};
        tmpObj[f_dt] = dtAgg.values;
        if (Array.isArray(f_op)) {
            f_op.forEach((col, idx) => {
                tmpObj[col] = this.df[col].values;
            })
        }
        else {
            tmpObj[f_op] = this.df[f_op].values;
        }
        const dfDt = new DataFrame(tmpObj);

        // Groupby:
        let dfAgg;
        switch (op_type) {
            case 'count':
                dfAgg = dfDt.groupby([f_dt]).count();
                break;
            case 'sum':
                dfAgg = dfDt.groupby([f_dt]).sum();
                break;
            case 'avg':
                dfAgg = dfDt.groupby([f_dt]).mean();
                break;
            case 'mean':
                dfAgg = dfDt.groupby([f_dt]).mean();
                break;
            case 'max':
                dfAgg = dfDt.groupby([f_dt]).max();
                break;
            case 'min':
                dfAgg = dfDt.groupby([f_dt]).min();
                break;
            default:
                printMsg(`Aggregation by ${op_type} still needs to be implemented... Will be aggregated with "avg" in the meantime`, 'error');
        }

        // Sort values:
        dfAgg.sortValues(f_dt, {ascending: true, inplace: true});

        return dfAgg;


    }
}


export class CntOdc extends DfOdc {
    /* *****************************************************************
    *   ODC Traffic counts class. Inherits from DfOdc
    * Props:
    *   - df: the df data instance to get the data from
    *   - f_dt: the name of the datetime field
    *   - f_cnt: the name of the count field
    *   - f_dir: the name of the direction field
    *   - f_veh_type: the name of the vehicle type field
    *   - f_speed: the name of the speed field
      ***************************************************************** */

    constructor(options) {
        super(DfOdc);
        this.df = options.df && options.df;
        this.setColumns(options.df.columns);
        this.f_dt = options.f_dt ? options.f_dt : 'cnt_datetime'; // the dt field
        this.f_cnt = options.f_cnt ? options.f_cnt : 'count';
        this.f_dir = options.f_dir ? options.f_dir : 'direction_id';
        this.f_veh_type = options.f_veh_type ? options.f_veh_type : 'vehicle_type';
        this.f_speed = options.f_speed ? options.f_speed : 'speed_kmh';
    }

    get tmj() {
        /* ******************************************
        *   Get the count TMJ: daily average traffic
        * Props:
        *   - TO COMPLETE: for now independant of bank holiday etc.
        * ****************************************** */

        // declarations:
        const f_sum = `${this.f_cnt}_sum`;  // the name of the count column after aggregation by day

        // Get the total per day for each day:
        const dfDay = this.aggByDt({f_dt: this.f_dt, dt_agg: 'day', f_op: this.f_cnt, op_type: 'sum'});

        if (dfDay == null) {
            return null;
        }

        // Compute the daily avg:
        // console.log('dfDay: ', dfDay, f_sum);
        let avgDay;
        if (dfDay.shape[0] > 0) {
            avgDay = dfDay[f_sum].mean();
        }
        return Math.round(avgDay, 1);

    }
}

/* -----------------------------------------------------------------------------------------------------------------
*                   Generic function for lib compatibilities
* ----------------------------------------------------------------------------------------------------------------- */

export const renameSuffixes = (df, dict_rename) => {
    /* ******************************************
    *   Function that renames the fields after the df has been merged or grouped:
    *   Ex: after groupby().sum() ==> renames '_sum'
    * Props:
    *   - the df having the fields to be renamed
    *   - dict_rename: dictionary of suffixes to be renamed. Ex: {'_sum': '', '_1': '_left', ...}
    * ****************************************** */
    let df2 = df;
    let columns = df2.columns;
    columns.map(col => {
        /*  Loop through the items of the dictionary:   */
        // console.log(`Rename '${col}'?`);
        Object.entries(dict_rename).forEach(([suff, repl]) => {
            /*  Check if the current field ends with the suffix:    */
            // console.log('   ', suff, 'with "', repl, '"');
            if (col.endsWith(suff)) {
                let dict_tmp = {};
                dict_tmp[col] = col.replace(suff, repl);  // NOTE THAT CAN LEAD TO ERRORS IF THE SUFFIX IS ALSO IN THE BODY OF THE FIELD NAME: ex: field name: POP_SUM_2022_SUM!!!
                df2.rename(dict_tmp, {axis: 1, inplace: true});
            }
        })
    });
    return df2;
};

export const rowToListObj = (df_row, f_name, f_value) => {
    /* ******************************************
    *   Function that transforms a df row into a list of objects. Ex: to plot a pie chart from the row
    *   Ex:
    *       - df row: {f1: val1, f2: val2, ...}
    *       - list obj: [{f_name: f1, f_value: val1}, {f_name: f2, f_value: val2}, ...]
    * Props:
    *   - df_row: the extracted row from the df
    *   - f_name: the key of the field name
    *   - f_value: the key of the field value
    * ****************************************** */
    let listObj = [];
    let df_col = df_row.columns;
    // console.log('df_col: ', df_col);
    df_col.map((col) => {
        // console.log('col: ', col);
        let tmpObj = {};
        tmpObj[f_name] = col;
        // console.log('1: ', df_row.loc({columns: [col]}));
        tmpObj[f_value] = df_row.loc({columns: [col]}).iloc({rows: [0], columns: [0]}).values[0][0];
       listObj.push(tmpObj);
    });
    return listObj;
};

export const arrayToColumns = (options) => {
    /* ******************************************
        *   Transform an array into a JSON object, readable by ant design tables:
        * Ex:
        *   - array: ['col1', 'col2']
        *   - output:
        * columns = [
        *   {
        *   title: 'col1',
        *   dataIndex: 'col1',
        *   key: 'col1',
        *   render: Js function to apply to the data for rendering. TO IMPLEMENT DEPENDING ON THE COL TYPE???
        *   },
        *   ...]
        *
        * Props:
        *   - columns: array of columns names to transform
        *   - provider: 'antd' by default
        *   - keyAsName: if 'true', set the column key as the columns name, if false (default), set as the col idx
        * ****************************************** */
    const colArrary = options.columns && options.columns;
    const provider = options.provider? options.provider: 'antd';
    const keyAsName = options.keyAsName? options.keyAsName: false;

    // Manage other providers TO DO if necessary
    let columns;
    switch (provider) {
        case 'antd':
            columns = [];
            colArrary.forEach((col, idx) => {
                let colObj = {};
                colObj['title'] = col;
                colObj['dataIndex'] = col;
                colObj['key'] = idx;
                columns.push(colObj);
            });
            break;
        default:
            printMsg(`Provider ${provider} to implement for columns array to json transformation`, 'error');
    }
    return columns;
};

