export default function TitleOdc(props) {
    /* ******************************************************************
    * ODC Titles as SVG elements. TO COMPLETE!!!
    * Props:
    *   - title
    *   - TO COMPLETE
    *
    * See doc here: https://developer.mozilla.org/en-US/docs/Web/SVG/Element/text
    * ****************************************************************** */

    const styleDef = {color: 'grey', fontSize: 20, textAlign: 'center', paddingBottom: 10};

    const title = props.title ? props.title : null;
    const style = props.style? props.style: styleDef;

    /*  SVG title: NOT USED FOR NOW */
    // const titleSvg = <svg viewBox="0 0 100 10">
    //         <text x="50%" y="20%" dominantBaseline="hanging" fontSize={5} fontWeight="bold">{title}</text>
    //     </svg>;
    return (
        <div style={style}>{title}</div>
    );
}