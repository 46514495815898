/* *****************************************************************
* City summary page for INSEE, called from InseePage
*   TO DO: PASS REAL DATA TO CHART!!!
*           ADD LABEL TO PIE CHART
* Props:
*   - citySummary:     city summary data as json
*   - fromTo:    whether from or to
*   - comId:           city ID
*   - ...
****************************************************************** */


import React from "react";
import {Row} from "antd";
import {useTranslation} from 'react-i18next';

/* ODC Components: */
import {FunnelChartOdc, PieChartOdc, ScatterChartOdc} from "../../components/charts/ChartsOdc";
import {formatNumber, maxFromObject, roundToMultiple} from "../../jsOdcLib/GenericJsOdc";
import TitleOdc from "../../components/layout/TitleOdc";

/* ***************** Data Object Keys (citySummary in InseePage): *********************
*   Defined in "citySummaryObj" CalcInsee.js:
*    'name',
*    'code'
*    'bc',          // carbon emission
*    'bcNet',
*    'bcDistr',
*    'tt',          // travel time
*    'ttDistr',
*    'pm',          // mode share
*
* ************************************************************************************ */

/* ***************** Default chats options: ****************************************** */
const chartHeight = 400; //300;
const pieChartOptDef = {height: chartHeight};
const funnelChartOptDef = {height: 0.9 * chartHeight};
const scatterChartOptDef = {height: chartHeight};
const elemOptDef = {fillOpacity: 0.9, isAnimationActive: true, animationDuration: 300};  // Style to apply to Line, Bar etc.
const maxTTdef = 90; /* max travel time for scatter plot bounds */

/* ***************** Field names: ***************************************************** */
const f_com_name = 'NOM_COM';   // defined in API
const f_com_id = 'COM_ID';      // defined in API
// const f_carbon_norm = 'Bilan Carbone / depl';   // defined in API
const f_tt_car = 'DURATION_driving';    // defined in API
const f_tt_pt = 'DURATION_transit';     // defined in API
const f_quantile = 'quantile';  // defined in CalcInsee.js: UPDATE IN dataDefFunnel as hard value there
const f_carbon_min = 'min_bc';
const f_carbon_max = 'max_bc';
const f_label = 'label';
const f_fill = 'fill';

/* ***************** Default data: ***************************************************** */
const colorMap = {
    'VP': '#762719',
    '2RM': 'rgba(203,65,43,0.83)',
    'TC': '#0cac1e',
    'Vélo': 'rgba(12,172,30,0.58)',
    'Marche': 'rgba(12,172,30,0.28)',
    'Pas de transport': 'rgba(68,73,82,0.41)',
};

/*  Def mode share data:    */
const dataDefPie = [
    {name: 'VP', value: 55.545645646},
    {name: '2RM', value: 5},
    {name: 'TC', value: 25},
    {name: 'Vélo', value: 3},
    {name: 'Marche', value: 10},
    {name: 'Pas de transport', value: 2},
];

/*  Def carbon distribution data:    */
const dataDefFunnel = [
    {quantile: 1, value: 0, label: 'Top 10%: < 0.2 kg/depl'},
    {quantile: 2, value: 0.2, label: '20%: < 0.4 kg/depl'},
    {quantile: 3, value: 0.4, label: '30%: < 0.6 kg/depl'},
    {quantile: 4, value: 0.6, label: '40%: < 0.8 kg/depl'},
    {quantile: 5, value: 0.8, label: '50%: < 1 kg/depl'},
    {quantile: 6, value: 1.0, label: '60%: < 1.2 kg/depl'},
    {quantile: 7, value: 1.2, label: '70%: < 1.5 kg/depl'},
    {quantile: 8, value: 1.5, label: '80%: < 2.2 kg/depl'},
    {quantile: 9, value: 2.2, label: '90%: < 2.2 kg/depl'},
    {quantile: 10, value: 3.7, label: '100%: <= 3.7 kg/depl'},
];

/* ***************** Carbon emission ****************************************************** */
export const ChartCarbon = (props) => {

    /*  translation:    */
    const { t } = useTranslation();

    /*  Funnel chart Carbon emission:   */
    let data = props.data ? props.data.bcDistr : dataDefFunnel;  // the carbon emission for the selected city
    const title = props.title ? props.title : null;
    const chartOpt = props.chartOpt ? props.chartOpt : funnelChartOptDef;
    const elemOpt = {fillOpacity: 0.9, isAnimationActive: false, animationDuration: 0};
    const xAxis = f_quantile;
    const yAxis = f_carbon_max;
    const fLabel = t(yAxis);
    const fFill = f_fill; // fill color field


    /*  Buil dictFields:    */
    // const dictFields = {};
    // dictFields[f_carbon_max] = t(f_carbon_max);

    /*  Add label field:    */
    data.map((obj, ix) => {
        let obj2 = {};
        obj2[fLabel] = obj[f_carbon_max];
        data[ix] = {...obj, ...obj2}
    });

    /*  CHANGE CARBON DISTR AND TOOL TIP TO GET BOUNDS  */
    const tooltipFormatter = (rec) => {
        if (!rec.active) {
            return null;
        }
        // console.log('rec: ', rec.payload[0]['name'], rec.payload[0]['value']);
        // console.log('whole rec: ', rec);
        // console.log('minBc: ', rec.payload[0].payload[f_carbon_min]);
        let tmpQuantile = rec.payload[0]['name'];
        let minBc = rec.payload[0].payload[f_carbon_min];
        let maxBc = rec.payload[0].payload[f_carbon_max];
        let topLabel;
        let bcLabel;
        if (tmpQuantile === 9) {
            topLabel = `${t('Top 10%')}: `;
            bcLabel = `${t('Bilan Carbone Moyen')} < ${formatNumber(maxBc, 2)} ${t('kg CO2/depl')}`;
        } else if (tmpQuantile === 0) {
            topLabel = `${t('Derniers 10%')}: `;
            bcLabel = `${t('Bilan Carbone Moyen')} > ${formatNumber(minBc, 2)} ${t('kg CO2/depl')}`;
        } else {
            topLabel = `Top ${10 * (9 - tmpQuantile + 1)}%: `;
            bcLabel = `${t('Bilan Carbone Moyen')}: ${formatNumber(minBc, 2)} - ${formatNumber(maxBc, 2)} kg CO2/depl`;
        }
        return (
            <div style={{backgroundColor: "lightgrey", padding: 5, borderRadius: 5}}>
                {topLabel}:<br/>
                {bcLabel}<br/>
            </div>
        );
    };

    /*  Reverse the order of the carbon funnel to get it top to bottom: */
    data = [...data].reverse();


    /*  Funnel chart Carbon emission:   */
    return (
        <div>
            <Row>
                <TitleOdc title={title}/>
            </Row>
            <Row>
                <FunnelChartOdc
                    data={data}
                    // title={title}
                    xAxis={xAxis}
                    yAxis={fLabel}
                    fLabel={fLabel}
                    // dictFields={dictFields}
                    fFill={fFill}
                    chartOpt={chartOpt}
                    elemOpt={elemOpt}
                    tooltipFormatter={tooltipFormatter}
                    legend={true}
                    apiOpt={{reversed: true}}   /* reversed chart   */
                />
            </Row>
        </div>

    )
};

/* ***************** Mode shares ******************************************************** */
export const ChartModeShare = (props) => {
    /*  Pie chart Mode shares:   */
    const data = props.data ? props.data.pm : dataDefPie;
    const title = props.title ? props.title : null;
    const chartOpt = props.chartOpt ? props.chartOpt : pieChartOptDef;
    const elemOpt = elemOptDef;
    const xAxis = 'name';
    const yAxis = 'value';

    const { t } = useTranslation();

    /*  Buil dictFields:    */
    const dictFields = {};
    for (let mode of Object.keys(colorMap)) {
       dictFields[mode] = t(`_${mode}`);    /*  '_VP' rather than 'VP' to have short translation (ODC convention) */
// console.log('mapping ', mode, ' to ', t(mode));
    };

    return (
        <div>
            <Row>
                <TitleOdc title={title}/>
            </Row>
            <Row>
                <PieChartOdc
                    data={data}
                    // title={title}
                    chartOpt={chartOpt}
                    yAxis={yAxis}
                    xAxis={xAxis}
                    colorMap={colorMap}
                    elemOpt={elemOpt}
                    dictFields={dictFields}
                />
            </Row>
        </div>
    )
};

/* ***************** Travel times ********************************************************* */
export const ChartTravelTime = (props) => {
    /*  translation:    */
    const {t} = useTranslation();

    /*  Travel time PT vs Car chart:   */
    const data = props.data ? props.data.ttDistr : null;
    const title = props.title ? props.title : null;
    const chartOpt = props.chartOpt ? props.chartOpt : scatterChartOptDef;
    const elemOpt = elemOptDef;
    const xAxis = f_tt_car;
    const yAxis = f_tt_pt;

    /*  Get the max bounds: */
    const maxTTcar = maxFromObject(data, f_tt_car);
    const maxTTpt = maxFromObject(data, f_tt_pt);
    let maxTT = roundToMultiple(Math.max(maxTTcar, maxTTpt), 10);
    maxTT = Math.max(maxTTdef, maxTT);
    // maxTT = maxTTdef;

    const axisDomain = [[0, maxTT], [0, maxTT]];

    const tooltipFormatter = (rec) => {
        if (!rec.active) {
            return null;
        }
        // console.log('rec: ', rec.payload[0].payload[f_com_name]);
        // console.log('whole rec: ', rec);
        let comName = rec.payload[0].payload[f_com_name];
        let comId = rec.payload[0].payload[f_com_id];
        return (
            <div style={{backgroundColor: "lightgrey", padding: 5, borderRadius: 5}}>
                {`${comName} (${comId})`}:<br/>
                {t('Tps VP')}: {Math.round(rec.payload[0].payload[f_tt_car])} min<br/>
                {t('Tps TC')}: {Math.round(rec.payload[0].payload[f_tt_pt])} min<br/>
            </div>
        );
    };

    /*  Buil dictFields:    */
    const dictFields = {};
    dictFields[f_tt_car] = t(f_tt_car);
    dictFields[f_tt_pt] = t(f_tt_pt);

    return (
        <div>
            <Row>
                <TitleOdc title={title}/>
            </Row>
            <Row>
                <ScatterChartOdc
                    data={data}
                    // title={title}
                    xAxis={xAxis}
                    yAxis={yAxis}
                    chartOpt={chartOpt}
                    elemOpt={elemOpt}
                    tooltipFormatter={tooltipFormatter}
                    dictFields={dictFields}
                    axisDomain={axisDomain}
                />
            </Row>
        </div>
    )
};

