/* *****************************************************************
* KpiOdc: Ant design statistics description:
* Props:
*   - title
*   - content
*   - kpiStyle: the style of the title and data
*   - divStyle: the style of thze outer div
*
* See doc here: https://ant.design/components/statistic/
********************************************************************* */

import React from "react";
import {Statistic} from 'antd';

/*  Style for outer KPI <div> */
const defDivStyle = {
    outline: '1px dotted darkgrey',
    borderRadius: '5px',
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 2,
    marginRight: 40,
    display: 'inline-block', /* for seeing the KPIs on the same row */
};

/*  Style for KPI content   */
const defKpiStyle = {
    decimalSeparator: '.',
    groupSeparator: ' ',    /* thousands separator */
    formatter: null,    /* the render function */
    valueStyle: {color: 'black', borderTop: '1px solid darkgrey', fontSize: 20},   // the style of the displayed value
    prefix: null,
    suffix: null,
    /* ODC added properties: */
    titleStyle: {fontSize: 12},
};


const KpiOdc = (props) => {

    const title = props.title ? props.title : null;
    const value = props.content ? props.content : null;
    const kpiStyle = props.kpiStyle ? {...defKpiStyle, ...props.kpiStyle} : {...defKpiStyle};
    const kpiProps = {...kpiStyle};

    const divStyle = props.divStyle ? {...defDivStyle, ...props.divStyle} : {...defDivStyle};

    // Get speicif ODC styles:
    const styledTitle = <span style={kpiStyle.titleStyle}>{title}</span>

    kpiProps['title'] = styledTitle;
    kpiProps['value'] = value;

    //<Statistic title={title} value={value} {...style}/>

    return (
        <div style={divStyle}>
            <Statistic {...kpiProps}/>
        </div>
    );
};

export default KpiOdc;