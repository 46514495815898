/* *****************************************************************
* ODC charts component - Use of recharts library (https://recharts.org/)
*
* Charts Components to add?:
*   CartesianGrid: https://recharts.org/en-US/api/CartesianGrid
*   Zooms on chart: https://recharts.org/en-US/examples/HighlightAndZoomLineChart
****************************************************************** */
import React from "react";
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    Cell,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
    PieChart,
    Pie,
    // BarChart,
    ReferenceLine,
    // Brush,
    Bar,
    // Rectangle,
    ComposedChart,
    ScatterChart,
    Scatter,
    FunnelChart,
    Funnel,
    LabelList,
} from 'recharts';
import {endTime, getRandomColor, isFloat, printMsg, startTime} from "../../jsOdcLib/GenericJsOdc";
// import TitleOdc from "../layout/TitleOdc";

/*******************************************************************
 *      CHARTS DEFAULT OPTIONS
 * *************************************************************** */

/* Fake data for debug only: */
const defData = [{name: 'x1', y1: 400, y2: 2400},
    {name: 'x2', y1: 2500, y2: 2500},
    {name: 'x3', y1: 2600, y2: 2600}];

/* Default chart options: */
const defChartOpt = {
    width: '100%',
    height: 400,
    minWidth: 200,
    minHeight: 200,
    margin: {top: 5, right: 20, bottom: 5, left: 0},
    grid: {horizontal: true, vertical: false, strokeDasharray: '4 1'},
};

/* Default chart elements options (Line, Bar, Pie etc.): */
const defChartElemOpt = {fillOpacity: 0.9, isAnimationActive: true, animationDuration: 300};

/* Default text style: */
const defTextStyle = {fontSize: 10};

/* Default lines options: */
const defLineOpt = {
    type: 'linear', // 'natural', '', description here:  https://github.com/d3/d3-shape#curves:
    dot: false, // { stroke: 'red', strokeWidth: 2 }, or <CustomizedDot />, or function
};

/* Default tooltip format: */
const defTooltipFormatter = (val) => {
    /*  ************************
    *   Tooltip default formatter. To be called as follows:
    *
    *         <Tooltip content={tooltipFormatter}/>
    *
    *   If Use several fields and values to format tooltip, can use the example below (check 'payload' attribute
    *   structure to adapt to each situation)
    *
    * ************************ */
    // console.log('val: ', val);  // check 'payload' attribute structure here

    const nb_digits = 2;
    if (!val.active) {
        return null;
    }
    let tmpVal = val.payload[0].value;

    /* if other field to be displayed, can be accessed like that for example:
    * let comName = val.payload[0].payload[f_com_name];
    * */

    /*  round value if float:   */
    if (isFloat(tmpVal)) {
        /* if floating value, round to 2 digits:    */
        tmpVal = tmpVal.toFixed(nb_digits);
    }
    /*  field name: */
    let tmpKey = val.payload[0].dataKey;

    /*  tooltip formatted value*/
    return (
        <div style={{backgroundColor: "rgba(68,73,82,0.11)", padding: 5, borderRadius: 5}}>
            {`${tmpKey}: ${tmpVal}`}
        </div>
    );

};

/*  Field names mapping function TO COMLPETE    */
const renameFields = (data, dictFields, k_name) => {
    let k_name2 = k_name? k_name: 'name';

    /*  Remap the field names   */
    const data2 = data.map(obj => {
       // let f_name = obj.name;
       let f_name = obj[k_name2];
       let f_name2 = dictFields[f_name]? dictFields[f_name]: f_name;
// console.log('mapping ', f_name, ' to ', f_name2);
        let newName = {};
        newName[k_name2] = f_name2;
//        return {...obj, ...{name: f_name2}};
       return {...obj, ...newName};
    });
    return data2;
};

/*******************************************************************
 *      ODC CHARTS
 * *************************************************************** */

/*  Funnel chart: TO COMPLETE   */
export function FunnelChartOdc(props) {
    /* *********************************
    *   Funnel chart generic
    * https://recharts.org/en-US/api/FunnelChart
    *
    * Props:
    *   - data: data in json notation: [{'name': 'field name 1', 'value': val1}, {'name': 'field name 2', 'value': val2, etc.]
    *   - colorMap: dictionary {field name: color}
    *   - dictKeys: dictionary of y keys to be renamed: TO IMPLEMENT
    *
    * ********************************* */

    let exData = [{name: 'x1', value: 400}, {name: 'x2', value: 200}, {name: 'x3', value: 100}];

    let data = props.data ? props.data : exData;
    const title = props.title ? props.title : null;
    let xAxis = props.xAxis ? props.xAxis : 'name';   // X field to display (legend)
    let yAxis = props.yAxis ? props.yAxis : 'value';   // Y field to display
    let fLabel = props.fLabel ? props.fLabel : xAxis; // Label field
    let fFill = props.fFill ? props.fFill : 'fill'; // Fill color field
    const colorMap = props.colorMap ? props.colorMap : null;    // mapping dictionary between field names and colors
    const chartOpt = props.chartOpt ? {...defChartOpt, ...props.chartOpt} : defChartOpt;
    const dictFields = props.dictFields ? props.dictFields : {};  // Dictionary between field values and display values
    const textStyle = props.textStyle ? props.textStyle : defTextStyle;
    const elemOpt = props.elemOpt ? props.elemOpt : defChartElemOpt;  // style options for pies: oppacity, animation etc.
    const tooltipFormatter = props.tooltipFormatter ? props.tooltipFormatter : defTooltipFormatter;
    const legend = (props.legend === false) ? props.legend: true;
    /* Funnel chart options:   */
    const apiOpt = props.apiOpt? props.apiOpt: {reversed: false}; /* Funnel API options: https://recharts.org/en-US/api/FunnelChart */

    /* Set the color map according to each Y field: */
    const defColors = data.map(y => getRandomColor());
    const dataColor = colorMap ? {...colorMap} : defColors;

    /* Set random colors where not defined: TO FIX */
    data.map((entry, idx) => {
        let entryLabel = entry[xAxis];
        dataColor[entryLabel] = dataColor[entryLabel] ?? defColors[idx];
        data[idx][fFill] = data[idx][fFill] ? data[idx][fFill] : dataColor[entryLabel];
        return true;
    });

     /*  remap fields:   */
    data = renameFields(data, dictFields, yAxis);

    // console.log('Funnel data to be plotted: ', data);
    // console.log('axis: ', xAxis, yAxis, ' "label": ', fLabel);
    // console.log('dictFields: ', dictFields);
    return (
        <ResponsiveContainer width={chartOpt.width} height={chartOpt.height} minHeight={chartOpt.minHeight}
                             minWidth={chartOpt.minWidth}>

            <FunnelChart width={chartOpt.width} height={chartOpt.height}>

                {/*Title: ----------------------------------------------------------------------------------------- */}
                <text x="50%" y="10" dominantBaseline="hanging" fontSize="15" fontWeight="bold" fill={'#768a9e'}>
                    {title}
                </text>


                {legend && <Legend/>}

                <Tooltip content={tooltipFormatter}/>

                <Funnel
                    dataKey={yAxis}
                    data={data}
                    {...elemOpt}
                    {...apiOpt}
                >
                    <LabelList position="right" offset={5} fill={fFill} stroke="none" dataKey={fLabel}
                               style={textStyle}/>
                </Funnel>


            </FunnelChart>

        </ResponsiveContainer>
    );

}

/*  Reference Line  DOES NOT WORK AS SEPARATE COMPONENT... */
const ReferenceLineOdc = (props) => {
    /* *********************************
    *   Reference line in chart (eg diag in scatter)
    * https://recharts.org/en-US/api/ReferenceLine
    *
    * Props:
    *   - data: data in json notation: [{'name': 'field name 1', 'value': val1}, {'name': 'field name 2', 'value': val2, etc.]
    *   - lineType: 'diag' by default: TO COMPLETE
    *
    * ********************************* */
    //
    // /*  FOR TESTS   -------------------------*/
    // const f_tt_car = 'DURATION_driving';
    // const f_tt_pt = 'DURATION_transit';
    // /*  END FOR TESTS   -------------------------*/
    //
    // let data = props.data;
    // let lineType = props.lineType ? props.lineType : 'diag';
    // let xAxis = props.xAxis ? props.xAxis : f_tt_car;   //'x';   // X field
    // let yAxis = props.yAxis ? props.yAxis : f_tt_pt; //'y';   // Y field
    // let label = props.label ? props.label : 'y=x';
    // let stroke = props.stroke ? props.stroke : 'red';
    // let strokeDasharray = props.strokeDasharray ? props.strokeDasharray : "3 3";
    //
    // /*  Compute segment:    */
    // let st0 = startTime();
    //
    // let segment = [{x: 0, y: 0}, {x: 60, y: 60}];
    // if (lineType === 'diag') {
    //     // let s0 = {x: null, y: null};    // start point
    //     // let s1 = {...s0};    // end point
    //     // s0[xAxis] = Math.min(...data.map(item => item[xAxis])); // extract the min among objects TO MAKE GENERIC
    //     // s0[yAxis] = Math.min(...data.map(item => item[yAxis]));
    //     // s1[xAxis] = Math.max(...data.map(item => item[xAxis])); // extract the max among objects TO MAKE GENERIC
    //     // s1[yAxis] = Math.max(...data.map(item => item[yAxis]));
    //     // s0[x] = 0;
    //     // s0[y] = 0;
    //     // s1[x] = 60;
    //     // s1[y] = 60;
    //     // let s0 = {x: 0, y: 0};    // start point
    //     // let s1 = {x: 60, y: 60};    // end point
    //     // segment = [s0, s1];
    // } else {
    //     printMsg('Ref line TO COMPUTE', 'error');
    // }
    // // {/*<ReferenceLine label="y=x" stroke="green" strokeDasharray="3 3" segment={[{ x: 0, y: 0 }, { x: 60, y: 60 }]} />*/}
    // endTime(st0, 'build diag scatter');
    // console.log('REF LINE label: ', label, ', SEGMENT: ', segment);
    // return (
    //     <ReferenceLine label={label} stroke={stroke} strokeDasharray={strokeDasharray} segment={segment}
    //                    alwaysShow={true}/>
    // )
};

/*  Scatter chart: TO COMPLETE  */
export function ScatterChartOdc(props) {
    /* *********************************
    *   Funnel chart generic
    * https://recharts.org/en-US/api/FunnelChart
    *
    * Props:
    *   - data: data in json notation: [{'name': 'field name 1', 'value': val1}, {'name': 'field name 2', 'value': val2, etc.]
    *   - colorMap: dictionary {field name: color}
    *   - dictKeys: dictionary of y keys to be renamed: TO IMPLEMENT
    *
    * ********************************* */

    let data = props.data ? props.data : null;
    const title = props.title ? props.title : null;
    let xAxis = props.xAxis ? props.xAxis : 'x';   // X field to display (legend)
    let yAxis = props.yAxis ? props.yAxis : 'y';   // Y field to display
    let zAxis = props.zAxis ? props.zAxis : 'z';   // Z field (size) to display TO USE / IMPLEMENT
    const dictFields = props.dictFields ? props.dictFields : {};  // Dictionary between field values and display values
    let xLabel = props.xLabel ? props.xLabel : dictFields[xAxis]?dictFields[xAxis]: xAxis;   // X field to display (legend)
    let yLabel = props.yLabel ? props.yLabel : dictFields[yAxis]?dictFields[yAxis]: yAxis;   // Y field to display
    const axisDomain = props.axisDomain? props.axisDomain: null; /* [[min_x, max_x], [min_y, max_y]] */
    // let fFill = props.fFill ? props.fFill : 'fill';   // field with fill color information
    // const colorMap = props.colorMap ? props.colorMap : null;    // mapping dictionary between field names and colors
    const chartOpt = props.chartOpt ? {...defChartOpt, ...props.chartOpt} : defChartOpt;
    // const textStyle = props.textStyle ? props.textStyle : defTextStyle;
    const elemOpt = props.elemOpt ? props.elemOpt : defChartElemOpt;  // style options for pies: oppacity, animation etc.
    const tooltipFormatter = props.tooltipFormatter ? props.tooltipFormatter : defTooltipFormatter;
    /* Scatter chart options:   */
    const showDiag = props.showDiag ? props.showDiag : true;  // Show the diagonal
    // let height = 300;

    /*  Example data: */
    let exData = [];
    let nb_data = 100;
    let rec = {};
    for (let i = 0; i < nb_data; i++) {
        rec[xAxis] = Math.round(Math.random() * nb_data);
        rec[yAxis] = Math.round(Math.random() * nb_data);
        rec[zAxis] = 1000 * Math.round(Math.random());
        exData.push(rec);
    }
    data = data ? data : exData;

    /*  Compute diagonal line: COMPLETE FOR OTHER TYPES OF REF LINES --------------------------------------------- */
    const getSegmentBounds = (data) => {
        let xMax = Math.max(...data.map(item => item[xAxis])); // extract the max among objects: generic in GenericJsOdc.maxFromObject
        let yMax = Math.max(...data.map(item => item[yAxis]));
        let segMax = Math.max(xMax, yMax);  // max for y=x
        return [{x: 0, y: 0}, {x: segMax, y: segMax}];
    };
    let segment = showDiag && getSegmentBounds(data);
    let refLine = showDiag &&
        <ReferenceLine label="y=x" stroke="green" strokeDasharray="3 3" segment={segment} alwaysShow={true}/>;
    /*  ---------------------------------------------------------------------------------------------------------- */

    /*  x and y ranges: */
    let xDomain = axisDomain? [axisDomain[0][0], axisDomain[0][1]]: null;
    let yDomain = axisDomain? [axisDomain[1][0], axisDomain[1][1]]: null;

    /*  remap fields:   */
    data = renameFields(data, dictFields);

    return (
        <ResponsiveContainer width={chartOpt.width} height={chartOpt.height} minHeight={chartOpt.minHeight}
                             minWidth={chartOpt.minWidth}>
            <ScatterChart
                width={chartOpt.width}
                height={chartOpt.height}
                margin={chartOpt.margin}
            >
                {/*Title: ----------------------------------------------------------------------------------------- */}
                <text x="5%" y="10" dominantBaseline="hanging" fontSize="15" fontWeight="bold" fill={'#768a9e'}>
                    {title}
                </text>

                <CartesianGrid/>

                <XAxis type="number" dataKey={xAxis} name={xLabel} unit=""
                       label={{value: xLabel, position: "bottom", offset: -10}}
                       domain={xDomain}/>

                <YAxis type="number" dataKey={yAxis} name={yLabel} unit=""
                       label={{value: yLabel, position: "left", angle: -90, offset: -20}}
                       domain={yDomain}/>

                <Tooltip content={tooltipFormatter}/>

                <Scatter
                    data={data}
                    {...elemOpt}
                />

                {/*  Reference line:  */}
                {refLine}

                {/*  Reference line as custom component DOES NOT WORK */}
                {/*<ReferenceLineOdc data={data} xAxis={xAxis} yAxis={yAxis} />*/}


            </ScatterChart>
        </ResponsiveContainer>
    );
}

/* Composed chart: TO COMPLETE  */
export function ComposedChartOdc(props) {
    /* tests */
    let data = [];
    let xAxis = 'uv';
    let yAxis = 'name';
    let nb_data = 130;
    for (let i = 0; i < nb_data; i++) {
        data.push({name: i, uv: 1.5 * i, pv: 0});
    }
    data[120] = {name: 120, uv: 1.5 * 120, pv: 1.5 * 120};
    let height = 500;
    let title = 'Classement Bilan\nCarbone / habitant';

    return (
        <ResponsiveContainer width="100%" height={height}>
            <ComposedChart
                width={250}
                height={0.5 * height}
                data={data}
                margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                }}
                layout={'vertical'}
            >

                {/*Title: ----------------------------------------------------------------------------------------- */}
                <text x="50%" y="10" dominantBaseline="hanging" fontSize="15" fontWeight="bold" fill={'#768a9e'}>
                    {title}
                </text>

                <CartesianGrid strokeDasharray="3 3"/>
                <XAxis dataKey={xAxis}/>
                <YAxis dataKey={yAxis} type='category'/>
                <Tooltip/>
                <Legend verticalAlign="bottom" wrapperStyle={{lineHeight: '40px'}}/>
                <ReferenceLine y={0} stroke="#000"/>
                {/*<Brush dataKey="name" height={15} stroke="#8884d8" />*/}
                <Line dataKey="uv" stroke="grey" dot={false}/>
                <Bar dataKey="pv" fill="red" barSize={1000}/>

            </ComposedChart>
        </ResponsiveContainer>
    )
}

/* Pie Chart: */
export function PieChartOdc(props) {
    /* *********************************
    * dictFields TO DO
    *   PieChart generic
    * https://recharts.org/en-US/api/PieChart
    *
    * Props:
    *   - data: data in json notation: [{'name': 'field name 1', 'value': val1}, {'name': 'field name 2', 'value': val2, etc.]
    *   - yAxis: list of Y fields to be plotted. Usually only one, but several if rings
    *   - colorMap: dictionary {y_field: color}
    *   - dictKeys: dictonary of y keys to be renamed: TO IMPLEMENT
    *   - elemOpt: fillOppacity, animation etc.
    *   - showAsPerc: if true, transform values as %
    *   - innerRadius: > 0 if ring rather than circle
    *   - outerRadius: ...
    *
    * ********************************* */
    let data = props.data ? props.data : defData;
    const title = props.title ? props.title : null;
    let xAxis = props.xAxis ? props.xAxis : 'name';   // X field to display (legend)
    let yAxis = props.yAxis ? props.yAxis : 'value';   // Y field to display
    const colorMap = props.colorMap ? props.colorMap : null;    // mapping dictionary between field names and colors
    const chartOpt = props.chartOpt ? {...defChartOpt, ...props.chartOpt} : defChartOpt;
    const dictFields = props.dictFields ? props.dictFields : {};  // Dictionary between field values and display values
    const elemOpt = props.elemOpt ? props.elemOpt : defChartElemOpt;  // style options for pies: oppacity, animation etc.
    /* Pie chart options:   */
    const showAsPerc = props.showAsPerc ? props.showAsPerc : true;
    const innerRadius = props.innerRadius ? props.innerRadius : 0;
    const outerRadius = props.outerRadius ? props.outerRadius : 80;
    // console.log('outer radius: ', outerRadius);

    /* Set the color map according to each Y field: */
    const defColors = data.map(y => getRandomColor());
    const dataColor = colorMap ? {...colorMap} : defColors;

    /* Set random colors where not defined: */
    data.map((entry, idx) => {
        let entryLabel = entry[xAxis];
        /* new key if fields are remapped:  */
        let f_key = dictFields[entryLabel]? dictFields[entryLabel]: entryLabel;

        /*  remap colors:   */
        // dataColor[entryLabel] = dataColor[entryLabel] ?? defColors[idx];
        dataColor[f_key] = dataColor[entryLabel]? dataColor[entryLabel]: defColors[idx];

        return true;
    });

    /* Transform the data as %: */
    let tooltipFormatter = defTooltipFormatter;
    if (showAsPerc) {
        let totVal = 0;
        /*  Compute total:  */
        data.map(entry => {
            totVal += entry[yAxis];
            return true;
        });
        /*  Normalise:  */
        if (totVal > 0) {
            data.map((entry, idx) => {
                let entry2 = {...entry};
                entry2[yAxis] = entry[yAxis] / totVal;
                data[idx] = entry2;   //`${Math.round(100*entry2)}%`;
                return true;
            });
        }
        /*  Format tooltip: */
        tooltipFormatter = (val) => {
            return `${Math.round(100 * val)}%`
        };
        // console.log('data after normalisation: ', data);
    }

    // console.log('dataColor: ', dataColor);

    /* Function for rendering label on pieChart:    */
    // const RADIAN = Math.PI / 180;
    // const renderCustomizedLabel = ({cx, cy, midAngle, innerRadius, outerRadius, percent, index}) => {
    //     const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    //     const x = cx + radius * Math.cos(-midAngle * RADIAN);
    //     const y = cy + radius * Math.sin(-midAngle * RADIAN);
    //
    //     return (
    //         <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
    //             {`${(percent * 100).toFixed(0)}%`}
    //         </text>
    //     );
    // };

    /*  Remap the field names:  */
    data = renameFields(data, dictFields);

    return (
        <ResponsiveContainer width={chartOpt.width} height={chartOpt.height} minHeight={chartOpt.minHeight}
                             minWidth={chartOpt.minWidth}>

            <PieChart width={chartOpt.width} height={chartOpt.height}>

                {/*Title: ----------------------------------------------------------------------------------------- */}
                <text x="50%" y="10" dominantBaseline="hanging" fontSize="15" fontWeight="bold" fill={'#768a9e'}>
                    {title}
                </text>

                {/*<Tooltip labelFormatter={tooltipFormatter}/>*/}
                <Tooltip formatter={tooltipFormatter}/>

                <Legend/>

                <Pie data={data}
                     dataKey={yAxis}
                     cx="50%"
                     cy="50%"
                     innerRadius={innerRadius}
                     outerRadius={outerRadius}
                     {...elemOpt}
                >
                    {data.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={dataColor[entry[xAxis]]}/>
                    ))}
                </Pie>
                )


                )}

            </PieChart>
        </ResponsiveContainer>
    );


    // const data01 = [
    //     {name: 'Group A', value: 400},
    //     {name: 'Group B', value: 300},
    //     {name: 'Group C', value: 300},
    //     {name: 'Group D', value: 200},
    // ];
    // const data02 = [
    //     {name: 'A1', value: 100},
    //     {name: 'A2', value: 300},
    //     {name: 'B1', value: 100},
    //     {name: 'B2', value: 80},
    //     {name: 'B3', value: 40},
    //     {name: 'B4', value: 30},
    //     {name: 'B5', value: 50},
    //     {name: 'C1', value: 100},
    //     {name: 'C2', value: 200},
    //     {name: 'D1', value: 150},
    //     {name: 'D2', value: 50},
    // ];
    // return (
    //     <ResponsiveContainer width={chartOpt.width} height={chartOpt.height} minHeight={chartOpt.minHeight}
    //                          minWidth={chartOpt.minWidth}>
    //         <PieChart width={200} height={200}>
    //             <Pie data={data01} dataKey="value" cx="50%" cy="50%" outerRadius={60} fill="#8884d8"/>
    //             <Pie data={data02} dataKey="value" cx="50%" cy="50%" innerRadius={70} outerRadius={90} fill="#82ca9d"
    //                  label/>
    //         </PieChart>
    //     </ResponsiveContainer>
    // );
}

/* Line Chart: */
export default function ChartsOdc(props) {
    /* ******************************************
    * Props:
    *   - data: data in json notation: [{f1: v1, f2: v1}, {f1: v2, f2: v2}, etc.]
    *   - xAxis: the field to represent the x-axis
    *   - yAxis: list of Y fields to be plotted
    *   - colorMap: dictionary {y_field: color}
    *   - dictKeys: dictonary of y keys to be renamed
    * ****************************************** */

    const data = props.data ? props.data : defData;
    const title = props.title ? props.title : null;
    const xAxis = props.xAxis ? props.xAxis : 'name';
    let yAxis = props.yAxis ? props.yAxis : ['y1', 'y2'];   // List of Y fields to display
    const colorMap = props.colorMap ? props.colorMap : null;    // mapping dictionary between field names and colors
    const chartOpt = props.chartOpt ? {...defChartOpt, ...props.chartOpt} : defChartOpt;
    const lineOpt = props.lineOpt ? {...defLineOpt, ...props.lineOpt} : defLineOpt;
    const dictKeys = props.dictKeys ? props.dictKeys : {};  // Dictionary between field values and display values
    const elemOpt = props.elemOpt ? props.elemOpt : defChartElemOpt;  // style options for pies: oppacity, animation etc.

    /* Transform yAxis into list if not list: */
    if (!Array.isArray(yAxis)) {
        yAxis = [yAxis];
    }

    /* Set the color map according to each Y field: */
    const defColors = yAxis.map(y => getRandomColor());
    const dataColor = colorMap ? {...colorMap} : defColors;

    /* Set random colors where not defined: */
    yAxis.forEach((y, idx) => {
        dataColor[y] = dataColor[y] ?? defColors[idx];
    });

    return (
        <ResponsiveContainer width={chartOpt.width} height={chartOpt.height} minHeight={chartOpt.minHeight}
                             minWidth={chartOpt.minWidth}>

            <LineChart data={data} margin={chartOpt.margin}>

                {/*Title: ----------------------------------------------------------------------------------------- */}
                <text x="50%" y="10" dominantBaseline="hanging" fontSize="15" fontWeight="bold" fill={'#768a9e'}>
                    {title}
                </text>

                {/*Cartesian Grid: ---------------------------------------------------------------------------------*/}
                {chartOpt.grid &&
                <CartesianGrid horizontal={chartOpt.grid.horizontal} vertical={chartOpt.grid.vertical}
                               strokeDasharray={chartOpt.grid.strokeDasharray}/>}

                <XAxis dataKey={xAxis}/>
                <YAxis/>
                <Tooltip/>
                <Legend/>

                {yAxis.map((y, idx) => {
                        let dataName = dictKeys[y] ? dictKeys[y] : y;
                        return <Line
                            type={lineOpt.type}
                            key={y}
                            dataKey={y}
                            name={dataName}
                            stroke={dataColor[y]}
                            dot={lineOpt.dot}
                            {...elemOpt}
                        />
                    }
                )}

            </LineChart>
        </ResponsiveContainer>
    );
}