/* *****************************************************************
*   OpenDC Styles for Google Maps, markers, vector layers
*
*  Maps configuration:
*
* Map ID and styles defined here: Project: open-dc-gmaps, map ID: 'map-id-odc'
* https://console.cloud.google.com/google/maps-apis/studio/maps/8ca100f2823e2c9e?project=open-dc-gmaps
*
* Maps ID:
*    - vector: '8ca100f2823e2c9e' ('map-id-odc')
*    - raster: '6159a46f07ef04c2' ('map-id-odc-raster')
*    - only city labels and rivers: '7326a842accf527' ('map-id-no-bkgd')
*
* Documentation:
*   - map options: https://developers.google.com/maps/documentation/javascript/reference/map#MapOptions
*   - polygon layer options: https://developers.google.com/maps/documentation/javascript/datalayer#style_options
*
****************************************************************** */

/*  import libs: */
import {DataFrame, toJSON} from "danfojs";
import {Marker, InfoWindow} from "@react-google-maps/api";

/*  import ODC components: */
import MapIcon from "./img/icon_gps.png";
import MapIconSelected from "./img/icon_gps_selected.png";
import MapIconOthers from "./img/icon_gps_faint.png";
import {copyObj, printMsg } from "../../jsOdcLib/GenericJsOdc";
import {setGradientOdc} from "./SymbologyOdc";
import React from "react";


/* ********************** Global variables **************************
* ****************************************************************** */
const NB_CLASSES = 6;   /*   number of categories to plot the data  */
const F_TOOLTIP = 'title';          /*  tooltip field  TO FIX DOES NOT WORK!!! */
const F_FILLCOLOR = 'fillColor';    /* name of style properties in Google Maps objects  */
const F_FILLOPACITY = 'fillOpacity';
const LIST_COL_STYLES = [F_TOOLTIP, F_FILLCOLOR, F_FILLOPACITY];
// to complete: strokeWeight etc.

const defTooltipFormatter = (rec) => {
    if (!rec.active) {
        return null;
    }
    console.log('rec map tooltip: ', rec.payload[0]);
    // // console.log('whole rec: ', rec);
    // let comName = rec.payload[0].payload[f_com_name];
    // let comId = rec.payload[0].payload[f_com_id];
    // return (
    //     <div style={{backgroundColor: "lightgrey", padding: 5, borderRadius: 5}}>
    //         {`${comName} (${comId})`}:<br/>
    //         Tps VP: {Math.round(rec.payload[0].payload[f_tt_car])} min<br/>
    //         Tps TC: {Math.round(rec.payload[0].payload[f_tt_pt])} min<br/>
    //     </div>
    // );
};


/* ********************** Default map tiles style *******************
    see full doc here:
    https://developers.google.com/maps/documentation/javascript/style-reference
* ****************************************************************** */
export const defMapStyles = [
    {
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#f5f5f5"
            }
        ]
    },
    {
        "elementType": "labels",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "elementType": "labels.icon",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#616161"
            }
        ]
    },
    {
        "elementType": "labels.text.stroke",
        "stylers": [
            {
                "color": "#f5f5f5"
            }
        ]
    },
    {
        "featureType": "administrative",
        "elementType": "geometry",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "administrative.land_parcel",
        "stylers": [
            {
                "visibility": "on"
            }
        ]
    },
    {
        "featureType": "administrative.land_parcel",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#bdbdbd"
            }
        ]
    },
    {
        "featureType": "administrative.neighborhood",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "poi",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "poi",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#eeeeee"
            }
        ]
    },
    {
        "featureType": "poi",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#757575"
            }
        ]
    },
    {
        "featureType": "poi.park",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#e5e5e5"
            }
        ]
    },
    {
        "featureType": "poi.park",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#9e9e9e"
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#ffffff"
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "labels.icon",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "road.arterial",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#757575"
            }
        ]
    },
    {
        "featureType": "road.highway",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#dadada"
            }
        ]
    },
    {
        "featureType": "road.highway",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#616161"
            }
        ]
    },
    {
        "featureType": "road.local",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#9e9e9e"
            }
        ]
    },
    {
        "featureType": "transit",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "transit.line",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#e5e5e5"
            }
        ]
    },
    {
        "featureType": "transit.station",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#eeeeee"
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#bcd0e4"
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#c9c9c9"
            }
        ]
    },
    {
        "featureType": "administrative.locality",
        "elementType": "labels.text",
        "stylers": [
            {
                "visibility": "simplified",
                "color": "#8f8f8f",
                "weight": 1,
            }
        ]
    },
];

/* ********************** Default map options ********************** */
export const defMapOptions = {
    // mapId: '8ca100f2823e2c9e', //'map-id-no-bkgd', // Error "TypeError: Cannot read properties of null (reading 'indexOf') at ..." when setting map Id!!!
    icon: MapIcon,
    iconSelected: MapIconSelected,   /* ODC props */
    iconOthers: MapIconOthers,       /* ODC props */
    center: /* based on IDF center */
        {
            lat: 48.83,
            lng: 2.33
        }
    ,
    zoom: 9,
    minZoom: 3,
    maxZoom: 17,
    gestureHandling: 'greedy',
    mapTypeControl: false,
    styles: [...defMapStyles],
    // zoomControl: boolean,
    // scaleControl: boolean,
    // streetViewControl: boolean,
    // rotateControl: boolean,
    // fullscreenControl: boolean
};

export const defMapContainerStyle = {
    position: 'relative', /*absolute: remove top to left values if absolute */
    width: '100%',
    // height: '83vh', /* for full screen map */
    height: '100%',  /* for map in card: IDF scale: 83% width*/
    minHeight: '150px',
    minWidth: '150px',
    // maxWidth: '1200px',
    overflow: 'hidden',
};

/* ********************** Default geometries styles *******************
Available on all geometries
    clickable: If true, the feature receives mouse and touch events
    visible: If true, the feature is visible.
    zIndex: All features are displayed on the map in order of their zIndex, with higher values displaying in front of features with lower values. Markers are always displayed in front of line-strings and polygons.
Available on point geometries
    cursor: Mouse cursor to show on hover.
    icon: Icon to show for the point geometry.
    shape: Defines the image map used for hit detection.
    title: Rollover text.
Available on line geometries
    strokeColor: The stroke color. All CSS3 colors are supported except for extended named colors.
    strokeOpacity: The stroke opacity between 0.0 and 1.0.
    strokeWeight: The stroke width in pixels.
Available on polygon geometries
    fillColor: The fill color. All CSS3 colors are supported except for extended named colors.
    fillOpacity: The fill opacity between 0.0 and 1.0.
    strokeColor: The stroke color. All CSS3 colors are supported except for extended named colors.
    strokeOpacity: The stroke opacity between 0.0 and 1.0.
    strokeWeight: The stroke width in pixels.
*
* ****************************************************************** */
export const defGeoJsonStyle = {
    icon: MapIcon,
    fillColor: 'transparent',
    fillOpacity: 0,
    strokeColor: 'grey',
    strokeOpacity: 0.9,
    strokeWeight: 0.5,
    clickable: true,
    cursor: true,
    // title: 'tooltip?',
};


const defStyleNullValues = {fillColor: '#0000ffff', fillOpacity: 0}; // Style applied to features not to be updated according to their properties

/*  for now simply imported from <GoogleMapsOdc /> ==> to set as customisable props!! */
export const defHoverPolygStyle = {
    fillColor: 'grey',
    fillOpacity: 0.5,
    strokeColor: 'black',
};

/* ********************** GeoJson styling function **********************
    - initGeoJsonStyle:     creates the style fields (fillColor, fillOpacity etc.) in the input geoJson layer
    - updateDfStyle:        updates the Df style columns to be passed to 'updateGeoJsonStyle'
    - updateGeoJsonStyle:   updates these styles values according to a Danfo dataframe containing the values for each feature key
    - geoJsonStyles:        the function passed to the <GoogleMapsOdc /> component: returns the styles defined in the properties of the layer feature
* *********************************************************************** */
export const geoJsonStyles = (feat) => {
    /* --------------------------------------------------------
    Function that sets a style according to a field where all values are between 0 and 1
    Add parameter to set style dynamically TO DO but not found in documentation...
    ----------------------------------------------------------*/

    // create function that fills the colors
    let tmpStyle = {...defGeoJsonStyle};

    const fillColor = feat.getProperty(F_FILLCOLOR);
    const fillOpacity = feat.getProperty(F_FILLOPACITY);
    const tooltip = feat.getProperty(F_TOOLTIP);
    // other styles TO DO...

    tmpStyle[F_FILLCOLOR] = fillColor ? fillColor : tmpStyle[F_FILLCOLOR];
    tmpStyle[F_FILLOPACITY] = fillOpacity ? fillOpacity : tmpStyle[F_FILLOPACITY];
    tmpStyle[F_TOOLTIP] = tooltip ? tooltip : tmpStyle[F_TOOLTIP];
    // other styles TO DO...

    return tmpStyle;
};

export const initGeoJsonStyle = (props) => {
    /* -----------------------------------------
    *   Function that sets the geoJson default style attributes keys and values to be updated later
    * -----------------------------------------*/
    let geoDataInit = copyObj(props.geoJson);
    let geoStyle = props ? props.geoStyle ? {...defGeoJsonStyle, ...props.geoStyle} : {...defGeoJsonStyle} : {...defGeoJsonStyle};
    // console.log('len geoData: ', props.geoJson.features.length, '\nBase geoData: ', props.geoJson);

    // Loop through features and set default style to each:
    for (let i = 0; i < geoDataInit.features.length; i++) {
        // Add default style fields to each feature:
        for (let [key, val] of Object.entries(geoStyle)) {
            geoDataInit.features[i].properties[key] = val;
        }
        // console.log('updated feature:: ', geoData.features[i]);
    }
    // console.log('Initialised geoData: ', geoDataInit);
    return geoDataInit;
};

const setStyleCategories = (props) => {
    /* -----------------------------------------
    TO COMPLETE FOR f_type='categorized' and scaleMode
    TO DO: UPDATE TO RETURN LEGEND OBJECT
    *     Function that given a dataframe column and a scaleMode, returns a list of values defining the range of each
    * category. Used in 'updateDfStyle'.
    * Props description:
    *   - dfData:   the dataframe with the fields to be plotted and fillColor, strokeWeight etc. styling fields
    *   - f_val:     the field to be plotted
    *   - f_type:    the type of data:
    *       * 'graduated' (continuous): DEFAULT
    *       * 'categorized' (discrete) TO DO
    *   - nbClasses: number of style categories
    *   - constantScale: [0, 15, 32, 150, ...]: if constant edge values, regardless of Df content ==> returns the list
    *   - scaleMode: how the data is spread: same as in QGIS:
    *       * equalInterval: DEFAULT
    *       * powerTen: [0, 10, 100, 1000, ..., 10^(nbClasses-1)]
    *       * equalCount TO DO
    *       * naturalBreaks TO DO
    *   - nbClasses: number of categories
    * ----------------------------------------- */
    const f_val = props.f_val;    // the column to be categorized
    const f_type = props.f_type ? props.f_type : 'graduated';
    const scaleMode = props.scaleMode ? props.scaleMode : 'equalInterval';
    const nbClasses = props.nbClasses ? props.nbClasses : NB_CLASSES;
    const minVal = (props.minVal !== undefined) ? props.minVal : props.dfData[[f_val]].min(); // !== undefined because if minVal = 0, props.minVal? return false

    /* Check if constantScale has values: */
    if (props.constantScale) return [...props.constantScale];

    // console.log('min val: ', minVal, props.minVal);
    let lVal = [minVal];  // the list of values defining when the category changes

    if (f_type === 'graduated') {
        /* get the field max value and distribute according to scaleMode: */
        const maxVal = props.dfData && props.dfData[[f_val]].max();
        // const minVal = props.dfData && props.dfData[[f_val]].min();
        console.log('Setting up scale categories, type ', f_type, ', mode: ', scaleMode, 'max val: ', maxVal);
        if (scaleMode === 'equalInterval') {
            for (let i = 1; i <= nbClasses; i++) {
                lVal[i] = i / nbClasses * maxVal;
            }
        } else if (scaleMode === 'powerTen') {
            for (let i = 1; i <= nbClasses; i++) {
                lVal[i] = Math.pow(10, i);
            }
            if (maxVal > lVal[nbClasses]) printMsg('Need more classes in setStyleCategories', 'warning')
        } else {
            printMsg('scaleMode to implement', 'error');
        }
    } else {
        printMsg('"categorized" category style to implement', 'error');
    }
    // console.log('Style categories: ', lVal);
    return lVal;
}

export const updateDfStyle = (props) => {
    /* -----------------------------------------
    *     Function that updates the dataframe style columns before being passed to 'updateGeoJsonStyle'
    * NOTE: Danfo cannot do replace like pandas like: df.loc[ix, col] = val ==> need to create a new Df!
    * Props description:
    *   - dfData:   the dataframe with the fields to be plotted and fillColor, strokeWeight etc. styling fields
    *   - f_val:     the field to be plotted
    *   - f_geoKeyDf:  the Df foreign key (must be unique)
    *   - constantScale: [0, 15, 32, 150, ...]: if constant edge values, regardless of Df content. Otherwise: scaleMode
    *   - colorRamp: the input color ramp: if not specified, built by the function using following props:
    *   - nbClasses: number of categories
    *   - minVal:   the value min to take into account (eg. 0). If not specified, tkes the min of the df column
    *   - f_type:    the type of data:
    *       * 'graduated' (continuous): DEFAULT
    *       * 'categorized' (discrete) TO DO
    *   - scaleMode: how the data is spread: same as in QGIS:
    *       * equalInterval: DEFAULT
    *       * powerTen: [0, 10, 100, 1000 etc.]
    *       * equalCount TO DO
    *       * naturalBreaks TO DO
    *   - opacity:  0.5 by default for now...
    *   - styleNullValues:   style to apply to values not found
    * ----------------------------------------- */
    const f_val = props.f_val;  // the field to categorized
    const f_geoKeyDf = props.f_geoKeyDf;
    let nbClasses = props.nbClasses ? props.nbClasses : props.constantScale ? props.constantScale.length : NB_CLASSES;
    const colorRamp = props.colorRamp ? props.colorRamp : setGradientOdc({nbClasses: nbClasses});
    const f_type = props.f_type ? props.f_type : 'graduated';
    const opacity = props.opacity ? props.opacity : 0.5; // update later TO DO
    const opacityRamp = props.opacityRamp ? props.opacityRamp : colorRamp.map(col => opacity); /* simple for now, to update later */
    /* strokeWeight TO DO */
    const styleNullValues = props.styleNullValues ? props.styleNullValues : defStyleNullValues;

    // const st = startTime();

    /* Set the field values defining the categories change: */
    const listVal = setStyleCategories(props);
    // console.log('List of edge values: ', listVal);

    /* Check listVal has the right number of classes (could be different if updateDfStyle is called with props constantScale): */
    if (listVal.length !== nbClasses) nbClasses = listVal.length;


    /* Creates a new dataframe with values and styles: */
    const applyStyle = (val) => {
        /* Function to be applied to each record of the dataframe */
        // console.log('applying style to ', val, 'in bands: ', listVal);
        if (f_type === 'graduated') {
            for (let i = 0; i < nbClasses; i++) {
                // let idx;
                if (i < (nbClasses - 1)) {
                    // idx = props.dfData && props.dfData.query(props.dfData[f_val].gt(listVal[i]).and(props.dfData[f_val].le(listVal[i + 1]))).index;
                    if ((val > listVal[i]) && (val <= listVal[i + 1])) return {
                        fillColor: colorRamp[i],
                        fillOpacity: opacityRamp[i],
                        title: 'UPDATE TOOLTIP FUNCTION HERE',
                    };
                    else if (val == null) return styleNullValues;
                } else {
                    if (val > listVal[i]) return {
                        fillColor: colorRamp[i],
                        fillOpacity: opacityRamp[i],
                        title: 'UPDATE TOOLTIP FUNCTION HERE'
                    };
                    else if (val == null) return styleNullValues;
                }
            }
        } else {
            printMsg('scaleMode to implement', 'error');
        }
        /* if data not in styles, set opacity to 0: */
        return styleNullValues;
    };
// console.log('f_geoKeyDf: ', f_geoKeyDf, 'f_val: ', f_val, ', col: ', props.dfData.columns);
// props.dfData.head(2).print();
// console.log(typeof f_val, props.dfData.constructor.name);
// console.log(props.dfData);
// console.log('ok?');
    const lKeys2 = props.dfData && props.dfData[f_geoKeyDf].values;
    const lVal2 = props.dfData && props.dfData[f_val].values;
    const lColors2 = lVal2.map(val => applyStyle(val).fillColor);
    const lOpacities2 = lVal2.map(val => applyStyle(val).fillOpacity);  // constant opacity for now...
    const lTooltip2 = lVal2.map(val => applyStyle(val).tooltip);  // UPDATE TOOLTIP HERE
    const objStyles2 = {};
    objStyles2[f_geoKeyDf] = lKeys2;
    objStyles2[f_val] = lVal2;
    objStyles2[F_FILLCOLOR] = lColors2;
    objStyles2[F_FILLOPACITY] = lOpacities2;
    objStyles2[F_TOOLTIP] = lTooltip2;

    /*update tooltip TO DO*/

    const dfStyle2 = new DataFrame(objStyles2); // , {index: props.dfData.index}

    // endTime(st, ' ** updateDfStyle ** ');

    return dfStyle2;

};

export const updateGeoJsonStyle = async (props) => {
    /* -----------------------------------------
    *     Function that updates the geoJson style properties according to df values
    * Props description:
    *   - geoData: the geoJson object to be updated (ref value updated, no copy)
    *   - dfData:  the Danfo dataframe with the values for each style fields to be set in the geoData
    *   - f_keyGeo: the foreign key of the geoData object
    *   - f_geoKey:  the foreign key of the data object (optional, kKeyGeo if not specified)
    *   - colStyles: the columns from the dataframe that have the style info
    *   - setIndexDf:  whether or not reset the df index. True by default
    * ----------------------------------------- */
    const f_keyGeo = props.f_keyGeo;
    const f_geoKeyDf = props.f_geoKeyDf ? props.f_geoKeyDf : f_keyGeo;
    const colStyles = props.colStyles ? props.colStyles : LIST_COL_STYLES; //[F_FILLCOLOR, F_FILLOPACITY];
    const setIndexDf = props.setIndexDf ? props.setIndexDf : true;
    const styleNullValues = props.styleNullValues ? props.styleNullValues : defStyleNullValues;

    /* Set index to df (add as option in case index is set): */
    // console.log('col df data: ', props.dfData && props.dfData.columns);
    // console.log('key: ', f_geoKeyDf);

    setIndexDf && props.dfData.setIndex({column: f_geoKeyDf, inplace: true});

    // console.log('Updating styles:', colStyles, 'for ', props.geoData.features.length, 'features');
    // console.log('geo data:', props.geoData);
    // console.log('Features index: ', props.dfData.index);

    // const st = startTime();
    let layerAttr;
    for (let i = 0; i < props.geoData.features.length; i++) {
        /* Get the current foreign key: */
        let fKey = props.geoData.features[i].properties[f_keyGeo];
// console.log('fKey: ', fKey);
        /* Extract info from dfData: */
        if (props.dfData.index.includes(fKey)) {
            // console.log('Key "', fKey, '" found in dataframe col "', f_geoKeyDf, '-', props.dfData);
            layerAttr = await toJSON(props.dfData.loc({rows: [fKey], columns: colStyles}));

            /* Update style info for the current feature: */
            colStyles.map(f_style => {
                props.geoData.features[i].properties[f_style] = layerAttr[0][f_style];
                return true;
            });
        } else {
            // console.log('Key "', fKey, '" NOT found in dataframe col "', f_geoKeyDf, '"');
            /* Apply default style: */
            colStyles.map(f_style => props.geoData.features[i].properties[f_style] = styleNullValues[f_style]);
        }
    }

    // endTime(st, ' ** Loop updateGeoJsonStyle ** '); // ~0.50ms for 1300 polygons
    // console.log('layerAttr: ', layerAttr);
};

/* ********************** Tooltip formatters **********************
    -
* *********************************************************************** */
export const defMapTooltipFormatter = (feat, zoom) => {
    /*  ************************
    *   Map object tooltip default formatter. To be called as follows:
    *
    *         <Tooltip content={tooltipFormatter}/>
    *
    *   If Use several fields and values to format tooltip, can use the example below (check 'payload' attribute
    *   structure to adapt to each situation)
    *
    * ************************ */
    const minZoom = 12;  //12

    if (zoom >= minZoom) {
        console.log('map tooltip feat: ', feat);  // check 'payload' attribute structure here
        console.log('current zoom: ', zoom);  // check 'payload' attribute structure here

        /* feat.getProperty(field_name) ==> returns value. How to get field names??
        *   For now stored in 'j' attribute:
        * */
        if (feat.j === undefined) {
            printMsg('Attribute "j" to change in function defMapTooltipFormatter... check if data object in other attribute', 'error');
            console.log('feat object: ', feat);
            return null;
        }
        const obj = feat.j;
        for (const [field, value] of Object.entries(obj)) {
            console.log(`${field}:\t${value}`);
        }

        // feat.forEachProperty(field => {console.log(field)});    // `${field}: ${feat.getProperty(field)}`
        // console.log(feat.toGeoJson());

        // const nb_digits = 2;
        // if (!feat.active) {
        //     return null;
        // }
        // let tmpVal = feat.payload[0].value;
        //
        // /* if other field to be displayed, can be accessed like that for example:
        // * let comName = feat.payload[0].payload[f_com_name];
        // * */
        //
        // /*  round value if float:   */
        // if (isFloat(tmpVal)) {
        //     /* if floating value, round to 2 digits:    */
        //     tmpVal = tmpVal.toFixed(nb_digits);
        // }
        // /*  field name: */
        // let tmpKey = feat.payload[0].dataKey;
        //
        // /*  tooltip formatted value*/
        // return (
        //     <div style={{backgroundColor: "rgba(68,73,82,0.11)", padding: 5, borderRadius: 5}}>
        //         {`${tmpKey}: ${tmpVal}`}
        //     </div>
        // );

        return (
            'tooltip to format'
        );
    } else {
        return null;
    }
};

export const TooltipMarkerOdc = (props) => {
    /* **************************
    *   TO FIX
    *   Google Maps marker used only for tooltips:
    *   Props:
    *       - tooltip: formatted info
    *       - lat:
    *       - lng:
    * ************************** */
    const show = props.show ? props.show : true;
    const tooltip = props.tooltip;
    const latLng = props.latLng;

    if (show === false) {
        return null;
    }
    console.log('in marker: ', latLng, tooltip);
    return (
        <Marker
            position={latLng}
            show={show}
            // icon={undefined}
            title={tooltip}
        >
            {/*<InfoWindow anchor={latLng}>*/}
            <h4>'toolipt?'</h4>
            {/*</InfoWindow>*/}
        </Marker>
    );
};