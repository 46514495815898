/* *******************************************************************
* Creates a combo box for website language selection
* USes react-country-flag: USes react-country-flag
* See:
*   - Dropdown: https://ant.design/components/dropdown/#API
********************************************************************* */

import React, {useState} from "react";
import {Dropdown, Space, Button} from "antd";
import {DownOutlined} from '@ant-design/icons';
import ReactCountryFlag from "react-country-flag";
import {useTranslation} from 'react-i18next';

/*  ODC Components: */

/*  Default styles: */
const apiPropsDef = {}; //arrow: true
const styleDropArrowDef = {color: 'black', fontSize: 10};
const styleFlagButtonDef = {type: 'text'};
const countryCodeDef = 'GB';

/*  Dictionary flag codes vs i18next codes: */
const dictLocaleCodes = {
    'FR': 'fr',
    'GB': 'en',
    'UK': 'en',
    'US': 'en',
};

/*  Dictionary i18next codes vs flag codes: */
const dictFlagsCodes = {
    'fr': 'FR',
    'en': 'GB',
    'en-US': 'GB',
    'en-GB': 'GB',
};

export const LanguageSelectionOdc = (props) => {
    /* -----------------------------------
    *
    *   Props:
    *       - code: country code. USes react-country-flag
    *
    * ----------------------------------- */
    const codeList = props.codeList ? props.codeList : ['FR', 'GB'];
    const styleFlagButton = props.styleFlagButton? {...styleFlagButtonDef, ...props.styleFlagButton}: styleFlagButtonDef;
    const apiProps = props.apiProps ? {...apiPropsDef, ...props.apiProps} : apiPropsDef;


    const {i18n} = useTranslation();

    const [locale, setLocale] = useState(i18n.language);

    /*  Function changing the locale language:  */
    const changeLocale = (code) => {
        const i18nCode = dictLocaleCodes[code] ? dictLocaleCodes[code] : code;
        if (i18n.language !== i18nCode) {
            i18n.changeLanguage(i18nCode);
            setLocale(i18nCode);
        }
    };

    /*  Build items: simply flag for now    */
    const items = codeList.map((code, ix) => {
        const label = <div>
            <ReactCountryFlag countryCode={code} svg/>
        </div>;
        return {key: ix, label: label, code: code}
    });

    /*  onClick Action to change the locale language:   */
    const onClick = ({key}) => {
        const code = items[key].code;
        /*  Change locale language: */
        changeLocale(code);
    };

console.log('default browser language: ', i18n.language);
    const countryCode = dictFlagsCodes[locale] ?? countryCodeDef;
    return (
        <Dropdown
            menu={{items, onClick,}}
            {...apiProps}
        >
            <Button onClick={(e) => e.preventDefault()} {...styleFlagButton}>
                <Space>
                    {locale && <ReactCountryFlag countryCode={countryCode} svg/>}
                    <DownOutlined style={styleDropArrowDef}/>
                </Space>
            </Button>
        </Dropdown>
    )

};
