import React, {useEffect, useState} from 'react';
import {BrowserRouter, Routes, Route} from 'react-router-dom';


/*  Translations and language variables: set as context TO DO:   */
import {ConfigProvider} from 'antd';  /*  Ant Design specific */
import './i18n';    /*  project specific translations*/
// import i18next from "i18next";


/* ODC Components:  */
import HeaderMenuOdc from "./components/layout/HeaderOdc";
// import FooterOdc from "./components/layout/FooterOdc";
// import ApiProviderOdc from "./contexts/ApiProviderOdc";  // access to API data

/* Project Pages:   */
// import HomePage from "./pages/HomePage";
// import TestsPage from "./pages/TestsPage";
import InseePage from "./pages/InseePage";
import DocInsee from "./pages/insee/DocInsee";

/* AppInfo: */
import {Links, AppInfo} from "./data/AppInfo";
import {endTime, startTime} from "./jsOdcLib/GenericJsOdc";
import {InseeCls} from "./pages/insee/CalcInsee";
// import {toJSON} from "danfojs";



/*  Load header and sider items from AppInfo: */
const headerItems = AppInfo.header.items;
const logo = AppInfo.header.logo;

function App() {

        // <ConfigProvider locale={{locale, setLocale}} componentSize='small'>
    /*  Set the app language to detected language:  */

    /*  Data to be shared across app: HANDLED HERE TO AVOID RELOAD ON PAGE CHANGE   */
    const [clsInsee, setClsInsee] = useState(null);
    const [firstLoad, setFirstLoad] = useState(false);  /*  Set to true once minimal data is loaded */
    const [fullLoad, setFullLoad] = useState(false);  /*  Set to true once all data is loaded */

    /* Load City-level INSEE data from csv file (quick load): */
    useEffect(() => {
        (async () => {
            /* Initialise the INSEE Class:   */
            let st = startTime();
            let inseeClsTmp = await new InseeCls().loadCityData();
            setClsInsee(inseeClsTmp);
            inseeClsTmp && console.log('dfInsee CITY = ', inseeClsTmp);
            inseeClsTmp && inseeClsTmp.citiesSummary['insee_from'].head(3).print();
            endTime(st, 'City-level data load');

            setFirstLoad(true);
        })();
    }, []);

    /* Load full INSEE data from csv file: */
    useEffect(() => {
        (async () => {
            /* Initialise the INSEE Class:   */
            if (clsInsee) {
                let st = startTime();
                let inseeClsTmp = await clsInsee.loadODdata();
                setClsInsee(inseeClsTmp);
                endTime(st, 'Full OD data load');
                inseeClsTmp && console.log('Full data = ');
                inseeClsTmp && inseeClsTmp.odfFullOD.df.head(3).print();

                setFullLoad(true);
            }
        })();
    }, [firstLoad]);


    return (
        <ConfigProvider >

            <BrowserRouter>

                {/*<ApiProviderOdc>*/}

                    <HeaderMenuOdc items={headerItems} logo={logo}/>

                    <Routes>

                        <Route
                            key="home"
                            path={Links.home}
                            element={<InseePage clsInsee={clsInsee} fullLoad={fullLoad}/>}
                        />

                        <Route
                            key="doc"
                            path={Links.documentation}
                            element={<DocInsee/>}
                        />

                        {/*<Route*/}
                        {/*    key="tests"*/}
                        {/*    path={Links.tests}*/}
                        {/*    element={<TestsPage/>}*/}
                        {/*/>*/}

                        {/*<Route*/}
                        {/*    key="insee_dt"*/}
                        {/*    path={Links.insee_dt}*/}
                        {/*    element={<InseePage clsInsee={clsInsee} test='ok!'/>}*/}
                        {/*/>*/}

                    </Routes>



                {/*</ApiProviderOdc>*/}

            </BrowserRouter>
        </ConfigProvider>


    );
}
;

export default App;