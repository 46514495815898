/* *****************************************************************
* Maps page for INSEE, called from InseePage - Only for styling purpose for now...
*
* Props:
*   -
*   - ...
****************************************************************** */
import React, {useCallback, useState, useRef, useMemo} from "react";
import {useTranslation} from "react-i18next";

/*  ODC components: */
import {LegendOdc, setGradientOdc} from "../../components/maps/SymbologyOdc";
import {Checkbox, Divider, Layout, Radio, Space} from "antd";

/*  Global variables: need to be consistent with InseePage.js:  */

const f_carbon = 'Bilan Carbone';
const f_carbon_norm = 'Bilan Carbone / depl';
const f_flow_mobpro = 'mobpro_tot';
const f_flow_mobsco = 'mobsco';
const f_flow_tot = 'flow_tot';
const f_flow_mobpro_mode = 'mobpro_selected_modes';
const f_tt_car = 'DURATION_driving';
const f_tt_pt = 'DURATION_transit';
const modesList = ['VP', 'TC', '2RM', 'Vélo', 'Marche'];

/*  Build scale, colors and legend objects for all fields:   */


/* ------------------- CITY LEVEL ---------------------------*/
/*  Bilan carbone:    */
const scaleBCnetCity = [0, 5000, 10000, 25000, 50000, 100000];
const LegendBCnetCity = () => {
    const {t} = useTranslation();
    return (
        <LegendOdc
            listValues={scaleBCnetCity}
            listColor={colorRampBCnet}
            direction={'vertical'}
            colSpan={6}
            title={t('Bilan carbone net total commune en Kg CO2')}
        />
    )
};

const scaleBCCity = [0, 0.5, 0.75, 1.25, 2, 5];
const LegendBCCity = () => {
    const {t} = useTranslation();
    return (
        <LegendOdc
            listValues={scaleBCCity}
            listColor={colorRampBC}
            direction={'vertical'}
            colSpan={6}
            title={t('Bilan carbone moyen par commune en Kg CO2 / déplacement')}
        />
    )
};

/*  Mob pro:    */
const scaleMobProCity = [0, 250, 1000, 5000, 20000, 50000, 100000, 200000];
const LegendMobProCity = () => {
    const {t} = useTranslation();
    return (
        <LegendOdc
            listValues={scaleMobProCity}
            listColor={colorRampMobPro}
            direction={'vertical'}
            colSpan={6}
            title={t('Flux journaliers domicile-travail par commune')}
        />)
};

/*  Mob sco:    */
const scaleMobScoCity = [0, 250, 1000, 5000, 20000, 50000, 100000, 200000];
const LegendMobScoCity = () => {
    const {t} = useTranslation();
    return (
        <LegendOdc
            listValues={scaleMobScoCity}
            listColor={colorRampMobSco}
            direction={'vertical'}
            colSpan={6}
            title={t('Flux journaliers domicile-études par commune')}
        />
    )
};

/*  Total pro + sco:    */
const scaleMobTotCity = [0, 250, 1000, 5000, 20000, 50000, 100000, 200000];
const LegendMobTotCity = () => {
    const {t} = useTranslation();
    return (
        <LegendOdc
            listValues={scaleMobTotCity}
            listColor={colorRampMobTot}
            direction={'vertical'}
            colSpan={6}
            title={t('Flux journaliers totaux (travail + études) par commune')}
        />
    )
};

/*  Temps VP & TC:    */
const scaleTT = [0, 15, 30, 45, 60, 90];
const LegendTTcarCity = () => {
    const {t} = useTranslation();
    return (
        <LegendOdc
            listValues={scaleTT}
            listColor={colorRampTT}
            direction={'vertical'}
            colSpan={6}
            suffix={' min'}
            title={t('Temps de parcours VP moyen par commune')}
        />
    )
};
const LegendTTtransitCity = () => {
    const {t} = useTranslation();
    return (
        <LegendOdc
            listValues={scaleTT}
            listColor={colorRampTT}
            direction={'vertical'}
            colSpan={6}
            suffix={' min'}
            title={t('Temps de parcours TC moyen par commune')}
        />
    )
};

/* ------------------- OD LEVEL ---------------------------*/
/*  Bilan carbone:    */
const scaleBCnetOD = [0, 5, 25, 100, 1000, 10000];
const LegendBCnetOD = () => {
    const {t} = useTranslation();
    return (
        <LegendOdc
            listValues={scaleBCnetOD}
            listColor={colorRampBCnet}
            direction={'vertical'}
            colSpan={6}
            title={t('Bilan carbone net OD en Kg CO2')}
        />
    )
};

const scaleBCOD = [0, 0.5, 0.75, 1.25, 2, 5];
const LegendBCOD = () => {
    const {t} = useTranslation();
    return (
        <LegendOdc
            listValues={scaleBCOD}
            listColor={colorRampBC}
            direction={'vertical'}
            colSpan={6}
            title={t('Bilan carbone moyen OD en Kg CO2 / déplacement')}
        />
    )
};

/*  Mob pro:    */
const scaleMobProOD = [0, 10, 100, 500, 2500, 5000, 10000, 25000];
const LegendMobProOD = () => {
    const {t} = useTranslation();
    return (
        <LegendOdc
            listValues={scaleMobProOD}
            listColor={colorRampMobPro}
            direction={'vertical'}
            colSpan={6}
            title={t('Flux journaliers domicile-travail OD')}
        />)
};

/*  Mob sco:    */
const scaleMobScoOD = [0, 10, 100, 500, 2500, 5000, 10000, 25000];
const LegendMobScoOD = () => {
    const {t} = useTranslation();
    return (
        <LegendOdc
            listValues={scaleMobScoOD}
            listColor={colorRampMobSco}
            direction={'vertical'}
            colSpan={6}
            title={t('Flux journaliers domicile-études OD')}
        />
    )
};

/*  Total pro + sco:    */
const scaleMobTotOD = [0, 50, 100, 500, 2500, 5000, 10000, 25000];
const LegendMobTotOD = () => {
    const {t} = useTranslation();
    return (
        <LegendOdc
            listValues={scaleMobTotOD}
            listColor={colorRampMobTot}
            direction={'vertical'}
            colSpan={6}
            title={t('Flux journaliers totaux (travail + études) OD')}
        />
    )
};

const LegendTTcarOD = () => {
    const {t} = useTranslation();
    return (
        <LegendOdc
            listValues={scaleTT}
            listColor={colorRampTT}
            direction={'vertical'}
            colSpan={6}
            suffix={' min'}
            title={t("Temps de parcours VP moyen sur l'OD")}
        />
    )
};
const LegendTTtransitOD = () => {
    const {t} = useTranslation();
    return (
        <LegendOdc
            listValues={scaleTT}
            listColor={colorRampTT}
            direction={'vertical'}
            colSpan={6}
            suffix={' min'}
            title={t("Temps de parcours TC moyen sur l'OD")}
        />
    )
};
/* ------------------- COLOR RAMPS ---------------------------*/
const colorRampBCnet = [
    "#018571",
    "#67bfb1",
    "#c6e5e1",
    "#ede1c5",
    "#d4af69",
    "#a6611a",
];
const colorRampBC = [...colorRampBCnet];
const colorRampMobPro = setGradientOdc({nbClasses: scaleMobProCity.length});
const colorRampMobSco = setGradientOdc({nbClasses: scaleMobScoCity.length});
const colorRampMobTot = setGradientOdc({nbClasses: scaleMobTotCity.length});
const colorRampTT = [
    "rgba(0, 97, 0, 0.8)",
    "rgba(139, 181, 0, 0.8)",
    "rgba(255, 229, 0, 0.8)",
    "rgba(255, 166, 0, 0.8)",
    "rgba(255, 111, 0, 0.8)",
    "rgba(255, 34, 0, 0.8)",
];


const dictSiderOptionsCity = {};
const dictSiderOptionsOD = {};

dictSiderOptionsCity[f_carbon_norm] = {scale: scaleBCCity, colorRamp: colorRampBC, legend: <LegendBCCity />};
dictSiderOptionsCity[f_carbon] = {scale: scaleBCnetCity, colorRamp: colorRampBCnet, legend: <LegendBCnetCity />};
dictSiderOptionsCity[f_flow_mobpro] = {scale: scaleMobProCity, colorRamp: colorRampMobPro, legend: <LegendMobProCity />};
dictSiderOptionsCity[f_flow_mobpro_mode] = {scale: scaleMobProCity, colorRamp: colorRampMobPro, legend: <LegendMobProCity />};
dictSiderOptionsCity[f_flow_mobsco] = {scale: scaleMobScoCity, colorRamp: colorRampMobSco, legend: <LegendMobScoCity />};
dictSiderOptionsCity[f_flow_tot] = {scale: scaleMobTotCity, colorRamp: colorRampMobTot, legend: <LegendMobTotCity />};
dictSiderOptionsCity[f_tt_car] = {scale: scaleTT, colorRamp: colorRampTT, legend: <LegendTTcarCity />};
dictSiderOptionsCity[f_tt_pt] = {scale: scaleTT, colorRamp: colorRampTT, legend: <LegendTTtransitCity />};

dictSiderOptionsOD[f_carbon_norm] = {scale: scaleBCOD, colorRamp: colorRampBC, legend: <LegendBCOD />};
dictSiderOptionsOD[f_carbon] = {scale: scaleBCnetOD, colorRamp: colorRampBCnet, legend: <LegendBCnetOD />};
dictSiderOptionsOD[f_flow_mobpro] = {scale: scaleMobProOD, colorRamp: colorRampMobPro, legend: <LegendMobProOD />};
dictSiderOptionsOD[f_flow_mobpro_mode] = {scale: scaleMobProOD, colorRamp: colorRampMobPro, legend: <LegendMobProOD />};
dictSiderOptionsOD[f_flow_mobsco] = {scale: scaleMobScoOD, colorRamp: colorRampMobSco, legend: <LegendMobScoOD />};
dictSiderOptionsOD[f_flow_tot] = {scale: scaleMobTotOD, colorRamp: colorRampMobTot, legend: <LegendMobTotOD />};
dictSiderOptionsOD[f_tt_car] = {scale: scaleTT, colorRamp: colorRampTT, legend: <LegendTTcarOD />};
dictSiderOptionsOD[f_tt_pt] = {scale: scaleTT, colorRamp: colorRampTT, legend: <LegendTTtransitOD />};

export {dictSiderOptionsCity, dictSiderOptionsOD};


/*  Map Sider: has to be custom  */
export const SiderMapCarbon = React.memo((props) => {
    /* -----------------------------
    *     Function that builds the map sider with all the options
    *     Props:
    *       - onAggType: returns whether aggregate ('agg') or OD ('od') data
    *       - onCheck: action on check options
    *       - onCheckMode: action on mode choice when option flow mobPro is selected
    * ----------------------------- */

    const {Sider} = Layout;
    // const {Panel} = Collapse;
    const {t} = useTranslation();

    /*  Default styles: */
    const theme = 'light';
    const styleSiderLabelDef = {color: 'grey', paddingTop: '1px', paddingBottom: '0px', fontSize: 14};  //  paddingRight: '10px', borderBottom: '1px solid darkgrey'
    const styleSiderOptionsDef = {color: 'black', paddingLeft: '5px', fontSize: 12, paddingBottom: '2px'};
    const styleModesOptionsDef = {color: 'grey', paddingLeft: '25px', fontSize: 10, paddingBottom: '2px'};
    const styleSiderDef = {
        theme: theme,
        collapsible: true,
        width: 120,
        collapsedWidth: 30,
        breakpoint: 'xl',
        height: '100%',
    };
    /*  Props:  */
    /*  Options from Sidebar API:   */
    const breakpoint = props.breakpoint ? props.breakpoint : 'md';
    const collapsedWidth = props.collapsedWidth ? props.collapsedWidth : '24';
    const onCollapse = props.onCollapse ? props.onCollapse : null;
    const onBreakpoint = props.onBreakpoint ? props.onBreakpoint : onCollapse;
    const collapsed = props.collapsed ? props.collapsed : false;
    const style = props.style ? {...styleSiderDef, ...props.style} : styleSiderDef;
    const width = props.width ? props.width : 150;
    /*  Custom options: */
    const maxHeight = props.maxHeight ? (typeof (props.maxHeight) === 'string') ? props.maxHeight : `${props.maxHeight}px` : '100%';
    const labelStyle = props.labelStyle ? {...styleSiderLabelDef, ...props.labelStyle} : styleSiderLabelDef;
    const optionsStyle = props.optionsStyle ? {...styleSiderOptionsDef, ...props.optionsStyle} : styleSiderOptionsDef;
    const modeStyles = props.modeStyles ? {...styleModesOptionsDef, ...props.modeStyles} : styleModesOptionsDef;
    const direction = props.direction ? props.direction : 'vertical';
    const defaultModes = props.defaultModes ? props.defaultModes : [...modesList]; /*  transport modes that are ticked by default  */
    const defaultValue = props.defaultValue? props.defaultValue: f_carbon;

    /*  States: */
    const [selectMode, setSelectMode] = useState(false);    /*  Transport mode is selectable only if option 'f_flow_mobpro' is selected */
    const [radioValue, setRadioValue] = useState(defaultValue); // set to first radio group option by default
    const radioValueRef = useRef(defaultValue);

    /*  check fields:   */
    const onCheck = useCallback((e) => {
        const val = e.target.value;
        setRadioValue(val);
        radioValueRef.current = val;
        props.onCheck && props.onCheck(val);

        /*  activate modes if mobpro selected:  */
        (val === f_flow_mobpro) ? setSelectMode(true) : setSelectMode(false);
    }, [props.onCheck]);

    /*  check modes for mobpro: */
    const onCheckMode = useCallback((e) => {
        props.onCheckMode && props.onCheckMode(e);
    }, [props.onCheckMode]);


    /*  the list of modes activated only if mobpro is selected: works ok    */
    const CheckList = useCallback(() => {
        /* -------------------------
        *     Check options for transport mode selection:
        * ------------------------- */
        const checkOptions = selectMode? {onChange: onCheckMode, defaultValue: defaultModes}: {disabled: true};
        return (
            <div>
                {/*<Checkbox.Group onChange={onCheckMode} defaultValue={defaultModes} disabled={selectMode}>*/}
                    <Checkbox.Group {...checkOptions}>
                    {defaultModes.map((mode, idx) => {
                        return (
                            <div key={`${idx}_div`}>
                                <Checkbox value={mode} key={`check_${mode}`} style={modeStyles}>{t(mode)}</Checkbox>
                            </div>
                        )
                    })}
                </Checkbox.Group>
            </div>
        );
    }, [selectMode]);


    /*  the list of fields to be mapped: flickers even with useCallback [radioValue, selectMode]  */
    const RadioList = useCallback(() => {

        const checkItems = <CheckList/>;

        return (
            <Radio.Group onChange={onCheck} value={radioValueRef.current}>
                {/*Carbon data:*/}
                <div style={labelStyle}>
                    <Divider orientation="left" style={labelStyle}>{t('Bilans carbone')}</Divider>
                </div>
                <div>
                    <Space direction={direction} align={'start'} size={'small'} wrap={true}>
                        <Radio value={f_carbon} key={f_carbon} style={optionsStyle}>{t(f_carbon)}</Radio>
                    </Space>
                    <Space direction={direction} align={'start'} size={'small'} wrap={true}>
                        <Radio value={f_carbon_norm} key={f_carbon_norm} style={optionsStyle}>{t(f_carbon_norm)}</Radio>
                    </Space>
                </div>

                {/*Travel time data:*/}
                <div style={labelStyle}>
                    <Divider orientation="left" style={labelStyle}>{t('Temps de parcours')}</Divider>
                </div>
                <div>
                    <Space direction={direction} align={'start'} size={'small'} wrap={true}>
                        <Radio value={f_tt_car} key={f_tt_car} style={optionsStyle}>{t(f_tt_car)}</Radio>
                    </Space>
                    <Space direction={direction} align={'start'} size={'small'} wrap={true}>
                        <Radio value={f_tt_pt} key={f_tt_pt} style={optionsStyle}>{t(f_tt_pt)}</Radio>
                    </Space>
                </div>

                {/*Commuter data:*/}
                <div style={labelStyle}>
                    <Divider orientation="left" style={labelStyle}>{t('Flux pendulaires')}</Divider>
                </div>
                <div>
                    <Space direction={direction} align={'start'} size={'small'} wrap={true}>
                        <Radio value={f_flow_mobpro} key={f_flow_mobpro} style={optionsStyle}>{t(f_flow_mobpro)}</Radio>
                    </Space>

                    {/*Sub options of mode list:*/}
                    {/*<CheckList/>*/}
                    {checkItems}

                    <Space direction={direction} align={'start'} size={'small'} wrap={true}>
                        <Radio value={f_flow_mobsco} key={f_flow_mobsco} style={optionsStyle}>{t(f_flow_mobsco)}</Radio>
                    </Space>
                    <Space direction={direction} align={'start'} size={'small'} wrap={true}>
                        <Radio value={f_flow_tot} key={f_flow_tot} style={optionsStyle}>{t(f_flow_tot)}</Radio>
                    </Space>
                </div>
            </Radio.Group>
        );
    }, [onCheck, selectMode]);

    return (
        <Sider
            breakpoint={breakpoint}
            collapsed={collapsed}
            collapsedWidth={collapsedWidth}
            style={style}
            theme={theme}
            width={width}
            trigger={null}
            onBreakpoint={onBreakpoint}
            onCollapse={onCollapse}
        >
            <div style={{overflowY: 'scroll', overflowX: 'auto', height: maxHeight}}>

                {<RadioList/>}

            </div>

        </Sider>
    )
});
