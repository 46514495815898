import React from "react";

/* *****************************************************************
* Handles Errors in components
********************************************************************* */

/* *****************************************************************
* "react-error-boundary" approach: Fallback UI and log messages:
* requires import { ErrorBoundary } from 'react-error-boundary'; on every module using it,
* then wrap error prone components with
*     <ErrorBoundary onError={logError} fallback={ErrorFallback}>
*       <Error prone component>
*     </ErrorBoundary>
 ***************************************************************** */
export function ErrorFallbackOdc({error, resetErrorBoundary, message}) {
  return (
    <div>
      <h2>{message? message: "An error was found!"}</h2>
      <p>Error: {error.message}</p>
      <button onClick={resetErrorBoundary}>Reset</button>
    </div>
  )
}

export function logErrorOdc(error, errorInfo, message) {
  message && console.log(message);
  console.log("logError:", error.message, errorInfo.componentStack);
}

// Custom ODC class: should not be used, use standard package ErrorBoundary instead:
// export default class ErrorBoundaryOdc extends React.Component {
//   constructor(props) {
//     super(props);
//     this.state = {error: null};
//     this.reset = this.reset.bind(this);
//   }
//
//   // Get error:
//   static getDerivedStateFromError(error) {
//     return {error};
//   }
//
//   // Catch error:
//   componentDidCatch(error, errorInfo) {
//     console.log("logError: ", error.message, errorInfo.componentStack);
//   }
//
//   // Reset component:
//   reset() {
//     this.setState({error: null});
//   }
//
//   render() {
//     if (this.state.error) {
//         return (
//           <div>
//             <em>{this.props.message? this.props.message: "An error was detected!"}</em>
//             <p><button onClick={this.reset}>Reset component</button></p>
//           </div>
//         );
//     }
//     else {
//       return this.props.children;
//     }
//   }
// }

// // "react-error-boundary" approach: make it work?
// function ErrorFallback({error, resetErrorBoundary}) {
//   return (
//     <div>
//       <h2>An error was found!</h2>
//       <p>Error: {error.message}</p>
//       <button onClick={resetErrorBoundary}>Reset</button>
//     </div>
//   )
// }
//
// function logError(error, errorInfo) {
//   console.log("logError:", error.message, errorInfo.componentStack);
// }
//
//
// // This needs to be modified
// export default class ErrorBoundaryOdc extends React.Component {
//   render() {
//       return (
//           <ErrorBoundary onError={logError} fallback={ErrorFallback}>
//             {this.props.children}
//           </ErrorBoundary>
//       )
//   }
// }

