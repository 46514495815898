/* *******************************************************************
* ODC Sider
*
* See doc and options here:
*   https://ant.design/components/layout/#Layout.Sider
*   https://ant.design/components/menu/#API
********************************************************************* */

import React, {useState, useEffect} from 'react';
import {Layout, Menu} from 'antd';
import {Link} from 'react-router-dom';
import {UserOutlined, MenuFoldOutlined, MenuUnfoldOutlined} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

/* Ant styles: */
import 'antd/dist/antd.min.css';

/* App info (items labels and icons etc.): */
// import {AppInfo, UserInfo} from "../../data/AppInfo";

/*  ODC logos:  */
// import logoOdcMini from '../../img/ODClogo-mini_green.png';
// import logoOdcFull from '../../img/ODClogo-full_green_frame.png';

/*  ODC Components: */
import {gridBreakpoints} from "../../jsOdcLib/GenericJsOdc";

/* **************************** Styles ******************************* */
const theme = 'light';
const siderStyleDef = {height: '100%', backgroundColor: '#ECECEC', padding: '10px', borderRadius: '10px', wordWrap: 'break-spaces', };  /* custom sider style   */
const menuStyleDef = {height: '100%'}; //width: 200, height: '100vh', marginTop: 10
// const openKeys = AppInfo.sidebar.links.map(link => link.key);    /* Make the sub links open by default! */
const siderWidthDef = 200;
const collapsedWidthDef = 80;   /* Width of the collapsed sidebar, by setting to 0 a special trigger will appear */
const breakpointDef = 'xl';    /*Breakpoints of the responsive layout	xs | sm | md | lg | xl | xxl*/

/* **************************** Default items ************************** */
/*  Default items for debugging:    */
const itemsDef = [
    {key: '1', icon: <UserOutlined/>, label: 'nav 1',},
    {
        key: '2',
        icon: <MenuFoldOutlined/>,
        label: 'nav 2',
        link: '/',
        children: [{key: 11, label: 'sub 1'}, {key: 12, label: 'sub 2'}]
    },
    {key: '3', icon: <MenuUnfoldOutlined/>, label: 'nav 3',},
    // {key: '4', icon: logoOdcFull, label: 'ODC', link: 'https://wwww.open-dc.com'},
];


const { Sider } = Layout;

export default function SiderMenuOdc(props) {
    /* -----------------------------------
    *  MAKE RECURSIVE links TO ALL CHILDREN DEPTH TO DO
    *   Menu Sider
    *   Props:
    *       - items:
    *       - ...
    * ----------------------------------- */
    const items = props.items ? props.items : itemsDef;
    const siderStyle = props.headerStyle ? props.headerStyle : siderStyleDef;
    const menuStyle = props.menuStyle ? props.menuStyle : menuStyleDef;
    const width = props.width? props.width: siderWidthDef;
    const collapsedWidth = props.collapsedWidth? props.collapsedWidth: collapsedWidthDef;
    const breakpoint = props.breakpoint? props.breakpoint: breakpointDef;

    /* items translation management:    */
    const { t } = useTranslation();

    /* Collapsed state:    */
    const [collapsed, setCollapsed] = useState(false);

    /*  check screen width to set collapsed as true or false    */
    useEffect(() => {
        const w = window.innerWidth;
        const maxW = gridBreakpoints[breakpoint];
        /* collapse if screen smaller than breakpoint */
        (w < maxW) ? setCollapsed(true) : setCollapsed(false);
        // console.log('window width: ', w, 'max width: ', maxW);
    }, [breakpoint]);

    /*  Update items to create links (as Menu.Item is deprecated): */
    let menuItems = [...items];

    /* transform labels to links MAKE RECURSIVE TO ALL CHILDREN DEPTH TO DO */
    items.map((item, ix) => {
        menuItems[ix].label = item.link? <Link to={item.link}>{t(item.label.toString())}</Link>: item.label;
        /* Map children links:  */
        item.children && item.children.map((child, iy) => {
// console.log('child label: ', child.label.toString());
            menuItems[ix].children[iy].label = child.link? <Link to={child.link}>{t(child.label.toString())}</Link>: child.label;
            return true;
        });
        return true;
    });

    return (
        <Sider
            style={siderStyle}
            theme={theme}
            collapsible={true}
            collapsed={collapsed}
            width={width}
            collapsedWidth={collapsedWidth}
            breakpoint={breakpoint}
            onCollapse={() => setCollapsed(!collapsed)}
        >
            <Menu
                mode="inline" // "vertical"   //
                style={menuStyle}
                // openKeys={openKeys}
                items={menuItems}

            >

            </Menu>
        </Sider>

    );
}

