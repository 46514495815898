/* *****************************************************************
* KPIs for INSEE, called from InseePage. Handles the layout and formatting
****************************************************************** */

import React from "react";
import { useTranslation } from 'react-i18next';

/*  ODC Components: */
import KpiOdc from "../../components/layout/KpiOdc";
import {formatNumber, roundToMultiple} from "../../jsOdcLib/GenericJsOdc";

/* Declarations:    */
const defTitles = {
    fromTo: 'Direction',
    cityName: 'Ville',
    cityCode: 'CODGEO',
    nbTrips: 'Trajets Domicile-Travail',
    carbAvg: 'Bilan carbone Moyen',
    carbTot: 'Bilan carbone Total',
    timeCar: 'Temps moyen VP',
    timePT: 'Temps moyen TC',
    percOut: '% Hors IDF',
};

const defTooltips = {
    fromTo: 'Indique si les données sont en origine ou destination pour la commune sélectionnée',
    cityName: 'Nom de la commune sélectionnée',
    cityCode: 'Code INSEE de la commune sélectionnée',
    nbTrips: 'Nombre de déplacements Domicile-Travail par jour',
    carbAvg: 'Bilan carbone moyen lié aux déplacements par nombre de déplacements et par jour',
    carbTot: 'Bilan carbone total lié aux déplacements domicile-travail en valeur nette journalière',
    timeCar: 'Temps moyen en véhicule particulier motorisé pour les trajets domicile-travail',
    timePT: 'Temps moyen en transports en commun pour les trajets domicile-travail',
    percOut: "Pourcentage de flux émis ou reçus par la commune hors Île de France. Le bilan carbone et temps de parcours des communes dont ce pourcentage est élevé n'est pas fiable car seules les liasions internes à l'Île de France ont été calculées",
};

const defStruct = {
    fromTo: '-',
    cityName: '-',
    cityCode: '-',
    nbTrips: '-',
    carbAvg: '-',
    carbTot: '-',
    timeCar: '-',
    timePT: '-',
    percOut: '-',
};


/* Field names: need to be consistent with InseePage.js & CalcInsee.js:    */
const f_from = 'insee_from';    // origin
const f_to = 'insee_to';        // destination
const f_com_id = 'COM_ID';      // total origin + destination
// const f_weight = 'mobpro_tot';
const f_tt_car = 'DURATION_driving';
const f_tt_pt = 'DURATION_transit';

const dictTitleFromTo = {};     // returns the title indicating whether origin, destination or total
dictTitleFromTo[f_from] = 'Origines';
dictTitleFromTo[f_to] = 'Destinations';
dictTitleFromTo[f_com_id] = 'Total origines + destinations';


/* Render functions: keys must be the KPIs titles!  */
const renderTrips = (nbTrips) => {
    if ((nbTrips === null) || (nbTrips === undefined) || (isNaN(nbTrips))) {
        return '-';
    } else {
        return formatNumber(roundToMultiple(nbTrips, 50), 0);
    }
};

const renderPerc = (perc) => {

    if ((perc === null) || (perc === undefined) || (isNaN(perc))) {
        return '-';
    }
    let nbDigits = (perc < 0.05) ? 1 : 0;
    let textStyle = {};
    if (perc > 0.25) {
        textStyle = {color: 'red'};
    }
    let formattedPerc = `${formatNumber(100 * perc, nbDigits)}%`;
// console.log('nbDigits: ', nbDigits, 'perc: ', perc, Math.round(100*perc, nbDigits));
    return (
        <span style={textStyle}>
        {formattedPerc}
        </span>
    );

};

const renderCarb = (carbVal, nbDigits, suffix) => {
    if ((carbVal === null) || (carbVal === undefined) || (isNaN(carbVal))) {
        return '-';
    } else {
        /*  Roudn to multiple of 10 if no digit:    */
        let rndVal = (nbDigits > 0) ? formatNumber(carbVal, nbDigits) : formatNumber(roundToMultiple(carbVal, 10), nbDigits);
        return (
            `${rndVal} Kg CO2 ${suffix ? suffix : ''}`
        );
    }
};

const renderTime = (tt) => {
    if ((tt === null) || (tt === undefined) || (isNaN(tt))) {
        return '-';
    } else {
        let rndVal = formatNumber(tt, 0);
        return (
            `${rndVal} min`
        );
    }
};

const render_func = {
    cityCode: (val) => {
        return val
    },    // no format
    cityName: (val) => {
      return (
          <span style={{fontFamily: 'bold', fontSize: 22}}>{val}</span>
      )
    },
    nbTrips: renderTrips,
    carbAvg: (val) => {
        return `${renderCarb(val, 2, '/ depl.')}`
    },
    carbTot: (val) => {
        return `${renderCarb(val, 0)}`
    },
    timeCar: renderTime,
    timePT: renderTime,
    percOut: renderPerc,
};


export default function KpiInsee(props) {
    /* *****************************************************************
    * KPIs layout for INSEE, called from InseePage
    *   Props:
    *       - citySummary: the city information: structure defined in CalcInsee.js
    *       - fromTo: 'insee_from', 'insee_to' or 'com_id' if total
    ****************************************************************** */
    const citySummary = props.citySummary ? props.citySummary : null;
    const fromTo = props.fromTo ? props.fromTo : f_from;
    const kpiTitles = props.kpiTitles ? props.kpiTitles : defTitles;
    const kpiTooltips = props.kpiTooltips ? props.kpiTooltips : defTooltips;
    const kpiStruct = props.kpiStruct ? props.kpiStruct : defStruct;

    /*  translation:    */
    const { t } = useTranslation();

    /*  Update data with city summary:  */
    //Tps moy VP: ${formatNumber(citySummaryProps.ttCar, 0)}min - Tps moy TC: ${formatNumber(citySummaryProps.ttPt, 0)}min`;
    if (citySummary !== null) {
        kpiStruct.fromTo = dictTitleFromTo[fromTo];
        kpiStruct.cityName = citySummary.name;
        kpiStruct.cityCode = citySummary.code;
        kpiStruct.nbTrips = citySummary.nbTrips;
        kpiStruct.carbAvg = citySummary.bc;
        kpiStruct.carbTot = citySummary.bcNet;
        kpiStruct.timeCar = citySummary.tt[f_tt_car];
        kpiStruct.timePT = citySummary.tt[f_tt_pt];
        kpiStruct.percOut = citySummary.percOut;
    }

    /*  Create list of KPIs:    */
    let kpiList = [];
    let kpiRenderList = [];
    let kpiTooltipsList = [];
    Object.entries(kpiStruct).forEach(([key, content]) => {
        kpiList.push({title: kpiTitles[key], content: content});
        kpiRenderList.push(render_func[key]);
        kpiTooltipsList.push(kpiTooltips[key]);
    });

    /* KPI with translation: TO FIX */
    const kpiItems = kpiList.map((kpi, ix) => {
        // let kpiStyle = {formatter: render_func[kpi.title]};
        let kpiStyle = {formatter: kpiRenderList[ix]};
        let tooltip = kpiTooltipsList[ix];

// console.log('tooltip: ', tooltip);
// console.log('language: ', i18n.language, 'kpi title: ', kpi.title, t(kpi.title));
// console.log(kpi.content, (typeof kpi.content === "string"));

        return (
            <li key={ix} style={{display: 'inline-block'}} title={tooltip}>
                <KpiOdc
                    title={t(kpi.title)}
                    content={(typeof kpi.content === "string")? t(kpi.content): kpi.content}
                    kpiStyle={kpiStyle}/>
            </li>
        );
    });
    return (
        <ul>
            {kpiItems}
        </ul>
    );
};
