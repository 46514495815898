/*  TO FIX / CONTINUE   */

import React from "react";
import {Row, Col} from "antd";
import {useTranslation} from "react-i18next";

/*  ODC Data:   */
import {AppInfo} from "../../data/AppInfo";

/* **************************** Styles ******************************* */
const theme = 'light';
const footerStyleDef = {
    theme: theme,
    width: '100%',
    backgroundColor: 'rgba(147,172,157,0.11)',
    // margin: 'auto', /*    to horizontally center the div  */
    // width: '50%',   /*    to horizontally center the div  */
    // maxWidth: 1000,
    // borderWidth: 1,
    // borderStyle: 'solid',
    padding: '15px 5px 15px 20px',
    marginTop: 10,
    borderRadius: 15,
    whiteSpace: 'auto',
    overflowX: 'wrap',
};

const colStyleDef = {
    fontSize: 12,
    paddingRight: 5,
};

const disclaimerStyleDef = {
    fontSize: 10,
};


const disclaimerDef = {
    'en': "This application is a Proof of Concept version to demonstrate our capabilities. Users should not make commercial use of the data shown before contacting OpenDC.",
    'fr': "Cette application est une démonstration. Les utilisateurs ne doivent pas faire d'usage commercial de cette donnée sans contacter OpenDC."
};

/* **************************** Default items ************************** */
/*  Default Footer:    */
const FooterDef = (props) => {
    const version = props ? props.version ? `version ${props.version}` : `version ${AppInfo.version.num}` : `version ${AppInfo.version.num}`;
    const date = props ? props.date ? props.date : AppInfo.version.date : AppInfo.version.date;
    const colStyle = props ? props.colStyle ? props.colStyle : colStyleDef : colStyleDef;
    const disclaimerStyle = props ? props.disclaimerStyle ? props.disclaimerStyle : disclaimerStyleDef : disclaimerStyleDef;

    /*  Language options:   */
    const {i18n, t} = useTranslation();

    const footerText = (i18n.language === 'fr') ? 'Application web développée par ' : 'Web application developed by ';
    const disclaimer = (i18n.language === 'fr') ? disclaimerDef['fr'] : disclaimerDef['en'];

    return (
            <Row align="middle" justify="space-between">
                <Col span={10} style={colStyle}>
                    {footerText}<a href='https://www.open-dc.com' target='_blank'>OpenDC.</a>
                </Col>
                <Col span={8} style={colStyle}>
                    <span>{version}</span><br/>
                    <span>{date}</span>
                </Col>
                <Col span={6} style={{...colStyle, ...{textAlign: 'right'}}}>
                    <span>Contact: </span><br/>
                    <span><a href="mailto:info@open-dc.com" style={{whiteSpace: 'nowrap'}}>info@open-dc.com</a></span>
                </Col>

                <Col span={24} style={{...disclaimerStyle, ...{textAlign: 'justify'}}}>
                    {disclaimer}
                </Col>
            </Row>
    )
};

function FooterOdc(props) {
    /* -----------------------------------
    *   Menu Footer
    *   Props:
    *       - items:
    *       - ...
    * ----------------------------------- */
    const items = props.items ? props.items : <FooterDef {...props} />;
    const style = props.style ? props.style : footerStyleDef;


    return (
        <div style={style}>
            {items}
        </div>
    )
}

export default React.memo(FooterOdc);