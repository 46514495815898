/* *******************************************************************
* Creates a tabultated layout
*   Props:
*       - items:
*           Ex format: [{label: 'Tab 1', key: '1', children: <MyJsxComponent />, ...]
*
* See: https://ant.design/components/tabs/#API
********************************************************************* */


import React from "react";
import {Tabs} from "antd";


/*  Default Styles  -------------------------------------------------------------------*/
// const marginDef = 10;
const tabBarStyleDef = {color: 'black'};
const apiOptionsDef = {centered: false, size: 'large', tabPosition: 'top', type: 'line', tabBarStyle: tabBarStyleDef};

/*  Default Values for debugging  -----------------------------------------------------*/
const itemsDef = [
    {
        label: `Tab 1`,
        key: '1',
        children: `Content of Tab Pane 1`,
    },
    {
        label: `Tab 2`,
        key: '2',
        children: `Content of Tab Pane 2`,
    },
];


export default function TabsOdc(props) {

    const items = props.items ? props.items : itemsDef;
    const apiOptions = props.apiOptions? {...apiOptionsDef, ...props.apiOptions}: apiOptionsDef;

    const onChange = (key) => {
        // console.log(key);
    };

    return (
        <Tabs
            defaultActiveKey="1"
            onChange={onChange}
            items={items}
            {...apiOptions}
        />
    )
};
