/* *****************************************************************
* INSEE Documentation page component
****************************************************************** */

import React from 'react';
import {Layout} from "antd";
import {useTranslation} from 'react-i18next';

/*  ODC components: */
import DocumentationOdc from "../../components/documentation/DocumentationOdc";
import docEn from "./documentation/en.md";
import docFr from "./documentation/fr.md";
import FooterOdc from "../../components/layout/FooterOdc";

const {Content, Footer} = Layout;

const styleLayout = {backgroundColor: 'transparent', minHeight: "calc(100vh - 64px)"};

function DocInsee() {
    /*  translation:    */
    const {i18n} = useTranslation();
    const lang = i18n.language;

    const mdUrl = (lang === 'fr') ? docFr : docEn;

    return (
        <Layout style={styleLayout}>
            <Content>
                <DocumentationOdc mdUrl={mdUrl}/>
            </Content>
            <Footer style={{width: '100%', margin: 2, padding: 2}}>
                <FooterOdc />
            </Footer>
        </Layout>
    )
};

export default React.memo(DocInsee);