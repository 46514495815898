/* *****************************************************************
*   OpenDC symbologies for maps. Uses the same concepts as QGIS
*
* Documentation:
*   - color gradient: https://github.com/Adrinlol/javascript-color-gradient (npm install javascript-color-gradient)
*
****************************************************************** */
/*  import libs: */
import {Row, Col} from "antd";
import Gradient from "javascript-color-gradient";

/*  import ODC components: */
import {formatNumber, isFloat} from "../../jsOdcLib/GenericJsOdc";


export function setGradientOdc(props) {
    /*
    Returns an array of colors in a gradient way
    check https://github.com/Adrinlol/javascript-color-gradient fro code update and doc
    /* ----------------- Props ------------------ */
    const defNbClasses = 6;
    const defRamp = ["#f7fbff", "#08306b"]; //["0000aa", "009900", "ffff00", "cc0000"]; //

    const nbClasses = props ? props.nbClasses ? props.nbClasses : defNbClasses : defNbClasses;
    const colorRamp = props ? props.colorRamp ? props.colorRamp : defRamp : defRamp;
    /* ------------------------------------------- */

    const secondGradient = new Gradient();

    secondGradient.setColorGradient(...colorRamp);

    secondGradient.setMidpoint(nbClasses);

    const secondColorArr = secondGradient.getColors();

    return secondColorArr;
}

/* ***********************************************************************
*       JSX legend objects
* *********************************************************************** */
export const LegendOdc = (props) => {
    /* ******************************************
    *   Creates a legend component
    *   SIMPLE FOR NUMERICAL VALUES FOR NOW ==> IMPROVE!!!
    * Props:
    *   - listValues: ordered list of edge values
    *   - listColor:    list of color
    *   - direction: 'vertical' or 'horizontal'. Default: 'vertical'
    *   - suffix: suffix to add to values
    *   - title: Title on the top of the legend
    * ****************************************** */
    /*  Default styles:  */
    const defStyle = {
        'horizontal': {
            colSpan: 2,
            div: {textAlign: 'middle', overflow: 'auto'},
            row: {justify: 'center'},
            icon: {height: 20, marginRight: 1},
            divTitle: {textAlign: 'middle', whiteSpace: 'wrap', color: 'grey', fontSize: 12, paddingRight: 5},
            label: {
                transform: 'rotate(0deg)',
                position: 'relative',
                textAlign: 'left',
                top: 0,
                paddingRight: 5,
                whiteSpace: 'nowrap',
                fontSize: 12,
            }
        },
        'vertical': {
            colSpan: 12,
            div: {textAlign: 'left', overflow: 'auto'},
            row: {justify: 'center'},
            icon: {height: 20, marginBottom: 1},
            divTitle: {textAlign: 'middle', whiteSpace: 'wrap', color: 'darkgrey', fontSize: 14, paddingBottom: 10},
            label: {
                transform: 'rotate(0deg)',
                position: 'relative',
                textAlign: 'top',
                top: 0,
                paddingLeft: 5,
                whiteSpace: 'nowrap',
                fontSize: 12,
            }
        },
    };

    /*  Props:  */
    const listValues = props.listValues ? props.listValues : [0, 10, 100, 1000];
    let listLabels = props.listLabels ? props.listLabels : null; /* formatted values for legend */
    const listColor = props.listColor ? props.listColor : setGradientOdc({nbClasses: listValues.length});
    const direction = props.direction ? props.direction : 'vertical';
    const suffix = props.suffix ? props.suffix : '';
    const title = props.title ? props.title : null;
    const gutter = props.gutter ? props.gutter : [5, 5];
    const colSpan = props.colSpan ? props.colSpan : defStyle[direction].colSpan; // Math.round(24/listValues.length)
    const divTitle = props.divTitle ? props.divTitle : defStyle[direction].divTitle;
    const divStyle = props.divStyle ? props.divStyle : defStyle[direction].div;
    const rowStyle = props.rowStyle ? props.rowStyle : defStyle[direction].row;
    const iconStyle = props.iconStyle ? props.iconStyle : defStyle[direction].icon; /* width is defined by 'span' */
    const labelStyle = props.labelStyle ? props.labelStyle : defStyle[direction].label;

    /* Update labels for numerical values:  */
    if (listLabels === null) {
        /*  build the labels from the values:   */
        listLabels = [];
        let label;
        // for (let i=0; i < (listValues.length-1); i++) {
        //     label = `${formatNumber(listValues[i])} - ${formatNumber(listValues[i+1])}`;
        //     listLabels.push(label);
        // }
        // /*  last label: */
        // label = `> ${formatNumber(listValues[listValues.length-1])}`;
        // listLabels.push(label);
        for (let i = 0; i < (listValues.length); i++) {
            label = `${formatNumber(listValues[i], 2, '.')}${suffix}`;
            label = (i === (listValues.length-1))? '> ' + label: label;
            listLabels.push(label);
        }
    }

    /*  Test with table object:   */


    /*  Build legend:   */
    const legend = (direction === 'vertical') ?
        /*  Vertical legend:    */
        <div style={divStyle}>
            {listColor.map((col, ix) => {
                let styleTmp = {...iconStyle, ...{backgroundColor: col}};
                let val = listLabels[ix];
                return (
                    <Row key={ix}>
                        <Col key={`col_${ix}`} span={colSpan} style={styleTmp}></Col>
                        <Col key={`val_${ix}`} span={colSpan} style={labelStyle}>{val}</Col>
                    </Row>
                )
            })}


        </div> :
        /*  Horizontal legend:    */
        <div style={divStyle}>
            <Row gutter={gutter} {...rowStyle}>
                {listColor.map((col, ix) => {
                    let styleTmp = {...iconStyle, ...{backgroundColor: col}};
                    return <Col key={`col_${ix}`} span={colSpan} style={styleTmp}></Col>
                })}
            </Row>
            <Row gutter={gutter} {...rowStyle}>
                {listLabels.map((val, ix) => {
                    return <Col key={`val_${ix}`} span={colSpan} style={labelStyle}>{val}</Col>
                })}
            </Row>
        </div>;
    return (
        <>
            {title && <div style={divTitle}>{title}</div>}
            {legend}
        </>
    );
};