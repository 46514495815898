/* *****************************************************************
* TableOdc: Ant design table:
* See doc here:
*       https://ant.design/components/table/
*       list of props: https://www.geeksforgeeks.org/reactjs-ui-ant-design-table-component/
*   Search filters full explanation: https://www.youtube.com/watch?v=uatpXRlR4zo
********************************************************************* */

import React, {useRef, useState, useMemo, useEffect} from 'react';
import {ErrorBoundary} from "react-error-boundary";
import {DataFrame, toCSV, toExcel} from "danfojs";
import {Button, Input, Space, Table, Row} from 'antd';
import {DownloadOutlined} from '@ant-design/icons';
import {Resizable} from 'react-resizable';
import {useTranslation} from 'react-i18next';
import './css/resizable.css';

/* ODC components:  */
// import ErrorBoundaryOdc from "../ErrorBoundaryOdc";
import {ErrorFallbackOdc, logErrorOdc} from "../ErrorBoundaryOdc";
import odcStyles from "./css/table.module.css";

/* App config import:   */
import {DataFields} from "../../data/AppInfo";


/* **************************** TO DO *******************************
* Check if unique values should be habdled better for efficiency reasons...
* specify search vs filter for each column
* handle filter
********************************************************************* */

/* ********************************************************************
                    CONFIG OPTIONS (pass as props TO DO):
********************************************************************* */
const f_dataIndex = DataFields.allTables.dataIndex;
// const f_loc_id = DataFields.locTable.id;

const tableOptions = {
    size: 'middle', // 'middle' or 'small'
    scroll: {y: 350, x: 'max-content'},
    // width: '10%',
    // style: {margin: 5}, // display: 'flex', flex: 1,
};

const headerOptions = {
    minWidth: 100,
    maxWidth: 600,
};

const downloadOptionsDef = {download: true, columns: null, filename: 'output', onClick: null};

/*  Default data for debugging  */
const dataSourceDef = [
    {
        key: '1',
        name: 'Mike',
        age: 32,
        address: '10 Downing Street',
    },
    {
        key: '2',
        name: 'John',
        age: 42,
        address: '10 Downing Street',
    },
];

const columnsDef = [
    {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
    },
    {
        title: 'Age',
        dataIndex: 'age',
        key: 'age',
    },
    {
        title: 'Address',
        dataIndex: 'address',
        key: 'address',
    },
];

/* ********************************************************************
                    LAYOUT FUNCTIONS:
********************************************************************* */

/* Add attributes to the columns to be able to filter (unique values etc...)    */
function completeColumnsAttr(columns, data, render_func, headerMapping, sorter) {
    let colComplete = null;
    if (columns) {
        colComplete = columns.map((col, idx) => {
            /* --------------------------- columns filters ----------------------------------------------------------*/
            // Get the list of unique values for each column to get the filtered tick boxes:
            let uniqVal = [...new Set(data ? data.map((rec) => rec[col[f_dataIndex]]) : [])];
            let filters = uniqVal.map(val => {
                return {text: val, value: val}
            });
            filters.sort();
            let sorterFunc = sorter && ((a, b) => a[col[f_dataIndex]].toString().length - b[col[f_dataIndex]].toString().length);
            let onFilter = (value, record) => record[col[f_dataIndex]].toString().includes(value);

            /* --------------------------- Render functions ---------------------------------------------------------*/
            let colKey = col.dataIndex;
            let colName = col.title;
            let render = render_func ? render_func[colKey] ? render_func[colKey] : (val) => val : (val) => val;

            /* --------------------------- Set header title with mapping dictionnary -------------------------------*/
            if (headerMapping) {
                let colName2 = headerMapping[col.dataIndex];
                if (colName2) {
                    colName = colName2;
                    col.title = colName2;
                }
            }

            /* --------------------------- Set header width ---------------------------------------------------------*/
            let tmpWidth = 1.6 * (colName.length * 8 + 5);  // 1 character = 8px + 5px margin
            // console.log(`col "${colName}", width: ${tmpWidth}`);
            let headerWidth = (tmpWidth < headerOptions.minWidth) ?
                headerOptions.minWidth : (tmpWidth > headerOptions.maxWidth) ? headerOptions.maxWidth : tmpWidth;
            // if last column: do not set header width to allow fluid fitting:
            if (idx == (columns.length - 1)) headerWidth = null;

            /* --------------------------- Add to column ------------------------------------------------------------*/
            // let sortOrder = sortedInfo.columnKey === col[f_dataIndex] ? sortedInfo.order : null
            const columns2 = {
                ...col,
                render: render,
                width: headerWidth,
                filters: filters,
                sorter: sorterFunc,
                onFilter: onFilter,
                ellipsis: true,
            };
            // console.log(colName, ': ', columns2);
            return columns2;
        });
    }
    return colComplete;
}

/* Configure resizable headers: */
const ResizableTitle = (props) => {
    const {onResize, width, ...restProps} = props;

    if (!width) {
        return <th {...restProps} />;
    }

    return (
        <Resizable
            width={width}
            height={0}
            handle={
                <span
                    className="react-resizable-handle"
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                />
            }
            onResize={onResize}
            draggableOpts={{
                enableUserSelectHack: false,
            }}
        >
            <th {...restProps} />
        </Resizable>
    );
};

/* ********************************************************************
                    TableOdc COMPONENT:
********************************************************************* */
const TableOdc = (props) => {
    /* ******************************************
    *   ODC Table Component
    * Props:
    *   - columns:
    *   - data: MUST HAVE A 'key' FIELD!!!!
    *   - render_func: object {'col_name': (val) => (render function)}: used to render some fields in a specific way. Uses column dataIndex as a key.
    *   - headerMapping: dictionnary for mapping header labels: eg for translation
    *   - onClick:
    *   - ...
    *
    * ****************************************** */
    const data = props.data ? props.data : undefined; //dataSourceDef;
    const col = props.columns ? props.columns : undefined; //columnsDef;
    const render_func = props.render_func ? props.render_func : null;
    const headerMapping = props.headerMapping ? props.headerMapping : null;
    const downloadOptions = props.downloadOptions? props.downloadOptions: downloadOptionsDef;
    const sorter = props.sorter? props.sorter: false;   /* to fix as default ant design doesn't seem to work properly... */
    const maxHeight = props.maxHeight? props.maxHeight: null;

    /*  Set the max table height:   */
    if (maxHeight) tableOptions.scroll.y = maxHeight;

    const download = downloadOptions.download;    /*  Download button */

    const [columns, setColumns] = useState(null);

    const {t} = useTranslation();

    /* Filtered items:  */
    const [filteredInfo, setFilteredInfo] = useState({});
    const [sortedInfo, setSortedInfo] = useState({});

    /*  Update columns attributes: mapping, width etc.:  */
    useEffect(() => {
        const colTmp = completeColumnsAttr(col, data, render_func, headerMapping, sorter);
        // console.log('columns2: ', JSON.stringify(colTmp));
        setColumns(colTmp);
    }, [col, data, headerMapping, render_func]);

    // Action after clicking on row: returns the full record as object, not only the index
    const onRowClick = (record, rowIndex) => {
        // console.log(`clicked on row ${rowIndex}, record: ${record}`);
        return {
            onClick: (e) => {
                // const selectedIds = [record[f_loc_id]];
                const selectedIds = [record];
                props.onClick && props.onClick(selectedIds);
            }
        }
    };

    // Action after clicking on header: clear filters:
    const onHeaderRowClick = (columns, index) => {
        return {
            onClick: (e) => {
                console.log('clicked on header, filters removed...');
                clearFilters();
                // props.onClick && props.onClick(undefined);
            }
        }
    };

    // Action after clicking on cell: TO FIX
    const onCellClick = (record, rowIndex) => {
        /* TO IMPLEMENT */
        console.log('Table cell clicked');

    }

    // Filters actions (Ant Design functions):
    const handleChange = (pagination, filters, sorter, record) => {
        // console.log('handling filters record: ', JSON.stringify(record), '\nfilters:', JSON.stringify(filters), '\nsorter: ', JSON.stringify(sorter));
        setFilteredInfo(filters);
        setSortedInfo(sorter);
        // Update IDs for others charts:
        // const selectedIds = record.currentDataSource.map(rec => rec[f_loc_id]);
        const selectedIds = [...record.currentDataSource];
        // console.log('selected IDs in table: ', JSON.stringify(selectedIds));
        props.onClick && props.onClick(selectedIds);
    };

    // Filters setting and clearing:
    const clearFilters = () => {
        setFilteredInfo({});
        props.onClick && props.onClick(undefined);
    };

    const clearAll = () => {
        setFilteredInfo({});
        setSortedInfo({});
        props.onClick && props.onClick(undefined);
    };

    const setColSort = (colKey) => {
        setSortedInfo({
            order: 'descend',
            columnKey: colKey,
        });
    };

    // Function that handles the headers resize:
    const handleResize = (index) => (_, {size}) => {
        const newColumns = [...columns];
        newColumns[index] = {...newColumns[index], width: size.width};
        setColumns(newColumns);
    };

    const resizedColumns = columns && columns.map((col, index) => ({
        ...col,
        onHeaderCell: (column) => ({
            width: column.width,
            onResize: handleResize(index),
        }),
    }));

    const onDownloadClick = async () => {
        if (downloadOptions) {
            if (downloadOptions.download !== true) {
                return
            }
            let filename = downloadOptions.filename ?? 'data.csv';
            /*  first convert to danfo js:  */
            let tmpDf = await new DataFrame(data);
            /*  select relevant columns for export: */
            let colExport = downloadOptions.columns? downloadOptions.columns: tmpDf.columns;
            tmpDf = await tmpDf.loc({columns: colExport});

            /*  Export to Excel format for encoding reasons:    */
            // toCSV(tmpDf, {download: true, fileName: filename});
            toExcel(tmpDf, {download: true, fileName: filename});

            /*  call parent props function: */
            download.onClick && download.onClick(data);
        }
    };

    const renderFooter = () => {
        const downloadButton = download && <Button
            onClick={onDownloadClick}
            icon={<DownloadOutlined/>}
            title={t('Download source data')}
        >
            {t('Download source data')}
        </Button>;
        return downloadButton;
    };

    // output table:
    // console.log("table data: ", JSON.stringify(data));
    let table = (data && columns) ? (
            <Table
                components={{
                    header: {
                        cell: ResizableTitle,
                    },
                }}
                columns={resizedColumns}
                dataSource={data}
                size={tableOptions.size}
                style={tableOptions.style}
                scroll={tableOptions.scroll}
                // width={tableOptions.width}
                onRow={onRowClick}
                onChange={handleChange}
                onHeaderRow={onHeaderRowClick}
                rowKey={'key'}  // columns must have a 'key' field!!!!
                footer={renderFooter}
                //onCell={onCellClick}
            />) :
        <Table loading={true}/>;

    return (
        <ErrorBoundary onError={logErrorOdc} FallbackComponent={(props) => {
            return <ErrorFallbackOdc {...props} message="Error loading table"/>
        }}>
                {table}
        </ErrorBoundary>
    );
    // return (
    //     table
    // );
};

// export default React.memo(TableOdc);
export default TableOdc;