/* *****************************************************************
* Translation file for INSEE Bilan carbone app
****************************************************************** */
export const translFR = {
    translation: {
        /*  Header:    */
        'Home': 'Accueil',
        'Login': 'Connexion',
        /*  KPI:    */
        'Direction': 'Type de flux',
        /*  Map component:  */
        'Carte interactive': 'Carte interactive',
        'Émissions': 'Résidents',
        'Attractions': 'Employés',
        'Origines': 'Résidents',
        'Destinations': 'Employés',
        /*  Data fields:    */
        'insee_from': 'codgeo origine',
        'insee_to': 'codgeo destination',
        'Origin': 'Origine',
        'Destination': 'Destination',
        'Bilan Carbone / depl': 'Bilan carbone par déplacement',
        'Bilan Carbone': 'Bilan carbone',
        'flow_tot': 'Flux Domicile travail et études',
        'mobpro_tot': 'Flux Domicile - Travail',
        'mobpro_selected_modes': 'Flux Domicile - Travail',
        'mobsco': 'Flux Domicile - Études',
        'DURATION_driving': 'Temps de parcours VP',
        'DURATION_transit': 'Temps de parcours TC',
        'DISTANCE_driving': 'Distance VP (km)',
        'DISTANCE_transit': 'Distance TC (km)',
        'MAIN_MODE_transit': 'Mode TC retenu',
        /*  Modes table:    */
        'VP': '# trajets VP',
        'TC': '# trajets TC',
        '2RM': '# trajets 2 roues',
        'Vélo': '# trajets vélo',
        'Marche': '# trajets marche',
        'Pas de transport': '# sans transport',
        /*  Modes labels, titres etc.   */
        '_VP': 'VP',
        '_TC': 'TC',
        '_2RM': '2RM',
        '_Vélo': 'Vélo',
        '_Marche': 'Marche',
        '_Pas de transport': 'na',
        '_Tous modes': 'Tous modes',
        /*  Charts fields:  */
        'max_bc': 'Déciles de bilan carbone par déplacement en Kg CO2',
        /*  ODC components standard values: */
        'Download source data': 'Télécharger les données',
    }
};
