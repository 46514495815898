import React, {useState, useEffect, Suspense, useMemo, useCallback, useRef} from "react";
import {Col, Layout, Row} from "antd";
import {toJSON} from "danfojs";
import {useTranslation} from 'react-i18next';


/* ODC components: */
// import SiderMenuOdc from "../components/layout/SiderOdc";
import CardOdc from "../components/layout/CardOdc";
import TableOdc from "../components/tables/TableOdc";
import {LayoutUIOdc} from "../components/layout/LayoutOdc";
import {startTime, endTime} from "../jsOdcLib/GenericJsOdc";
import GoogleMapsOdc from "../components/maps/GoogleMapsOdc";
import {
    geoJsonStyles,
    initGeoJsonStyle,
    updateDfStyle,
    updateGeoJsonStyle,
} from "../components/maps/GoogleMapsStyles";
// import {setGradientOdc, LegendOdc} from "../components/maps/SymbologyOdc";

/* Page component */
import {InseeCls} from "./insee/CalcInsee";
import {dictSiderOptionsCity, dictSiderOptionsOD, SiderMapCarbon} from "./insee/MapsInsee";
import {ChartModeShare, ChartCarbon, ChartTravelTime} from "./insee/ChartsInsee";

/* Data url: */
import {IDF_GeoJSON} from "../data/api_data/insee/IDF_GeoJSON";
import KpiInsee from "./insee/KpiInsee";
import TabsOdc from "../components/layout/TabsOdc";
// import {AppInfo} from "../data/AppInfo";
import TitleOdc from "../components/layout/TitleOdc";
import FooterOdc from "../components/layout/FooterOdc";

// Body layout: to make work with body container...
// const tmpColor = "rgba(190,210,223,0.17)";  /* just for selection */
const {Content, Footer} = Layout;
const layoutStyle = {minHeight: "calc(100vh - 64px)", maxWidth: 2400};   // {minHeight: '100vh', maxWidth: 2400}; // "calc(100vh - 50px)"
const contentStyle = {padding: 10, margin: 0};
const gutter = [8, 16]; // horizontal and vertical space between grid elements
const rowHeight = 600;  /* For maps and charts*/
const mapHeight = 400;  /* For maps and charts*/
const tableHeight = 350;  /* For table*/
const cardStyle = {height: rowHeight};
const cardStyleTable = {}; // {height: rowHeight};
const cardProps = {headStyle: {backgroundColor: "rgba(190,210,223,0.17)", paddingLeft: 5, height: 5},};
const mapContainerStyle = {width: '100%', height: mapHeight}; //null; //{width: '80%', height: '70%'}; //{width: 1.2*rowHeight, height: rowHeight};     /* for bounds: IDF bounds */

/*  Default map options:    */
const mapOptionsDef = {
    center: /* based on IDF center */
        {
            lat: 48.83,
            lng: 2.33
        }
    ,
    zoom: 9,
    disableDefaultUI: true, /* remove to show zoom button and street view */
    fullscreenControl: true,
};
const mapTitleStyle = {color: 'black', fontWeight: 500, fontSize: 20, paddingLeft: 24,};     /* for map card */
const mapDivStyle = {minWidth: 400, maxWidth: 2400};     /* for map card */
const cardChildStyle = {minWidth: 300, maxWidth: 1200};    /* for charts cards */
const kpiBannerStyle = {height: 100, whiteSpace: 'nowrap'};     /* for KPIs card */

/* **************************** TO DO *******************************
*   - cf TO_DO.md
   ****************************************************************** */

/* Lazy imports:  MAKE IT WORK WITH SUSPENSE WITHOUT CREATING BUG WHEN COMING BACK TO MAIN PAGE... */
// const TableOdc = React.lazy(() => import("../components/tables/TableOdc"));
// const VectorPolygonMapOdc = React.lazy(() => import("../components/maps/VectorPolygonMapOdc"));

const f_from = 'insee_from';
const f_to = 'insee_to';
const f_name_from = 'Origin';
const f_name_to = 'Destination';
const f_com_id = 'COM_ID';      // total origin + destination
const f_id = 'COM_ID';  // COM ID in the shp file
const f_carbon = 'Bilan Carbone';
const f_carbon_norm = 'Bilan Carbone / depl';
const f_flow_mobpro = 'mobpro_tot';
const f_flow_mobsco = 'mobsco';
const f_flow_tot = 'flow_tot';
const f_flow_mobpro_mode = 'mobpro_selected_modes';   /*  column when subset of modes is selected: needs to bet set in MapsInsee.js  */
const f_map_def = f_carbon;    /*  default value for mapping   */
// const f_sort = f_map_def;
// const f_tt_car = 'DURATION_driving';
// const f_tt_pt = 'DURATION_transit';
const f_dist_car = 'DISTANCE_driving';
const f_dist_pt = 'DISTANCE_transit';
const f_main_transit_mode = 'MAIN_MODE_transit';
const ascSort = false;  // ascending sort

const headerOptions = ['Origines', 'Destinations'];
const aggOptions = ['Moyenne ville', 'Détail Flux'];    /*  si la carte est full OD ou aggrégation commune */
const modesList = ['VP', 'TC', '2RM', 'Vélo', 'Marche'];

/* Render function for INSEE fields: */
const rnd_fct = (val) => {
    // return Math.round(val)
    return Math.round(val).toLocaleString('fr-FR')
};

const render_func = {
    'Bilan Carbone': rnd_fct,
    // 'Bilan Carbone / depl': rnd_fct,
    'DURATION_driving': rnd_fct,
    'DURATION_transit': rnd_fct,
    'Pas de transport': rnd_fct,
    'Marche': rnd_fct,
    'Vélo': rnd_fct,
    '2RM': rnd_fct,
    'VP': rnd_fct,
    'TC': rnd_fct,
    'mobpro_tot': rnd_fct,
    'mobsco': rnd_fct,
    'flow_tot': rnd_fct
};

/* indexes in header options vs field names: */
const dict_f = {0: f_from, 1: f_to};

/* dict of keys accrding to whether from or to is selected: */
const ft_key = {};
ft_key[f_from] = f_to;
ft_key[f_to] = f_from;

/* dict for titles */
const dictTitleFromTo = {};     /* returns the title indicating whether origin, destination or total: UPDATE IN KPI INSEE AS WELL!!! */
dictTitleFromTo[f_from] = 'Origines';
dictTitleFromTo[f_to] = 'Destinations';
dictTitleFromTo[f_com_id] = 'Moyenne';

/* dict for text */
const dictTextFromTo = {};     /* returns the title indicating whether origin, destination or total */
dictTextFromTo[f_from] = 'depuis';
dictTextFromTo[f_to] = 'vers';
dictTextFromTo[f_com_id] = 'depuis et vers';

/* dict for table aggregation type: */
// const dictAggType = {0: 'aggCity', 1: 'full OD'};

/*  Options defined in MapInsee / SiderMapCarbon: must follow the same order!!! */
// const siderOptions = [f_carbon, f_carbon_norm, f_tt_car, f_tt_pt, f_flow_mobpro, f_flow_mobsco, f_flow_tot];  // ['flow_tot', 'mobpro_tot', 'mobsco'];
/* Scale and Color ramp values defined in dictSiderOptions from './insee/MapsInsee' */

/* Default selected city: */
const cityDef = 75101;

/*  Table download options: */
const colDownload = [f_from, f_to, f_name_from, f_name_to, f_carbon, f_carbon_norm, f_flow_mobpro, f_flow_mobsco,
    f_flow_tot, 'VP', 'TC', '2RM', 'Vélo', 'Marche', f_dist_car, f_dist_pt, f_main_transit_mode];

const downloadOptions = {download: true, columns: colDownload, filename: 'output', onClick: null};

/* sider items for tests only   */

// const siderMenuItems = AppInfo.sider.items;

/* ------------------------------------------------------------------------------------------------
* ------------------------------------------------------------------------------------------------
*                   *****************  COMPONENT ******************
* ------------------------------------------------------------------------------------------------
* ------------------------------------------------------------------------------------------------ */


function InseePage(props) {
    /* ********* INSEE data as ODC dataframe ******* :
    *      - dfInsee.df: data as df
    *      - dfInsee.json: data as json for tables
    *      - dfInsee.columns: columns
    * ********************************************* */
    const clsInsee = props.clsInsee ? props.clsInsee : null;
    const fullLoad = props.fullLoad ? props.fullLoad : false;

    /*  translation:    */
    const {t, i18n} = useTranslation();

    const clsInseeRef = useRef(null);               /* INSEE class with full and selected data  */
    // const [dfInsee, setDfInsee] = useState(null);           // INSEE data: full OD or aggregate emissions, attractions or average...
    const dfInseeRef = useRef(null);                /* INSEE data: full OD or aggregate emissions, attractions or average...    */
    const dfPlotRef = useRef(null);                // Selected INSEE data for plotting
    const aggTypeRef = useRef(0);                        // Aggregation type: avg city (0) or full OD (1)
    const [, setAggType] = useState(0);          /*  same with useState for rerender */
    const [tblCol, setTblCol] = useState(null);           // INSEE columns for table
    const [colMapping, setColMapping] = useState(null);       // Columns mapping dictionary (translation)
    const [mapHeadersOptions, setMapHeadersOptions] = useState(headerOptions);       // Map header options (translation)
    const [mapTypeOptions, setMapTypeOptions] = useState(aggOptions);       // Map header options (translation)
    const [tblData, setTblData] = useState(null);         // selected INSEE data as json for tables
    const [geoJson, setGeoJson] = useState(null);             // geographic data
    const [fromTo, setFromTo] = useState(null);               // whether flow from or to: does not update with useState so useRef instead... see https://dmitripavlutin.com/react-hooks-stale-closures/
    const fromToRef = useRef(null);
    const colMapRef = useRef(null);                      /* the value to be mapped */
    const [, setColMap] = useState(null);               /* same for rerender */
    const selectedModeRef = useRef(null);               /*  the list of selected modes  */
    const [selectedMode, setSelectedMode] = useState(0);          /*  same with useState for rerender */
    const [citySelect, setCitySelect] = useState(cityDef);            // Selected city
    const cityRef = useRef(cityDef);
    const [citySummary, setCitySummary] = useState(null);   // Data aggregated for the selected city
    const citySummaryRef = useRef(null);   // Data aggregated for the selected city
    const kpiContentRef = useRef(null);                     /*  KPI values    */
    const [kpiContent, setKpiContent] = useState(null);     /*  same with useState for rerender */
    const mapOptionsRef = useRef(null);                     // Default map options
    const mapLegendRef = useRef(null);                     // Map legend when city is clicked
    const scaleRef = useRef(null);                         // scale of data to be plotted
    const colorRampRef = useRef(null);                         // Color ramp of data to be plotted
    const [firstLoaded, setFirstLoaded] = useState(false);  /*  Set to true once minimal data is loaded */
    const [allLoaded, setAllLoaded] = useState(false);  /*  Set to true once all the data has been fully loaded */
    const mapKeyRef = useRef(null);             /* unique map key refreshed any time the map needs reloading    */

    /* Load City-level INSEE data from csv file (quick load): */
    useEffect(() => {
        if (clsInsee) {
            clsInseeRef.current = clsInsee;
            let dfCitiesTmp = clsInseeRef.current.citiesSummary[f_from];
            // console.log('dfInsee CITY = ');
            // dfCitiesTmp.head(3).print();

            /*  Full data (OD or avg city)  */
            dfInseeRef.current = dfCitiesTmp;

            /*  Selected data for plotting  */
            dfPlotRef.current = dfCitiesTmp;

            /*  Set partial data load to true: */
            setFirstLoaded(true);
        }
    }, [clsInsee]);

    /* Load full INSEE data from csv file: */
    useEffect(() => {
        /* Initialise the INSEE Class:   */
        // console.log('cls insee 2: ', clsInsee);
        // (async () => {
        if (clsInsee) {
            if (clsInsee.odfFullOD) {
                clsInseeRef.current = clsInsee;
                let dfODTmp = clsInseeRef.current.odfFullOD;

                /* Load only records from default city to display in the table: */
                // let dfTbl = await dfODTmp.df.query(dfODTmp.df[f_from].eq(cityDef));
                let dfTbl = dfODTmp.df.query(dfODTmp.df[f_from].eq(cityDef));

                setTblData(toJSON(dfTbl));
                setTblCol(dfODTmp.columns);

                /*  Set full load data to true: */
                setAllLoaded(true);
            }
        }
        // })();

    }, [fullLoad, clsInsee]);

    /*  Translate columns according to current language:    */
    useEffect(() => {
        let dictTmp = {};
        tblCol && tblCol.map(col => {
            let colKey = col.dataIndex;
            let colTransl = t(colKey);
            dictTmp[colKey] = colTransl ? t(colKey) : t(col.title);
        });
        setColMapping(dictTmp);
    }, [tblCol, i18n.language]);

    /*  Translate header and sider options according to current language*/
    useEffect(() => {
        let headOpt = headerOptions.map(opt => t(opt));
        // let sideOpt = siderOptions.map(opt => t(opt));
        setMapHeadersOptions(headOpt);
        // setMapSiderOptions(sideOpt);

        headOpt = aggOptions.map(opt => t(opt));
        setMapTypeOptions(headOpt);

    }, [i18n.language]);

    /* GeoJson data: */
    useEffect(() => {
        setGeoJson(initGeoJsonStyle({geoJson: IDF_GeoJSON}));
    }, [IDF_GeoJSON]);

    /* Reflect state change in other functions: */
    // useEffect(() => {
    //     // console.log("flow from or to in useEfeect? ", fromTo);
    // }, [fromToRef.current, aggTypeRef.current, colMapRef.current, selectedModeRef.current, cityRef.current, mapKeyRef.current]); //fromTo

    /*  Default map options:    */
    useEffect(() => {
        fromToRef.current = f_from;
        let f_key = colMapRef.current ? colMapRef.current : f_carbon;
        colMapRef.current = f_key;
        mapOptionsRef.current = {...mapOptionsDef};

        let dictSiderOptions = (aggTypeRef.current === 0) ? dictSiderOptionsCity : dictSiderOptionsOD;

        mapLegendRef.current = dictSiderOptions[f_key].legend;
        scaleRef.current = dictSiderOptions[f_key].scale;
        colorRampRef.current = dictSiderOptions[f_key].colorRamp;
    }, [aggTypeRef.current]);

    /*  Refresh map the first time:    */
    useEffect(() => {
        (async () => {
            if (firstLoaded) await updateMap({});
        })();
    }, [firstLoaded]);

    /* Refresh key to force the map to re-render: NECESSARY */
    mapKeyRef.current = new Date();

    /*  Keeps the current map bounds on refresh:    */
    const handleBoundsChange = useCallback((bounds, center, zoom) => {
        /*  Setting new user defined zoom and center:   */
        const userCenter = center && center;
        const userZoom = zoom && zoom;
        // console.log('user bounds: ', userCenter, userZoom);
        const newMapOptions = {center: userCenter, zoom: userZoom};
        mapOptionsRef.current = userCenter ? {...newMapOptions} : {...mapOptionsDef};
    }, []);

    /*  Computes the sum of mob pro sub modes:  */
    const computeSumSelectedModes = useCallback(async (df, modesList) => {
        let tmpTotModes = await df.loc({columns: modesList}).sum();
        if (df.columns.includes(f_flow_mobpro_mode)) {
            await df.drop({columns: [f_flow_mobpro_mode], inplace: true});
        }
        let df2 = await df.addColumn(f_flow_mobpro_mode, tmpTotModes, {inplace: false});
        return df2;
    }, []);


    const updateDfPlot = async (props) => {
        /*  Updates the df to be used according to agg type and orig or dest selected   */
        const aggType = props.aggType ? props.aggType : aggTypeRef.current; /* city avg by default */
        const origDest = props.origDest ? props.origDest : fromToRef.current; /* city avg by default */
        const cityId = props.cityId ? props.cityId : cityRef.current; /* city avg by default */
        // console.log('Getting data for aggType = ', aggType, origDest, '=', cityId);
        let tmpDf;
        if (aggType === 0) {
            /*  Select if city emission, attraction or avg emission + attraction:   */
            tmpDf = await clsInseeRef.current.citiesSummary[origDest];

            /*  Data for plotting:  */
            dfPlotRef.current = tmpDf;
        } else {
            /*  full OD data:   */
            tmpDf = await clsInseeRef.current.odfFullOD.df;

            /*  Data for plotting:  */
            dfPlotRef.current = await tmpDf.query(tmpDf[origDest].eq(cityId));
        }
        /*  Data with full OD:  */
        dfInseeRef.current = tmpDf;
        // tmpDf.head(2).print();
    };

    const updateKpi = useCallback(() => {
        let tmpKpiContent = <KpiInsee citySummary={citySummaryRef.current} fromTo={fromToRef.current}/>;
        kpiContentRef.current = tmpKpiContent;
        setKpiContent(tmpKpiContent);
    }, [fromTo, citySummary]);


    /* UI actions functions: ---------------------------------------------------------------- */

    /* Defines whether the full OD df must be used or the aggregated at the city level:  */
    const selectAggType = async (val) => {
        if (val != aggTypeRef.current) {
            /*  Set the ref values: */
            aggTypeRef.current = val;
            setAggType(val);    /*  for rerender    */

            /*  Update the df source:   */
            await updateDfPlot({aggType: val});
            /*  Update the map: */
            await updateMap({});
        }
    };

    /* Defines whether compute flows from or to -------------------------------------------- : */
    const originOrDest = async (val) => {
        const ft = dict_f[val];
        /*  Update the df source:   */
        if (ft !== fromToRef.current) {
            /*  Set the ref values: */
            setFromTo(ft);
            fromToRef.current = ft;

            /*  Update the source df*/
            await updateDfPlot({origDest: ft});

            /*  Update table content:    */
            let dfODtmp = await clsInseeRef.current.odfFullOD.df.query((clsInseeRef.current.odfFullOD.df[fromToRef.current].eq(cityRef.current)));
            setTblData(toJSON(dfODtmp));

            /*  Update the map: */
            await updateMap({headerKey: val});
        }
    }; //, [citySelect]);

    /* Defines the field to be mapped -------------------------------------------- : */
    const selectMapField = async (val) => {
        /*  Set selected modes to null if not mob pro selected: */
        selectedModeRef.current = (val === f_flow_mobpro) ? modesList : null;
        setSelectedMode(selectedModeRef.current);

        /*  Update map: */
        await updateMap({siderKey: val, selectedMode: selectedModeRef.current});

    };//, [selectedModeRef.current, dfInseeRef.current, geoJson, fromTo]);

    /*  If mob Pro: Defines the transport mode: */
    const selectModeField = async (val) => {
        /*  Set the ref values: */
        selectedModeRef.current = val;
        setSelectedMode(val);
        colMapRef.current = f_flow_mobpro_mode;

        /*  Update map column if mobpro selected: keep only selected transport modes: ~100ms  */
        dfPlotRef.current = await computeSumSelectedModes(dfPlotRef.current, val);

        /*  Update the map: */
        await updateMap({siderKey: f_flow_mobpro_mode, selectedMode: val});
    };

    /* props function to update map on click: */
    const geoJsonOnClick = useCallback(async (feat) => {

        /*  Set selected city:  */
        const cityId = feat.getProperty(f_id);
        cityRef.current = cityId;
        setCitySelect(cityId);

        /*  Update df source if full OD is selected:    */
        if (clsInseeRef.current.odfFullOD) {
            /*  Full OD for table data, regardless of what is selected, avg or detailed flow:   */
            let dfODtmp = await clsInseeRef.current.odfFullOD.df.query((clsInseeRef.current.odfFullOD.df[fromToRef.current].eq(cityRef.current)));

            /*  Update table content:    */
            setTblData(toJSON(dfODtmp));

            /*  Update dfPlot if "detailed" OD option is selected:    */
            if (aggTypeRef.current === 1) {
                /*  Data for plotting:  */
                dfPlotRef.current = dfODtmp;
            }
        }

        /*  Refresh map:    */
        await updateMap({cityId: cityId});  // , selectedMode: selectedMode

    }, [clsInseeRef.current, dfInseeRef.current, geoJson, fromTo]);  //

    /* Map update function ==> called by map click or header / sider option selected: */
    const updateMap = async (props) => {
        /* ------------------------------------------
        *   Function called anytime a city is selected on the map or when a header or sider option is clicked.
        *   ==> refreshes the geoJson layer and style
        *   Props:
        *       - cityId: the clicked cityID
        *       - headerKey: the header option selected
        *       - siderKey: the sider option selected
        * ------------------------------------------ */
        const cityId = props.cityId ? props.cityId : cityRef.current;
        const headerKey = (props.headerKey !== undefined) ? props.headerKey : null;
        const siderKey = (props.siderKey !== undefined) ? props.siderKey : null;

        /*  Get emission or attraction info: */
        if (headerKey !== null) {
            let val = headerKey;
            const ft = dict_f[val];
            setFromTo(ft);
            fromToRef.current = ft;
        }

        /*  Get map column: */
        if ((siderKey !== null) | (mapLegendRef === null)) {
            let key = (siderKey !== null) ? siderKey : f_map_def;
            // const key = siderOptions[val];
            colMapRef.current = key; //siderOptions[val];
            setColMap(key); /*  for rerender    */

            /*  Set scale, colors and legend objects: */
            let keyLegend = (siderKey === f_flow_mobpro_mode) ? f_flow_mobpro : key;  /* when sub modes are selected*/
            let dictSiderOptions = (aggTypeRef.current === 0) ? dictSiderOptionsCity : dictSiderOptionsOD;

            mapLegendRef.current = dictSiderOptions[keyLegend].legend;
            scaleRef.current = dictSiderOptions[keyLegend].scale;
            colorRampRef.current = dictSiderOptions[keyLegend].colorRamp;
        }

        /*  Add sub mode sum columns if not in data:    */
        if ((colMapRef.current === f_flow_mobpro_mode) && (selectedModeRef.current)) {
            dfPlotRef.current = await computeSumSelectedModes(dfPlotRef.current, selectedModeRef.current);
        }

        /*  Update City summary:    */
        // (cityId !== citySelect) && setCitySelect(cityId);
        const tmpCitySummary = await clsInseeRef.current.getCitySummary(fromToRef.current, cityId);
        setCitySummary(tmpCitySummary);
        citySummaryRef.current = tmpCitySummary;

        /*  Update KPIs:    */
        await updateKpi();

        /*  update geoJson layer: MANAGE WHEN FIELD IS NOT OD BUT FULL CITY */
        /* Sort values: */
        let f_sort = colMapRef.current;
        // tmpODSelect.sortValues(f_sort, {ascending: ascSort, inplace: true});
        dfPlotRef.current.sortValues(f_sort, {ascending: ascSort, inplace: true});

        /* Get field to be mapped: */
        let colMap = colMapRef.current ? colMapRef.current : f_map_def;

        /* Update geoData fillColor according to flow vs maxFlow:  */
        let f_geoKeyDf = (aggTypeRef.current === 0) ? f_com_id : ft_key[fromToRef.current];
        let dfStyle = await updateDfStyle({
            // dfData: tmpODSelect,
            dfData: dfPlotRef.current,
            f_val: colMap,
            f_geoKeyDf: f_geoKeyDf,
            constantScale: scaleRef.current,
            colorRamp: colorRampRef.current,
            minVal: 0
        });

        /* Update geoJsonStyle: */
        await updateGeoJsonStyle({
            geoData: geoJson,
            dfData: dfStyle,
            f_keyGeo: f_id,
            f_geoKeyDf: f_geoKeyDf,
        });

        /* Update refresh key: NECESSARY */
        mapKeyRef.current = new Date();

    }; //, [mapKeyRef.current]);

    /* Cards content: MAKE IT WORK WITH SUSPENSE WITHOUT CREATING BUG WHEN COMING BACK TO MAIN PAGE... */
    /* Maps props -------------------------------------------------------------------------- : */
    const mapsProps = {
        mapOptions: mapOptionsRef.current,
        mapContainerStyle: mapContainerStyle,
        geoJson: geoJson, //geoJsonRef.current,
        geoJsonStyles: geoJsonStyles,
        geoJsonOnClick: geoJsonOnClick,
        refreshMapKey: mapKeyRef.current, //refreshMapKey,
        handleBoundsChange: handleBoundsChange,
    };


    /* Map title    */
    const buildMapTitle = () => {
        /*  Defines the map title according to current city, selected field and eventually selected modes   */
        const textFromTo = dictTextFromTo[fromToRef.current];   // 'depuis' ou 'vers'
        const titleFromTo = dictTitleFromTo[fromToRef.current];   // 'Origines' ou 'Destinations'


        let modeTitle = selectedModeRef.current ? ' (Modes: ' : null;
        let modesStr = '';
        if (selectedModeRef.current === modesList) {
            modesStr = t('Tous modes');
        } else if (selectedModeRef.current !== null) {
            selectedModeRef.current && selectedModeRef.current.map((mode, ix) => {
                if (mode.length > 0) {
                    let sep = (ix === (selectedModeRef.current.length - 1)) ? '' : ', ';  /* the modes delimiter  */
                    /* TRANSLATION TO BETTER MANAGE:    */
                    let tMode = `_${mode}`; /*  key '_' to distinguish from modes in table  */
                    modesStr += t(tMode) + sep;
                }
                return true;
            });
        }
        modeTitle = modeTitle ? modeTitle + modesStr + ')' : '';

        let title = '';
        if (aggTypeRef.current === 0) {
            /*  Title for avg city: */
            title = citySummary ? `${t(colMapRef.current)} - ${t(titleFromTo)} - ${t('Moyenne par commune')} ${modeTitle}` : '';
        } else {
            /*  Title for origin / destinations: */
            title = citySummary ? `${t(colMapRef.current)} - ${t(textFromTo)} ${citySummaryRef.current.name} ${modeTitle}` : '';
        }
        return title;
    };

    // const mapTitle = citySummary ? `${t(colMapRef.current)} ${t(textFromTo)} ${citySummary.name} ${modeTitle}` : '';
    const mapTitle = buildMapTitle();

    /* Layout props ------------------------------------------------------------------------ : */
    /*      Header items: options + title: list of lists    */
    const headerItems = [
        [
            {
                key: 'aggType',
                label: t('Type de carte'),
                type: 'radio',
                apiProps: {optionType: 'button', size: 'small', style: {padding: '2px 2px 2px 2px', margin: '0px'}},    // , width: '150px', textAlign: 'center', whiteSpace: 'noWrap'
                options: mapTypeOptions,
                onClick: selectAggType
            },
            {
                key: 'direction',
                label: t('Direction'),
                type: 'radio',
                options: mapHeadersOptions,
                onClick: originOrDest
            }],
        [{
            key: 'map_tile',
            type: 'jsx',
            options: <TitleOdc title={mapTitle} style={mapTitleStyle}/>,
        }]
    ];

    // const siderItems = [{
    //     key: 'mapData',
    //     label: t('Visualiser'),
    //     type: 'radio',
    //     // options: mapSiderOptions,
    //     onClick: selectMapField,
    // }
    // ];


    const siderRightItems = [{
        key: 'legend',
        label: t('Légende'),
        type: 'jsx',
        options: mapLegendRef.current
    }];

    /*  WORKS BUT MAKES MAP RELAOD EACH TIME +> CREATE SUB HEADER INSTEAD!!!!*/
    // const LayoutContent = () => {
    //     const textFromTo = dictTextFromTo[fromToRef.current];   // 'depuis' ou 'vers'
    //     const mapTitle = citySummary? `${t(colMapRef.current)} ${t(textFromTo)} ${citySummary.name}`: '';
    //     return (
    //         <>
    //                 <TitleOdc title={mapTitle}/>
    //                 <GoogleMapsOdc {...mapsProps} />
    //         </>
    //     )
    // };

    /*  the map option div and title div style: CANNOT MAKE TITLE CENTERED!!!! */
    const headerStyle = [
        null,
        {
            backgroundColor: 'transparent',
            paddingBottom: 0,
            marginBottom: 0,
            textAlign: 'center',
            display: 'flex'
        }
    ];

    /*  Map left-sider items: */
    const siderItemsJsx = useMemo(() => {
        return (
            <SiderMapCarbon onCheck={selectMapField} onCheckMode={selectModeField} maxHeight={mapHeight}/>   /* sub modes do not work when called like this*/
        )
    }, [allLoaded]);
    /*------------------------------------- end tests to avoid sider flickering -------------------------------------*/
    const layoutProps = {
        headerItems: headerItems,
        headerStyle: headerStyle,
        siderItemsJsx: siderItemsJsx,
        content: <GoogleMapsOdc {...mapsProps} />, //<LayoutContent />,  //
        siderRightItems: siderRightItems,
    };

    const mapContent = <LayoutUIOdc {...layoutProps} />;

    /* City summary------ ------------------------------------------------------------------- : */

    // const kpiContent = KpiInsee({citySummary: citySummaryRef.current, fromTo: fromTo});
    // const kpiContent = updateKpi();
    // const kpiContent = useMemo(() => {
    //     console.log('KPI content: ', citySummaryRef.current);
    //     return (
    //         <KpiInsee citySummary={citySummaryRef.current} fromTo={fromTo} />
    //     );
    // }, [fromTo, citySummary]);
    // const kpiContent = updateKpi();
    // const kpiContent = kpiContentRef? kpiContentRef.current: null;

    /* Charts props ------------------------------------------------------------------------- : */
    const chartsProps = {
        data: citySummary,
    };

    /*      Card titles --------------------------------------------------------------------- :*/
    const cityLabel = citySummary ? `${citySummary.name} (${citySummary.code})` : '';  /*  (Paris 1er arr (75101)  */
    const direction = dictTitleFromTo[fromTo] ? t(dictTitleFromTo[fromTo]) : t(dictTitleFromTo[f_from]);
    // const titleMap = t('Carte interactive');
    // const titleCity = citySummary ? `${t('Données')} ${cityLabel}` : t('Données ville');
    const titleModeShare = citySelect ? `${t('Parts modales')} ${cityLabel} - ${direction}` : t('Parts modales');
    const titleCarbon = citySelect ? `${t('Bilan carbone par déplacement')} ${cityLabel} - ${direction}` : t('Bilan carbone par déplacement');
    const titleTravelTime = citySelect ? `${t('Temps de parcours VP vs TC')} ${cityLabel} - ${direction}` : t('Temps de parcours VP vs TC');
    const titleTable = citySelect ? `${t('Données de flux détaillées')} ${cityLabel} - ${direction}` : t('Données Origines / Destinations détaillées');

    const cityItems = [
        {label: t('Bilan Carbone'), 'key': 'bc', children: <ChartCarbon {...chartsProps} title={titleCarbon}/>},
        {label: t('Parts modales'), 'key': 'pm', children: <ChartModeShare {...chartsProps} title={titleModeShare}/>},
        {
            label: t('Temps de parcours'),
            'key': 'tt',
            children: <ChartTravelTime {...chartsProps} title={titleTravelTime}/>
        }
    ];
    const cityContent = <TabsOdc items={cityItems}/>;

    /* Table data --------------------------------------------------------------------------- : */
    // const tableFromToContent = TableOdc? <TableOdc columns={inseeColumns} data={tblData}/>:
    //     (<Suspense fallback={"Loading OD tables..."}></Suspense>);
    downloadOptions['filename'] = citySummary ? `${citySummary.code}_${citySummary.name}_${dictTitleFromTo[fromToRef.current]}` : downloadOptions['filename'];
    const tableFromToContent = <TableOdc
        columns={tblCol}
        data={tblData}
        render_func={render_func}
        headerMapping={colMapping}
        downloadOptions={downloadOptions}
        maxHeight={tableHeight}
    />;

    return (
        <Layout style={layoutStyle}>

            <Content style={contentStyle}>

                {/*// ------------------------ HEADER CITY SUMMARY ---------------------------------------------- */}
                <Row align="middle" gutter={gutter}>
                    <Col span={24}>
                        <CardOdc
                            title={null}
                            content={kpiContentRef.current}
                            style={kpiBannerStyle}/>
                    </Col>
                </Row>

                {/*// ------------------------ MAPS, CHARTS AND TABLE ------------------------------------------ */}
                <Row align="middle" gutter={gutter}>

                    {/*// ------------------------ MAPS -------------------------------------------------------- */}
                    <Col xs={24} sm={24} md={24} lg={16} xl={16} xxl={18}>
                        <CardOdc
                            // title={titleMap}
                            content={mapContent}
                            style={cardStyle}
                            cardProps={cardProps}
                            childStyle={mapDivStyle}/>
                    </Col>

                    {/*// ------------------------ CITY SUMMARY CHARTS ------------------------------------------ */}
                    <Col xs={24} sm={24} md={24} lg={8} xl={8} xxl={6}>
                        <CardOdc
                            // title={titleCity}
                            content={cityContent}
                            style={cardStyle}
                            cardProps={cardProps}
                            childStyle={cardChildStyle}/>
                    </Col>

                </Row>

                {/*// ------------------------ TABLE ----------------------------------------------------------- */}
                <Row align="middle" gutter={gutter}>
                    <Col span={24}>
                        <CardOdc
                            title={titleTable}
                            content={tableFromToContent}
                            style={cardStyleTable}
                            cardProps={cardProps}
                        />
                    </Col>
                </Row>

            </Content>

            <Footer style={{width: '100%', margin: 2, padding: 2}}>
                <FooterOdc/>
            </Footer>

        </Layout>
    )
}

export default React.memo(InseePage);