/* *****************************************************************
* Generic operations for INSEE, called from InseePage
*
* Props:
*   - dfData:   INSEE full data as ODC df
*   - colFromTo:    'insee_from' or 'insee_to'
*   - ...
****************************************************************** */
import {merge, toCSV, toJSON} from "danfojs";

/*  ODC Libs*/
import {DfOdc, rowToListObj} from "../../jsOdcLib/DataframeOdc";
import {copyObj, printMsg, startTime, endTime} from "../../jsOdcLib/GenericJsOdc";

/* Data url: */
import OD_CSV from "../../data/api_data/insee/OD_bilan_carbone.csv";  // Full OD
import CITY_EM_CSV from "../../data/api_data/insee/City_em_bilan_carbone.csv";  // City emission
import CITY_AT_CSV from "../../data/api_data/insee/City_attr_bilan_carbone.csv";  // City attraction
// import CITY_TOT_CSV from "../../data/api_data/insee/City_tot_bilan_carbone.csv";  // City emission + attraction
import LKP_COM_CSV from "../../data/api_data/insee/lkp_com_IDF.csv";

/*  TESTS TO DO WITH DATA IN CLOUD BUCKET: FIX CORS ERROR TO DO */
// const path_data = `${process.env.REACT_APP_BUCKET}/data`;
// const OD_CSV = `${path_data}/OD_bilan_carbone.csv`;  // Full OD
// const CITY_EM_CSV = `${path_data}/City_em_bilan_carbone.csv`;  // City emission
// const CITY_AT_CSV = `${path_data}/City_attr_bilan_carbone.csv`;  // City attraction
// const CITY_TOT_CSV = `${path_data}/City_tot_bilan_carbone.csv`;  // City emission + attraction
// const LKP_COM_CSV = `${path_data}/lkp_com_IDF.csv`;

const f_from = 'insee_from';
const f_to = 'insee_to';
const f_name_from = 'Origin';
const f_name_to = 'Destination';
const f_com_id = 'COM_ID';  // the ID of the city in LKP_COM_CSV
const f_com_name = 'NOM_COM';  // the NAME of the city in LKP_COM_CSV
const modesList = ['VP', 'TC', '2RM', 'Vélo', 'Marche', 'Pas de transport'];
// let fSummaryList = ['flow_tot', 'mobpro_tot', 'mobsco', 'Bilan Carbone', 'DURATION_driving', 'DURATION_transit'].concat(modesList);
const f_weight = 'mobpro_tot';
const f_carbon = 'Bilan Carbone';
const f_carbon_norm = 'Bilan Carbone / depl';
const f_carbon_min = 'min_bc';  // only to get bounds in quantiles distribution
const f_carbon_max = 'max_bc';  // only to get bounds in quantiles distribution
const f_quantile = 'quantile';
const f_tt_car = 'DURATION_driving';
const f_tt_pt = 'DURATION_transit';
const f_flow_out = 'Flux Hors IDF'; /* flow out of the region: carbon calculation not done outside region   */

// const f_label = 'label';    // field for labelling the data
const f_fill = 'fill';  // field for fill color

const fillColorDefault = 'rgba(68,73,82,0.25)';
const fillColorSelectedCity = '#0000ffff';

// const di_code_name = {};   // matching between code and name fields
// di_code_name[f_from] = f_name_from;
// di_code_name[f_to] = f_name_to;

const citiesSummaryObj = {}; // Structure of summarised data for all cities
citiesSummaryObj[f_from] = null;
citiesSummaryObj[f_to] = null;
citiesSummaryObj[f_com_id] = null;

const citySummaryObj = {
    'name': null, 'code': null,
    'nbTrips': null,
    'bc': null, 'bcNet': null, 'bcDistr': null, /* carbon emission  */
    'tt': null, 'ttDistr': null,    /* travel time  */
    'pm': null,         /* mode share */
    'percOut': null,   /* % hors IDF*/
}; // Structure of summarised data for selected city

/* descr of objects: -------------------
*   ttDistr = [{COM_ID: 75116, COM_NAME: ..., DURATION_driving: 13.84, DURATION_transit: 24.74, fill: '#0000ffff'}, ...]
*   bcDistr =
* -------------------------------------   */

/* for tests: set to true if data from local storage is no longer up to date:   */
// const reloadFromAPI = false;
// const projKey = 'insee_bc';
// const localOdcData = {};    /* the object conatining the local storage data */
// localOdcData[projKey] = {};

export class InseeCls {
    /* *****************************************************************
    * INSEE Class
    *   props:
    *       - xxx
    *
    * Example call:
    *   - xxx
    *   -
    ****************************************************************** */

    constructor() {
        /* **************************
        *
        * ************************** */
        this.odfFullOD = null;    // full OD data as ODC df
        this.lkpCom = null;     // lkp code city
        this.citiesSummary = copyObj(citiesSummaryObj);     // summary emission, attraction and total as df
        this.carbonDistr = copyObj(citiesSummaryObj);       // summary carbon distribution emission, attraction and total as df
        // this.travelTimeDistr = copyObj(citiesSummaryObj);   // summary travel time distribution emission, attraction and total as df
        this.citySummary = copyObj(citySummaryObj);
        // this.regSummary = null;

        /*  nb quantiles:   */
        this.nb_quantiles = 10;

        /* number of cities in scatter plot */
        this.nb_scatter = 100;
    }

    async loadCityData() {
        /* **************************
        *   Load the city-level data (for quick load) and merge with cities names
        * ************************** */
        const f_com_from = f_name_from;    //   `${f_com_name}${suffixes['from']}`;
        const f_com_to = f_name_to;    //   `${f_com_name}${suffixes['to']}`;

        /*  Load the raw data: ---------------------------------------------------------------------------- */
        let odfCityEm;
        let odfCityAttr;
        let odfCityTot;

        printMsg('Loading city-level data from API');
        /*      Summary by city: ~60ms by df    */
        odfCityEm = await new DfOdc().load({file_path: CITY_EM_CSV});
        odfCityAttr = await new DfOdc().load({file_path: CITY_AT_CSV});
        // odfCityTot = await new DfOdc().load({file_path: CITY_TOT_CSV});

        /*      Lkp cities vs name: */
        let lkpCom = await new DfOdc().load({file_path: LKP_COM_CSV});
        this.lkpCom = lkpCom.df;
        this.lkpCom.setIndex({column: f_com_id, inplace: true, drop: false});

        /*  Add cities names to cities summary:  ~8ms  */
        odfCityEm.df = await merge({left: this.lkpCom, right: odfCityEm.df, on: [f_com_id], how: 'right'});
        odfCityAttr.df = await merge({left: this.lkpCom, right: odfCityAttr.df, on: [f_com_id], how: 'right'});
        // odfCityTot.df = await merge({left: this.lkpCom, right: odfCityTot.df, on: [f_com_id], how: 'right'});

        /*  Set the lkp and index: ~1ms   */
        odfCityEm.df.setIndex({column: f_com_id, inplace: true, drop: false});
        odfCityAttr.df.setIndex({column: f_com_id, inplace: true, drop: false});
        // odfCityTot.df.setIndex({column: f_com_id, inplace: true, drop: false});

        /*  Set the cities summary: */
        this.citiesSummary[f_from] = odfCityEm.df;
        this.citiesSummary[f_to] = odfCityAttr.df;
        // this.citiesSummary[f_com_id] = odfCityTot.df;

        /*  Get the carbon quantiles: ~2ms per entry --------------------------------------------------------------- */
        const getCarbonQuantiles = (df, nb_quantile, field_carbon) => {
            /* **************************
            *   Function that returns an array of the carbon emission at each quantiles
            *   Props:
            *       - df: the df having the values
            *       - nb_quantiles: the number of quantiles: 10 by default
            *       - field_carbon: the field having the carbon emission information
            *   Ex:
            *       - [0, 2.5, 5.4, 6.3] => first quartile is between 0 and 2.5, second between 2.5 and 5.4 atc.
            * ************************** */
            if (!nb_quantile) nb_quantile = this.nb_quantiles;
            if (!field_carbon) field_carbon = f_carbon_norm;

            /*  Get the sorted list of carbon emission values:   */
            let sCarbon = df[[field_carbon]].dropNa().values;
            sCarbon.sort();
            let nb_rec = sCarbon.length;
            let step = Math.floor(nb_rec / nb_quantile);

            /*  Populate the carbon edge values at each quantile:   */
            let carbon_quantiles = [];
            let rec = {};
            for (let i = 0; i < nb_quantile; i++) {
                rec[f_quantile] = i + 1;

                /* label and bounds:   */
                // rec[f_label] = `${10 * (i + 1)}%: < ${sCarbon[(i + 1) * step].toFixed(2)} kg/depl`;
                rec[f_carbon_min] = sCarbon[i * step];          // Lower bound
                rec[f_carbon_max] = sCarbon[(i + 1) * step];    // Upper bound

                /* fill color:  */
                rec[f_fill] = fillColorDefault;

                carbon_quantiles.push(copyObj(rec));
            }
            /*  Replace value in last entry by slightly higher entry:    */
            carbon_quantiles[(nb_quantile - 1)][f_carbon_max] = sCarbon[nb_rec - 1] + 0.0001;

            return carbon_quantiles;
        };

        this.carbonDistr[f_from] = getCarbonQuantiles(this.citiesSummary[f_from], this.nb_quantiles, f_carbon_norm);
        this.carbonDistr[f_to] = getCarbonQuantiles(this.citiesSummary[f_to], this.nb_quantiles, f_carbon_norm);
        // this.carbonDistr[f_com_id] = getCarbonQuantiles(this.citiesSummary[f_com_id], this.nb_quantiles, f_carbon_norm);

        return this;
    }

    async loadODdata() {
        /* **************************
        *   Load the full OD data and merge with cities names
        * ************************** */
        const f_com_from = f_name_from;    //   `${f_com_name}${suffixes['from']}`;
        const f_com_to = f_name_to;    //   `${f_com_name}${suffixes['to']}`;

        /*  Load the raw data: ---------------------------------------------------------------------------- */
        printMsg('Loading full OD data from API');
        /*      Full OD: ~2sec   */
        this.odfFullOD = await new DfOdc().load({file_path: OD_CSV});
        printMsg('ADDING REACT KEY TO FULL OD: REMOVE ONCE FILE STORED IN CLOUD STORAGE');
        let list_key = Array.from(Array(this.odfFullOD.df.index.length).keys());
        this.odfFullOD.df.addColumn('key', list_key, {inplace: true});
        /*  End raw data laod: ---------------------------------------------------------------------------- */

        /*      Lkp cities vs name: */
        let lkpCom = this.lkpCom; // await new DfOdc().load({file_path: LKP_COM_CSV});

        /* Add "f_from", "f_to", "f_com_from" and "f_com_to" to the lkpCom for merges */
        lkpCom.addColumn(f_from, lkpCom.loc({columns: [f_com_id]}).values, {inplace: true});
        lkpCom.addColumn(f_to, lkpCom.loc({columns: [f_com_id]}).values, {inplace: true});
        lkpCom.addColumn(f_com_from, lkpCom.loc({columns: [f_com_name]}).values, {inplace: true});
        lkpCom.addColumn(f_com_to, lkpCom.loc({columns: [f_com_name]}).values, {inplace: true});

        /*  Merge cities names with codes:  */
        let comFrom = lkpCom.loc({columns: [f_from, f_com_from]});
        let comTo = lkpCom.loc({columns: [f_to, f_com_to]});

        /*  Add cities names to full OD:  ~400ms  */
        // this.odfFullOD.df = await merge({left: this.odfFullOD.df, right: comFrom, on: [f_from], how: 'left'});
        // this.odfFullOD.df = await merge({left: this.odfFullOD.df, right: comTo, on: [f_to], how: 'left'});
        this.odfFullOD.df = await merge({left: comTo, right: this.odfFullOD.df, on: [f_to], how: 'right'});
        this.odfFullOD.df = await merge({left: comFrom, right: this.odfFullOD.df, on: [f_from], how: 'right'});

        /*  Rearrange columns of full OD:  VERY SLOW - CHANGED BY JUST MERGING LKP_COM TO THE LEFT*/
        // const colOrder = [f_from, f_to, f_com_from, f_com_to];
        // const colDf = this.odfFullOD.df.columns;
        // colDf.map(col => {
        //     if (!colOrder.includes(col)) colOrder.push(col);
        // });
        // this.odfFullOD.df.resetIndex({inplace: true});
        // this.odfFullOD.df = await this.odfFullOD.df.loc({columns: colOrder});   /* ~ 10 sec!!!  */

        /*  Set the columns as JSON format:  */
        this.odfFullOD.setColumns();

        /*  Set the data type: ~400ms */
        let st = startTime();
        this.odfFullOD.df.asType(f_from, 'int32', {inplace: true});
        this.odfFullOD.df.asType(f_to, 'int32', {inplace: true});
        st = endTime(st, 'setting col type');

        return this;
    }

    async getCitySummary(fromTo, city_id) {
        /* ******************************************
        *   Extracts the summary for the current city
        *   Props:
        *       - fromTo: f_from, f_to or f_com_id if total emission + attraction
        *       - city_id: code of the selected city
        *
        /* ****************************************** */
        let st0 = startTime();
        /*  Query city: ~90ms depends if the scatter is active! 90ms without scatter, 600 with... WHY??  */
        let dfTmp = this.citiesSummary[fromTo];
        let summaryTmp = await dfTmp.loc({rows: [city_id]});
        // endTime(st0, 'City query');

        /*  Populate citySummary:   */
        /*      City name: ~1ms  */
        let citySelect = await this.lkpCom.loc({rows: [city_id], columns: [f_com_name, f_com_id]});
        let cityName = citySelect.iloc({rows: [0], columns: [0]}).values[0][0];
        let cityCode = citySelect.iloc({rows: [0], columns: [1]}).values[0][0];
        this.citySummary['name'] = cityName;
        this.citySummary['code'] = cityCode.toString().padStart(5, '0');

        /*      Number of trips: ~0ms ------------------------------------------------------------------------------ */
        this.citySummary['nbTrips'] = summaryTmp.loc({columns: [f_weight]}).iloc({
            rows: [0],
            columns: [0]
        }).values[0][0];

        /*      Modes choices: ~0ms -------------------------------------------------------------------------------- */
        // console.log('summaryTmp: ', summaryTmp);
        this.citySummary['pm'] = await rowToListObj(summaryTmp.loc({columns: modesList}), 'name', 'value');

        /*      Carbon emission: ~0ms  ----------------------------------------------------------------------------- */
        const updateQuantilePosition = (carbonVal, carbonDistr) => {
            /* **************************
            *   Extracts the position of the city in the carbon distribution
            * ************************** */
            let updatedDistr = copyObj(carbonDistr);

            for (let i = 0; i < carbonDistr.length; i++) {
                // if ((carbonVal >= carbonDistr[i][f_carbon_norm]) && (carbonVal < carbonDistr[i + 1][f_carbon_norm])) {
                //     updatedDistr[i][f_fill] = fillColorSelectedCity;
                // } else {
                //     updatedDistr[i][f_fill] = fillColorDefault;
                // }
                if ((carbonVal >= carbonDistr[i][f_carbon_min]) && (carbonVal < carbonDistr[i][f_carbon_max])) {
                    updatedDistr[i][f_fill] = fillColorSelectedCity;
                } else {
                    updatedDistr[i][f_fill] = fillColorDefault;
                }
            }

            // /* check last entry: */
            // let i = carbonDistr.length - 1;
            // // console.log('i=', i, 'carbonVal: ', carbonVal, 'last val: ', carbonDistr[i-1][f_carbon_norm]);
            // if (carbonVal >= carbonDistr[i][f_carbon_norm]) {
            //     updatedDistr[i][f_fill] = fillColorSelectedCity;
            // } else {
            //     updatedDistr[i][f_fill] = fillColorDefault;
            // }
            return updatedDistr;
        };

        let dfBc = summaryTmp.loc({columns: [f_carbon_norm, f_carbon]});
        let cityBc = dfBc.iloc({rows: [0], columns: [0]}).values[0][0];
        let cityBcNet = dfBc.iloc({rows: [0], columns: [1]}).values[0][0];
        let cityBcDistr = this.carbonDistr[fromTo];

        /*          Update label and fill color:    */
        cityBcDistr = await updateQuantilePosition(cityBc, cityBcDistr);

        /*          Populate values:    */
        this.citySummary['bc'] = cityBc;
        this.citySummary['bcNet'] = cityBcNet;
        this.citySummary['bcDistr'] = cityBcDistr;
        // console.log('bcDistr: ', cityBcDistr);

        /*  Travel times: ~2ms ------------------------------------------------------------------------------------  */
        const updateTravelTimeAttr = async (df) => {
            /* **************************
            *   Updates the fill color for the selected city
            * ************************** */
            let ttSelect = toJSON(df.loc({rows: [city_id]}));
            ttSelect[0][f_fill] = fillColorSelectedCity;
            // /*  Select only sample for visbility and speed: */
            df.resetIndex({inplace: true}); // need to reset the index for the sample
            let dfPlot = await df.sample(this.nb_scatter);
            // dfPlot.head().print();
            let ttPlot = toJSON(dfPlot);
            // let ttPlot = toJSON(df);
            let cityFound = false;
            ttPlot.map((rec, idx) => {
                let tmpCity = rec[f_com_id];
                if (tmpCity === city_id) {
                    ttPlot[idx][f_fill] = fillColorSelectedCity;
                    cityFound = true;
                } else {
                    ttPlot[idx][f_fill] = fillColorDefault;
                }
            });
            if (!cityFound) {
                ttPlot = ttPlot.concat(ttSelect);
            }
            return ttPlot;
        };

        let ttDistr = dfTmp.loc({columns: [f_com_id, f_com_name, f_tt_car, f_tt_pt]});  //
        let cityTt = summaryTmp.loc({columns: [f_tt_car, f_tt_pt]});
        /*          Update label and fill color:    */
        // /*          Populate values:    */
        this.citySummary['ttDistr'] = await updateTravelTimeAttr(ttDistr);
        this.citySummary['tt'] = toJSON(cityTt)[0];

        /*  Flow outside region: ~??ms ----------------------------------------------------------------------------  */
        let dfPercOut = summaryTmp.loc({columns: [f_weight, f_flow_out]});
        let flowTot = dfPercOut.iloc({rows: [0], columns: [0]}).values[0][0];
        let flowOut = dfPercOut.iloc({rows: [0], columns: [1]}).values[0][0];
        let percOut = flowOut / flowTot;
        this.citySummary['percOut'] = percOut;
// console.log('% out region: ', Math.round(100*percOut), '%');

        return this.citySummary;
    }

}
