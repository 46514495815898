import React, {useState, useEffect, useCallback, useRef } from 'react';
import {GoogleMap, Marker, useLoadScript} from "@react-google-maps/api";    // , useGoogleMap, InfoWindow

/* App config import:   */
import {DataFields} from "../../data/AppInfo";

/* ODC map styles and options:   */
import {
    defMapOptions,
    defGeoJsonStyle,
    defHoverPolygStyle,
    defMapContainerStyle,
    defMapTooltipFormatter,
    // TooltipMarkerOdc
} from "./GoogleMapsStyles";
import {printMsg} from "../../jsOdcLib/GenericJsOdc";


/* *****************************************************************
* TO DO:
* check when more than1 geoJSON layer imported, other than polygons and eventually with different types (points lines etc.)
* Check label, tooltip on hover: https://codesandbox.io/s/google-map-react-markers-hover-and-click-example-zhk16
*
* Links and doc:
*   - map class: https://developers.google.com/maps/documentation/javascript/reference#Map
*   - data class: https://developers.google.com/maps/documentation/javascript/reference#Data
*   - default map options: see https://developers.google.com/maps/documentation/javascript/reference/map#MapOptions
*   - All <GoogleMap /> props options: https://react-google-maps-api-docs.netlify.app/#googlemap
****************************************************************** */

const GoogleMapsOdc = (props) => {
    /* *****************************************************
    * Props description:
    *   - f_lat, f_lng, f_mkId, f_key etc.: names of latitude, longitude, key fields (default names in DataFields)
    *   - markers: list of objects with lat & lng keys
    *   - mkfId: name of the location ID field for the markers
    *   - mkStyles: list of marker styles objects: [{mkfId: 1, icon: '...', etc.}, ...]
    *   - selectedIds: list of selected Ids (for zoom, panning and marker style)
    *   - geoJson: List of GeoJSON objects to be loaded
    *   - geoJsonStyles: List of GeoJSON style configurations (defined as functions or static styles). If specified, needs to be the same size as geoJson: apply style functions: see https://jacobfilipp.com/creating-choropleth-maps-with-google-maps/
    *   - geoJsonHoverStyles: List of GeoJSON style configurations fro when the features are hovered. If specified, needs to be the same size as geoJson
    *   - mapOptions: mapId, center, zoom, styles etc.
    *   - mapContainerStyle: position, min width, height etc. of the map container
    *   - mapBounds: Google Maps Bounds object to set the map bounds
    *   - Events handlers: full list here: https://react-google-maps-api-docs.netlify.app/#googlemap
    *       * onClick: when click on a marker or loaded layer
    *       * onHover: ...
    *       * showTitle: function that format the title to be shown on hover
    *       * geoJsonOnClick: when the user clicks on a geoJson feature
    *       * ...
    *   - refreshMapKey: unique key indicating that the map needs refresh (eg. current timestamp)
    *   - debug: true if show debugging messages
    ****************************************************** */

    /* ********************************* DEFAULT INPUT VALUES ********************************************************/
    const mapOptions = {...defMapOptions, ...props.mapOptions};
    const mapContainerStyle = props.mapContainerStyle ? {...defMapContainerStyle, ...props.mapContainerStyle} : defMapContainerStyle;
    const f_lat = props.f_lat ? props.f_lat : DataFields.locTable.lat;
    const f_lng = props.f_lng ? props.f_lng : DataFields.locTable.lng;
    const f_key = props.f_key ? props.f_key : DataFields.allTables.key;
    const f_mkId = props.f_mkId ? props.f_mkId : DataFields.locTable.id;


    /* ********************************* LOADING AND STATES SETTINGS *************************************************/

    /*  Load Google Maps API:   */
    const {isLoaded} = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_BASE_GMAPS_API_KEY,
    });

    /*  Create some state that will be used to reference to the <GoogleMap /> component:    */
    const [mapRef, setMapRef] = useState(null);

    /* Tootlitp markers state:  DOES NOT WORK */
    // const [tooltipProps, setTooltipProps] = useState(null);
    // const [tooltipMk, setTooltipMk] = useState(null);

    /*  center, zoom and bound states to be kept from a refresh to another... DOES NOT WORK!!!! */
    // const [mapCenter, setMapCenter] = useState(null);
    // const [mapZoom, setMapZoom] = useState(null);
    const zoomRef = useRef(null);
    const [mapBounds, setMapBounds] = useState(undefined);
    // const mapBoundsRef = useRef(null);

    // /*  Set map center: */
    // useEffect(() => {
    //     /*  Set the map center on load the first time only. Otherwise, keep previous value */
    //     props.mapOptions && setMapCenter(mapOptions.center);
    // }, [props.mapOptions]);
    //
    // /*  Set map zoom: */
    // useEffect(() => {
    //     /*  Set the map zoom on load the first time only. Otherwise, keep previous value */
    //     props.mapOptions && setMapZoom(mapOptions.zoom);
    // }, [props.mapOptions]);
    //
    // /*  Set map bounds: */
    // useEffect(() => {
    //     props.mapBounds && setMapBounds(props.mapBounds);
    // }, [props.mapBounds]);

    // const mapRef = useRef(null); // difference between useRef and useState: see here: https://stackoverflow.com/questions/56455887/react-usestate-or-useref

    /*  This is required for the marker to appear:  */
    const [isMounted, setIsMounted] = useState(false);

    useEffect(() => setIsMounted(true), []);


    /* ********************************* EVENTS HANDLERS AND DISPLAY FUNCTIONS ***************************************/
    /*  Get clicked data feature:   */
    const onClick = useCallback((e, targetData) => {
        // Transform target data into a list of objects if single selected object:
        let selectedList = null;
        if (Array.isArray(targetData)) {
            selectedList = [...targetData];
        } else {
            selectedList = [targetData];
        }
        props.onClick && props.onClick(selectedList);
        // Record current zoom:
    }, [props.onClick]);

    const showTitle = useCallback((targetData) => {
        /*  Get current zoom:           */
        const currZoom = zoomRef.current;
        /*  Tooltip function on hover   */
        const title = props.showTitle ? props.showTitle(targetData, currZoom) : JSON.stringify(targetData);
        return title;
    }, [props.showTitle]);

    const geoJsonOnClick = useCallback((targetData) => {
        props.geoJsonOnClick && props.geoJsonOnClick(targetData);
    }, [props.geoJsonOnClick]);

    const handleBoundsChange = () => {
        const newBounds = mapRef && mapRef.getBounds();
        const newCenter = mapRef && mapRef.getCenter();
        const newZoom = mapRef && mapRef.getZoom();
        zoomRef.current = newZoom;
        // mapRef && console.log('BOUNDS CHANGED: ', newBounds);
        props.handleBoundsChange && props.handleBoundsChange(newBounds, newCenter, newZoom);

    };

    /* ********************************************** MARKERS ********************************************************/
    /* Markers state:   */
    const [markers, setMarkers] = useState(null);

    /* Update markers through useEffect:    */
    useEffect(() => {
        setMarkers(() => loadMarkers());
    }, [mapRef, props.markers, props.selectedIds]);

    /* Load markers function:   */
    const loadMarkers = () => {
        // console.log("loading markers. Selected IDs: ", props.selectedIds);
        let mks = null;
        let icon = null;
        if ((mapRef) && (Array.isArray(props.markers))) {
            printMsg(`${props.markers.length} markers loaded`, null, props.debug);
            const bounds = new window.google.maps.LatLngBounds();
            mks = props.markers.map((marker, idx) => {
                // handle style with mkStyles[idx]:
                //          TO IMPLEMENT LATER
                // check if marker is selected:
                if (props.selectedIds) {
                    if (props.selectedIds.includes(marker[f_mkId])) {
                        icon = mapOptions.iconSelected;
                        console.log("icon selected: ", marker[f_mkId]);
                        // Extend map bound:
                        bounds.extend({
                            lat: marker[f_lat],
                            lng: marker[f_lng],
                        });
                    } else {
                        icon = mapOptions.iconOthers;
                    }
                } else {
                    // Extend map bound:
                    bounds.extend({
                        lat: marker[f_lat],
                        lng: marker[f_lng],
                    });
                    // Set default style:
                    icon = mapOptions.icon;
                }
                // Add markers:
                return (
                    <Marker
                        position={{lat: marker[f_lat], lng: marker[f_lng]}}
                        key={marker[f_key]}
                        icon={icon}
                        onClick={((e) => onClick(e, marker))}
                        title={showTitle(marker)}
                    />
                );
            });
            // console.log("markers bounds: ", JSON.stringify(bounds));
            // bounds && mapRef.fitBounds(bounds);
            bounds && setMapBounds(bounds);
        }
        return mks;
    };

    /* ********************************************** GEOJSON LAYERS RENDERING ***************************************/
    /*  Load geoJson function: Note: set styles to null when unloading rather than unloading? see https://developers.google.com/maps/documentation/javascript/dds-boundaries/style-polygon */
    const loadGeoJson = (map) => {
        // Transform to list of layers if single layer:
        const geoJsonLayers = props.geoJson ? Array.isArray(props.geoJson) ? props.geoJson : [props.geoJson] : null;
        // handle case when not specified but more than one layer TO DO
        const geoJsonStyles = props.geoJsonStyles ? Array.isArray(props.geoJsonStyles) ? props.geoJsonStyles : [props.geoJsonStyles] : [defGeoJsonStyle];
        const geoJsonHoverStyles = props.geoJsonHoverStyles ? Array.isArray(props.geoJsonHoverStyles) ? props.geoJsonHoverStyles : [props.geoJsonHoverStyles] : [defHoverPolygStyle];
        const tooltipFormatter = props.tooltipFormatter ? props.tooltipFormatter : defMapTooltipFormatter;

        // Load geoJson layers + styles + events handlers:
        // doc here: https://developers.google.com/maps/documentation/javascript/datalayer#add_event_handlers
        if (geoJsonLayers) {
            // console.log("rendering geoJSON file: ");
            // console.log(mapRef);

            let hoverStyle = null;
            geoJsonLayers.map((geoJson, idx) => {

                /* GeoJSON layer:   */
                map.data.addGeoJson(geoJson);
                /* Revert previous style: CHECK IF WORKS WHEN MANY LAYERS!!! */
                // console.log('setting previous style to null...');
                // map.data.setStyle(null);  // or?
                // map.data.setStyle({}); // or?
                // map.data.revertStyle();
                /* Style applied to the layer:  */
                let tmpStyle = {...defGeoJsonStyle, ...geoJsonStyles[idx]};
                map.data.setStyle(tmpStyle);
// console.log('tmpStyle: ', tmpStyle);

                /* Add onClick (pass as prop to do: */
                map.data.addListener("click", (e) => {
                    // console.log("polygon data: ", JSON.stringify(targetData));
                    // console.log("polygon event: ", e.feature.j);
                    geoJsonOnClick(e.feature);
                });

                /* Add mouseover listener to change the style:  */
                hoverStyle = geoJsonHoverStyles[idx];
                map.data.addListener('mouseover', (event) => {

                    /*  title (tooltip) returns the correct value but does not show on hover! only in the console!!!  */
                    let newStyle = props.showTitle? {...hoverStyle, ...{label: showTitle(event)}}: {...hoverStyle};
                    // let newStyle = props.showTitle? {...hoverStyle, ...{tooltip: showTitle(event)}}: {...hoverStyle};

                    map.data.revertStyle();
                    // map.data.overrideStyle(event.feature, {...hoverStyle});
                    map.data.overrideStyle(event.feature, {...newStyle});

                    /*  Create tooltip TO FIX: */
                    // const tooltip = tooltipFormatter(event.feature, map.getZoom());
                    // tooltip && console.log('hover lcoation: ', event.latLng, event);
                    // setTooltipProps(() => {return {tooltip: tooltip, latLng: event.latLng, show: true}});

                    /* end tooltip */
                });

                map.data.addListener('mouseout', (event) => {
                    map.data.revertStyle();
                    // setTooltipProps(() => {return {show: false}});
                });
                return true;
            });
        }
    };

    /*  refresh geoJson style:  */
    useEffect(() => {
        // console.log('REFRESHING geoJSON map style: ');
        /*  FIX HERE IF LIST OF STYLES*/
        let tmpStyle = {...defGeoJsonStyle};
        if (typeof props.geoJsonStyles === 'function') {
            /* Styles are defined as function, cannot be happened   */
            tmpStyle = props.geoJsonStyles;
        }
        else {
            tmpStyle = {...defGeoJsonStyle, ...props.geoJsonStyles};
        }
        // let tmpStyle = (Object.is) {...defGeoJsonStyle, ...props.geoJsonStyles};
// console.log('pros geojson: ', props.geoJsonStyles, typeof props.geoJsonStyles);
        mapRef && mapRef.data.setStyle(tmpStyle);
        // mapRef && mapRef.data.setStyle(props.geoJsonStyles);

        // mapRef && console.log('updating zoom to: ?', mapRef.zoom, mapZoom);
        // currZoom && mapRef.setZoom(currZoom);
    }, [mapRef, props.refreshMapKey, props.geoJsonStyles]);

    /* Fit map bounds:  */
    useEffect(() => {
        if (mapBounds) {
            // console.log('Fitting bounds to: ', JSON.stringify(mapBounds));
            // console.log("map object in fit bounds: ", mapRef);
            if (mapRef) {
                mapRef.fitBounds(mapBounds);
                // Reset min zoom:
                const zoom = mapRef.getZoom();
                // console.log("current zoom: ", zoom);
                // (mapOptions.maxZoom !== undefined) && mapRef.state.map.setZoom(zoom > mapOptions.maxZoom? mapOptions.maxZoom: zoom);
                // (mapOptions.minZoom !== undefined) && mapRef.state.map.setZoom(zoom < mapOptions.minZoom? mapOptions.minZoom: zoom);
                (mapOptions.maxZoom !== undefined) && mapRef.setZoom(zoom > mapOptions.maxZoom ? mapOptions.maxZoom : zoom);
                (mapOptions.minZoom !== undefined) && mapRef.setZoom(zoom < mapOptions.minZoom ? mapOptions.minZoom : zoom);
            }
        }
    }, [mapRef, mapBounds, mapOptions]);

    /*  All actions on map load:    */
    const onMapLoad = useCallback((map, maps) => {
        /* Set map ref: */
        setMapRef(map);

        // Load geoJson layers:
        if ((map) && (props.geoJson)) {
            // console.log("rendering geoJSON file: ");
            // map.data.addGeoJson(props.geoJson);
            loadGeoJson(map);
        }

        /* Set Map zoom and bounds: DOES NOT WORK */
        // map && setMapZoom(map.zoom);
        // mapBoundsRef && console.log('setting map bound to ', mapBoundsRef.current);
        // mapBoundsRef.current && mapRef.fitBounds(mapBoundsRef.current);
        //
        // console.log("map inital zoom & bounds: ", mapZoom, mapBounds);

        // Update typeId: does not work
        // map.setMapTypeId('8ca100f2823e2c9e');

        // Update map style: does not work
        // map.defaultProps = defMapStyles;

        // Fit map bounds
        // console.log("map object in onMapLoad: ", map);
        // console.log("map object in onMapLoad after setRef: ", mapRef);

        /* Fit bounds (only called at mounting...):  */
        // props.mapBounds && mapRef.fitBounds(props.mapBounds);

    }, [mapRef, props.geoJson, loadGeoJson]);  // , props.refreshKey

    /* ***************************************************************************************************************/
    /* ********************************************** MAP OBJECT RENDERING *******************************************/
    /* ***************************************************************************************************************/
    const renderMap = () => {
        /* wrapping to a function is useful in case you want to access `window.google`
         to eg. setup options or create latLng object, it won't be available otherwise*/

        return (
            <GoogleMap
                mapContainerStyle={mapContainerStyle}
                options={mapOptions}    /* default options  */
                // styles={defMapStyles}
                //ref={mapRef}
                onLoad={(map, maps) => onMapLoad(map, maps)}
                onZoomChanged={handleBoundsChange}
                // onBoundsChanged={handleBoundsChange} /* called too many times: use onDragEnd instead */
                onDragEnd={handleBoundsChange}
                onResize={handleBoundsChange}
            >
                {isMounted && markers}
                {/*{isMounted && <TooltipMarkerOdc {...tooltipProps} />}*/}
            </GoogleMap>

        );
    };

    return isLoaded ? renderMap() : "Loading...";

};

export default React.memo(GoogleMapsOdc);
