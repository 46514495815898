/* *******************************************************************
* Creates a layout with a sider, options header and main content
*   Props:
*       - headerItems & siderItems: list of objects for populating Header & sider:
*           Ex format: ('type' MUST BE AMONG: ['jsx', 'radio', 'check']), 'combo', 'search' etc. to add later!
*               headerOptions = [
*                   {label: 'Ex JSX', type: 'jsx', options: <MyJsxComponent />, onClick: onHeaderJsxClick},
*                   {label: 'Direction', type: 'radio', options: ['Orig', 'Dest', 'Total'], onClick: onHeaderCheckClick},
*                   {label: 'Mode', type: 'check', options: ['VP', 'TC', 'Vélo', 'Marche'], onClick: onHeaderOptClick},
*               ];
* Ex parent component:
* const ParentLayout = () => {
    const onHeaderCheckClick = (val) => {...};
    const onHeaderOptClick = (val) => {...};
    const onSiderCheckClick = (val) => {...};
    const onSiderOptClick = (val) => {...};

    const TestJsx = () => {
        const li = [1, 2, 3, 4];
        const legend = li.map((l, ix) => { return <div key={ix} style={{color: getRandomColor()}}>'dsds'</div>})
        return legend;
    };

    const headerItems = [
        {key: '1', icon: <UserOutlined/>, label: 'Login', type: 'radio', options: ['Orig', 'Dest', 'Total'], onClick: onHeaderCheckClick},
        {key: '2', icon: <MenuFoldOutlined/>, label: 'Mode', type: 'check', options: ['VP', 'TC', 'Vélo', 'Marche'], onClick: onHeaderOptClick},
    ];

    const siderItems = [
        {key: '1', icon: <UserOutlined/>, label: 'Direction', type: 'radio', options: ['Orig', 'Dest', 'Total'], onClick: onSiderCheckClick},
        {key: '2', icon: <MenuFoldOutlined/>, label: 'Mode', type: 'check', options: ['VP', 'TC', 'Vélo', 'Marche'], onClick: onSiderOptClick},
        {key: '3', label: 'No icon', options: ['tram', 'bus']},
    ];

    const siderRightItems = [{key: 'legend', label: 'Legend', type: 'jsx', options: <TestJsx/>}];

    const footer = <div>OpenDC www.open-dc.com</div>

    const layoutProps = {
        headerItems: headerItems,
        siderItems: siderItems,
        siderRightItems: siderRightItems,
        siderOptions: {breakpoint: 'md', collapsedWidth: '0', width: 120},
        footer: footer,
    };

    return (
        <LayoutOdc {...layoutProps}/>

    )
};
*
* See: https://ant.design/components/layout/
********************************************************************* */


import React, { useState, useEffect } from "react";
import {Layout, Divider, Row} from "antd";
import {RadioListOdc, CheckListOdc} from "../buttons/ButtonsOdc";
import {gridBreakpoints} from "../../jsOdcLib/GenericJsOdc";

/*  Ant icons:  */
import {
    MenuFoldOutlined,
    MenuUnfoldOutlined,
    UserOutlined,
} from '@ant-design/icons';
import HeaderMenuOdc from "./HeaderOdc";
import SiderMenuOdc from "./SiderOdc";

/*  ODC icons:  */
// import logoDef from '../../img/ODClogo-mini_green.png';
// import logoDef from '../../img/ODClogo-full_green_frame.png';

/*  Default Styles  -------------------------------------------------------------------*/
const marginDef = 2;
const paddingDef = 10;
const theme = 'light';
const borderDef = 'solid'; // 'dotted'; // /* for identifying frames in dev mode: to remove for prod   */
const borderWidthDef = '1px'; /* for identifying frames in dev mode: to remove for prod   */
const borderColorDef = '#FFFFFF';

/*  UI types styles:    */

/*      Outer div:  */
const styleDivLayoutDef = {
    backgroundColor: 'transparent',
    borderRadius: '10px',
    borderStyle: borderDef,
    borderWidth: borderWidthDef,
    borderColor: borderColorDef,
    margin: marginDef,
    padding: paddingDef,
};

/*      Header div: */
const styleDivHeaderDef = {
    backgroundColor: 'rgba(68,73,82,0.05)',
    borderRadius: '10px',
    borderStyle: borderDef,
    borderWidth: borderWidthDef,
    borderColor: borderColorDef,
    margin: marginDef,
    padding: paddingDef,
    verticalAlign: 'middle',
};

/*      Content div: */
const styleDivContentDef = {
    backgroundColor: 'transparent',
    // borderRadius: '10px',
    // borderStyle: borderDef,
    // borderWidth: borderWidthDef,
    margin: marginDef,
    padding: paddingDef,
};

/*      Footer div: */
const styleDivFooterDef = {textAlign: 'center',};

/*      Titles and labels: */
// const styleLabelsDef = {color: 'grey', padding: paddingDef};    /* style of options description */
// const styleOptionsDef = {color: 'red', padding: paddingDef};    /* style of options description */
const styleSiderDef = {
    theme: theme,
    collapsible: true,
    width: 120,
    collapsedWidth: 30,
    breakpoint: 'xl',
    height: '100%',
};
const styleSiderLabelDef = {color: 'grey', paddingTop: '5px', paddingBottom: '2px',};  //  paddingRight: '10px', borderBottom: '1px solid darkgrey'
const styleSiderOptionsDef = {color: 'black', paddingLeft: '5px'};
const styleHeaderLabelDef = {color: 'grey', paddingLeft: '5px',};  //  paddingRight: '10px', borderBottom: '1px solid darkgrey'
const styleHeaderOptionsDef = {color: 'black',};

/*      Fold icon style:    */
const styleFoldIconDef = {fontSize: '20px'};

/*  Example default values for debugging:   */
const defItemsUI = [
    {key: '1', icon: <UserOutlined/>, label: 'nav 1',},
    {key: '2', icon: <MenuFoldOutlined/>, label: 'nav 2',},
    {key: '3', icon: <MenuUnfoldOutlined/>, label: 'nav 3',},
];

// const defItemsMenu = [
//     {key: '1', icon: <UserOutlined/>, label: 'nav 1',},
//     {
//         key: '2',
//         icon: <MenuFoldOutlined/>,
//         label: 'nav 2',
//         children: [{key: 11, label: 'sub 1'}, {key: 12, label: 'sub 2'}]
//     },
//     {key: '3', icon: <MenuUnfoldOutlined/>, label: 'nav 3',},
// ];

/*  Application  ---------------------------------------------------------------------- */
// const {Content} = Layout;
const { Sider, Content} = Layout;

/* UI options (list of check box, radio etc.):    */
const buildUIoptions = (props) => {
    const obj = props.obj; /* the option object: eg: {label: 'Direction', type: 'radio', options: ['Orig', 'Dest', 'Total'], onClick: onHeaderCheckClick}*/
    const orientation = props.orientation;  /* vertical or horizontal   */
    const styleItems = props.styleItems ? props.styleItems : {listStyleType: 'none', paddingLeft: paddingDef}; /* for default list, no check box or radio*/
    const apiProps = obj.apiProps? obj.apiProps: {};    /*  options from the API*/

    let uiOptions;
    switch (obj['type']) {
        case undefined:
            uiOptions = null;
            break;
        case 'jsx':
            uiOptions = obj['options'];
            break;
        case 'radio':
            uiOptions = <RadioListOdc checkList={obj['options']} onCheck={obj['onClick']} direction={orientation} apiProps={apiProps}/>;
            break;
        case 'check':
            uiOptions =
                <CheckListOdc checkList={obj['options']} onCheck={obj['onClick']} orientation={orientation} apiProps={apiProps}/>;
            break;
        default:
            uiOptions = obj['options'].map((opt, ix) => {
                return <li key={`opt_${ix}`}>{opt}</li>
            });
            uiOptions = <ul style={styleItems}>{uiOptions}</ul>
    }
    return uiOptions;
};

export const HeaderUIOdc = (props) => {
    /* -----------------------------------
    * HANDLE ICON TO DO
    *   Creates a header for UI elements: search box, tick boxes etc.
    *   Differs from HeaderMenuOdc as header set as scrollable row rather than collapsable menu
    *   Ex items:
    *   [
    *       {key: '1', icon: <UserOutlined/>, label: 'Direction', type: 'radio', options: ['Orig', 'Dest', 'Total'], onClick: onSiderCheckClick},
    *       {key: '2', label: 'Mode', type: 'check', options: ['VP', 'TC', 'Vélo', 'Marche'], onClick: onSiderOptClick},
    *       ...,
    *   ]
    *   If list of list, creates several headers on the top of each other
    * ----------------------------------- */

    /* props list: -------------------------------------------------------------------- */
    const items = props.items ? props.items : defItemsUI;
    const style = props.style ? props.style : styleDivHeaderDef; /* can be a list of styles, default values handled below  */
    const labelStyle = props.labelStyle ? {...styleHeaderLabelDef, ...props.labelStyle} : styleHeaderLabelDef;
    const optionsStyle = props.optionsStyle ? {...styleHeaderOptionsDef, ...props.optionsStyle} : styleHeaderOptionsDef;
    /* end props list: ---------------------------------------------------------------- */

    /*  Check if one or several headers:    */
    const isList = (Array.isArray(items[0])) ? true : false;

    /* Build header:    */
    const buildHeader = (options) => {
        if ((options === null) | (options === undefined)) {
            return null;
        }
        const content = options.map((obj, ix) => {
            /*  Divider if more than one group: */
            let divider = (obj.label) ? <Divider type="vertical"/> : '';
            /*  Radio options in header:    */
            let uiOptions = buildUIoptions({obj: obj, orientation: 'horizontal'});

            return (
                <Row key={`obj_${ix}`}>
                    <span style={labelStyle}>{divider}{obj.label}{divider}</span>
                    <span style={optionsStyle}>{uiOptions}</span>
                </Row>
            )
        });

        return (
            content
        )
    };


    const buildMutltipleHeaders = (headers) => {
        /*  Builds multiple headers on the top of each other    */
        let listHead = headers.map((head, ix) => {
            let styleIx = Array.isArray(style) ?
                (style[ix]) ? {...styleDivHeaderDef, ...style[ix]} :
                    styleDivHeaderDef :
                {...styleDivHeaderDef, ...style};
            return (
                <div key={`div_${ix}`} style={styleIx}>
                    <Row key={`opt_${ix}`}>
                        {buildHeader(head)}
                    </Row>
                </div>
            );
        });
        return (
            <div>
                {listHead}
            </div>
        );
    };

    let header = isList ? buildMutltipleHeaders(items) : buildHeader(items);

    return (
        header
    )
};

export const SiderUIOdc = (props) => {
    /* -----------------------------------
    *   Creates a sider for UI elements: layers, tick boxes etc.
    *   Differs from SiderMenuOdc as makes the sider collapse to single button
    *   Ex items:
    *   [
    *       {key: '1', icon: <UserOutlined/>, label: 'Direction', type: 'radio', options: ['Orig', 'Dest', 'Total'], onClick: onSiderCheckClick},
    *       {key: '2', label: 'Mode', type: 'check', options: ['VP', 'TC', 'Vélo', 'Marche'], onClick: onSiderOptClick},
    *       ...,
    *   ]
    *   itemsJsx: If items are more complex, eg with dividers between element of same group, can create complete jsx
    *   See API here: https://ant.design/components/layout/#Layout.Sider
    * ----------------------------------- */

    /* props list: -------------------------------------------------------------------- */
    const items = props.items ? props.items : defItemsUI;
    const itemsJsx = props.itemsJsx ? props.itemsJsx : null;    /*  for more complex layout, built separatly as JSX. In this case, no need for items...    */
    /*  Options from Sidebar API:   */
    const breakpoint = props.breakpoint ? props.breakpoint : 'md';
    const collapsedWidth = props.collapsedWidth ? props.collapsedWidth : '24';
    const onCollapse = props.onCollapse ? props.onCollapse : null;
    const onBreakpoint = props.onBreakpoint ? props.onBreakpoint : onCollapse;
    const collapsed = props.collapsed ? props.collapsed : false;
    const style = props.style ? {...styleSiderDef, ...props.style} : styleSiderDef;
    const width = props.width ? props.width : 150;
    /*  Custom options: */
    const labelStyle = props.labelStyle ? {...styleSiderLabelDef, ...props.labelStyle} : styleSiderLabelDef;
    const optionsStyle = props.optionsStyle ? {...styleSiderOptionsDef, ...props.optionsStyle} : styleSiderOptionsDef;
    /* end props list: ---------------------------------------------------------------- */

    /* Build sider: builds the UI options   */
    const buildSider = (options) => {
        if (options === null) {
            return null;
        }
        const content = options.map((obj, ix) => {
            /*  Chekc or Radio options in sider:    */
            let uiOptions = buildUIoptions({obj: obj, orientation: 'vertical'});

            return (
                <div key={ix}>
                    <div style={labelStyle}>
                        <Divider orientation="left" style={labelStyle}>{obj.icon}{obj.label}</Divider>
                    </div>
                    <div style={optionsStyle}>
                        {uiOptions}
                    </div>
                </div>
            )
        });

        return content;

    };

    const content = itemsJsx ? itemsJsx : buildSider(items);

    /*  Builds the sider as option list rather than Menu and submenu items: */
    return (
        <Sider
            breakpoint={breakpoint}
            collapsed={collapsed}
            collapsedWidth={collapsedWidth}
            style={style}
            theme={theme}
            width={width}
            trigger={null}
            onBreakpoint={onBreakpoint}
            onCollapse={onCollapse}
        >

            {content}

        </Sider>
    )
};

export const LayoutUIOdc = (props) => {
    /* -----------------------------------
    *   Creates a dynamic UI layout (UI items: check boxes etc. or JSX) with header, sider (left and right), content and footer
    *   For "Menu" type layout (Menu items with links etc), use LayoutMenuOdc
    *   Props:
    *       - siderItems: eg:
    *           [{key: '1', icon: <MyIcon/>, label: 'Direction', type: 'radio', options: ['Orig', 'Dest', 'Total'], onClick: myFunction},]
    *
    * ----------------------------------- */

    /* props list: -------------------------------------------------------------------- */
    /*  Content:    */
    const siderItemsJsx = props.siderItemsJsx ? props.siderItemsJsx : null;    /* if the sider is built before: in this case, no need for siderItems etc. */
    const siderItems = props.siderItems ? props.siderItems : defItemsUI;
    const siderRightItems = props.siderRightItems ? props.siderRightItems : null;
    const siderOptions = props.siderOptions ? props.siderOptions : {breakpoint: 'md', collapsedWidth: '0'};
    const siderRightOptions = props.siderRightOptions ? props.siderRightOptions : siderOptions;
    const headerItems = props.headerItems ? props.headerItems : defItemsUI; /* can be list of list if several headers on the top of each other  */
    const headerStyle = props.headerStyle ? props.headerStyle : styleDivHeaderDef;  /* can be a list of styles if several headers on the top of each other  */
    const content = props.content ? props.content : 'content to pass as props...';
    const footer = props.footer ? props.footer : null;
    /*  Styles: */
    const style = props.style ? {...styleDivLayoutDef, ...props.style} : styleDivLayoutDef;
    const foldIconStyle = props.foldIconStyle ? {...styleFoldIconDef, ...props.foldIconStyle} : styleFoldIconDef;
    const contentStyle = props.contentStyle ? props.contentStyle : styleDivContentDef;
    const footerStyle = props.footerStyle ? props.footerStyle : styleDivFooterDef;
    /*  Fold icons: see below   */
    /* end props list: ---------------------------------------------------------------- */

    const [collapsed, setCollapsed] = useState(true); /* true ==> starts with sider open    */
    const [rightCollapsed, setRightCollapsed] = useState(true);

    /*  check screen width to set collapsed as true or false    */
    useEffect(() => {
        const w = window.innerWidth;
        const maxW = gridBreakpoints[siderOptions.breakpoint];
        const maxWright = gridBreakpoints[siderRightOptions.breakpoint];
        // console.log('window width: ', w, 'max width: ', maxW);
        /* collapse if screen smaller than breakpoint */
        (w < maxW) ? setCollapsed(true) : setCollapsed(false);
        (w < maxWright) ? setRightCollapsed(true) : setRightCollapsed(false);
    }, [siderOptions.breakpoint, siderRightOptions.breakpoint]);

    /* Transform header to list if not list (in order to be able to put several headers): */
    // let headersList = (!Array.isArray(header))? [header]: header;

    const hasSider = (props.siderItems || props.siderItemsJsx) ? true : false;

    /*  Sider fold methods:   */
    const onLeftSiderCollapse = () => {
        // console.log('left collapsed?', collapsed);
        setCollapsed(!collapsed);
    };

    const onRightSiderCollapse = () => {
        // console.log('right collapsed?', rightCollapsed);
        setRightCollapsed(!rightCollapsed);
    };

    /*  Fold Icons:  */
    const foldIcon = props.foldIcon ? props.foldIcon : <MenuFoldOutlined onClick={onLeftSiderCollapse} style={foldIconStyle} />;
    const unfoldIcon = props.unfoldIcon ? props.unfoldIcon : <MenuUnfoldOutlined onClick={onLeftSiderCollapse} style={foldIconStyle} />;
    const foldIconRight = props.foldIconRight ? props.foldIconRight :
        <MenuFoldOutlined onClick={onRightSiderCollapse} style={foldIconStyle} />;
    const unfoldIconRight = props.unfoldIconRight ? props.unfoldIconRight :
        <MenuUnfoldOutlined onClick={onRightSiderCollapse} style={foldIconStyle} />;

    /*  Left and right siders collapsed icon:  */
    const leftCollapseIcon = collapsed ? foldIcon : unfoldIcon;
    const rightCollapseIcon = rightCollapsed ? foldIconRight : unfoldIconRight;

    /* Build Sider component:   */
    const sider = siderItemsJsx ?
        <SiderUIOdc
            itemsJsx={siderItemsJsx}
            onCollapse={onLeftSiderCollapse}
            collapsed={collapsed}
            {...siderOptions}
        /> :
        <SiderUIOdc
            items={siderItems}
            onCollapse={onLeftSiderCollapse}
            collapsed={collapsed}
            {...siderOptions}
        />;

    /*  Build header component: */
    const header = <HeaderUIOdc
        items={headerItems}
        style={headerStyle}
    />;

    /*  Build right sider component:    */
    const siderRight = siderRightItems ? <SiderUIOdc
        items={siderRightItems}
        onCollapse={onRightSiderCollapse}
        collapsed={rightCollapsed}
        {...siderRightOptions}
    /> : null;

    return (
        <Layout style={style}>

            {header}

            <Layout hasSider={hasSider} style={contentStyle}>

                {sider && <div style={{}}>{leftCollapseIcon}</div>}

                {sider}

                <Content style={contentStyle}>{content}</Content>

                {siderRight && <div style={{marginRight: 0, paddingBottom: '2px'}}>{rightCollapseIcon}</div>}
                {siderRight}

            </Layout>

            {footer && <div style={footerStyle}>{footer}</div>}

        </Layout>
    );
};

export const LayoutMenuOdc = (props) => {
    /* -----------------------------------
    *   Creates a dynamic Menu layout (Menu items with links etc) with header, sider (left only for now), content and footer
    *   For "UI" type layout (UI items: check boxes etc. or JSX), use LayoutUIOdc
    *   Props:
    *       - sider: <SiderMenuOdc /> component
    *       - header: <HeaderMenuOdc /> component
    *
    * ----------------------------------- */

    /* props list: -------------------------------------------------------------------- */
    /*  Content:    */
    const sider = (props.sider !== undefined) ? props.sider : <SiderMenuOdc/>;
    const header = props.header ? props.header : <HeaderMenuOdc/>;
    const content = props.content ? props.content : 'content to pass as props...';
    const footer = props.footer ? props.footer : null;
    /*  Styles: */
    const style = props.style ? {...styleDivLayoutDef, ...props.style} : styleDivLayoutDef;
    const contentStyle = props.contentStyle ? props.contentStyle : styleDivContentDef;
    const footerStyle = props.footerStyle ? props.footerStyle : styleDivFooterDef;
    /*  Fold icons: see below   */
    /* end props list: ---------------------------------------------------------------- */

    const [collapsed, setCollapsed] = useState(true); /* true ==> starts with sider open    */


    const hasSider = sider ? true : false;

    /*  Sider fold methods:   */
    const onSiderCollapse = () => {
        // console.log('left collapsed?', collapsed);
        setCollapsed(!collapsed);
    };

    /*  Fold Icons: MOVE TO SIDERMENUODC??? */
    // const foldIcon = props.foldIcon ? props.foldIcon : <MenuFoldOutlined onClick={onSiderCollapse}/>;
    // const unfoldIcon = props.unfoldIcon ? props.unfoldIcon : <MenuUnfoldOutlined onClick={onSiderCollapse}/>;
    //
    // /*  Left and right siders collapsed icon:  */
    // const leftCollapseIcon = collapsed ? foldIcon : unfoldIcon;

    return (
        <Layout style={style}>

            {header}

            <Layout hasSider={hasSider}>

                {sider}

                <Layout>

                    <Content style={contentStyle}>
                        {content}
                    </Content>

                </Layout>

            </Layout>

            {footer && <div style={footerStyle}>{footer}</div>}

        </Layout>
    );
};

