
import {
    // BarChartOutlined,
    // DashboardOutlined,
    HomeOutlined,
    // LogoutOutlined,
    // UserOutlined,
    CarOutlined,
    ReadOutlined,
} from '@ant-design/icons';

// import logoFull from '../img/ODClogo-full_green_frame.png';
import logo from '../img/ODClogo-mini_green.png';
import {UserInfo} from "./UserInfo";
import {LanguageSelectionOdc} from "../components/layout/LanguageSelectionOdc";


export const Links = {
    website: "https://www.open-dc.com",
    home: "/",
    profile: `/profile/${UserInfo.username}`,
    lang: '/lang',
    login: "/",
    // charts: "/charts",
    // maps: "/maps",
    tests: "/tests",
    insee_dt: '/insee_dt',
    documentation: "/documentation"
};

export const AppInfo = {
    name: "OpenDC webapp",
    version: {
        num: "0.0",
        date: '12/12/2022',
    },
    language: 'fr',
    url: process.env.REACT_APP_BASE_APP_URL,
    api_url: process.env.REACT_APP_BASE_API_URL,
    // logo: logoFull,
    header: {
        logo: logo,
        items: [
            {
                key: 'home',
                icon: <HomeOutlined />,
                label: 'Home',
                link: Links.home,
                description: 'Home Page',
            },
            {
                key: 'doc',
                icon: <ReadOutlined />,
                label: 'Doc',
                link: Links.documentation,
                description: 'Documentation Page',
            },
            {
                key: 'lang',
                icon: null,
                label: <LanguageSelectionOdc />,
                // link: Links.lang,
                description: 'Language options',
            },
            // {
            //     key: 'login',
            //     label: UserInfo.isAuth ? 'Logout' : 'Login',
            //     icon:  UserInfo.isAuth ? <LogoutOutlined />: <UserOutlined />,
            //     // link: Links.login
            // },
        ]
    },
    sider: {
        items: [
            {
                key: 'road_counts',
                icon: <CarOutlined />,
                label: 'Traffic counts',
                children: [
                    {
                        key: 'dashboard',
                        label: 'Dashboard',
                        icon: null,
                        link: Links.home,
                        description: "Dashboard",
                        // children: []
                    },
                    {
                        key: 'tests',
                        label: 'Tests',
                        icon: null,
                        link: Links.tests,
                        description: "Tests page",
                        // children: []
                    },
                ]
            },
            {
                key: 'insee',
                label: 'INSEE data',
                icon: null,
                children: [{
                    key: 'insee_dt',
                    label: 'Commuter data',
                    icon: null,
                    link: Links.insee_dt,
                    description: "INSEE DT",
                    // children: []
                }]
            },
            {
                key: 'doc_title',
                label: 'Documentation',
                icon: null,
                children: [{
                    key: 'doc',
                    label: 'Read me',
                    link: Links.documentation,
                    description: "Documentation",
                    icon: null,
                    // children: []
                }]
            },

        ],
    },
};

// Field names for each table:
export const DataFields = {
    allTables: {
        key: 'key',
        dataIndex: 'dataIndex',
    },
    locTable: {
        id: 'loc_id',
        name: 'loc_name',
        lat: 'latitude',
        lng: 'longitude',
    },
    cntTable: {
        id: 'count_id',
        locId: 'loc_id',

    },
    inseeDTtable: {

    }
};