/* *****************************************************************
* React translation management
*   NEED TO CHANGE PROJECT FOLDER IN ROWS:
*       - import {translFR} from "./i18n/insee/fr";
*       - import {translEN} from "./i18n/insee/fr";
*   How to use:
*       - import { useTranslation } from 'react-i18next';
*       - const { t, i18n } = useTranslation();
*       - then in code: <div>t('translation_key')</div>
*       - <LanguageSelectorOdc /> component enables to switch languages
*   Properties and methods:
*       - i18n.language ==> returns the active language
*       - i18n.changeLanguage('fr') =+> changes the active language
* See doc here:
*   https://react.i18next.com/latest/using-with-hooks
****************************************************************** */

import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';

// import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

/*  Project translation files: UPDATE PROJECT FOLDER HERE!!! */
import {translFR} from "./i18n/insee/fr";
import {translEN} from "./i18n/insee/en";
import {AppInfo} from "./data/AppInfo";

const checkMissingKeys = false;
console.log('Checking missing translation keys set to: ', checkMissingKeys);

i18n
/* ******************************************
* load translation using http -> see /public/locales
* (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
* learn more: https://github.com/i18next/i18next-http-backend
* ******************************************  */

/* Translations to be loaded from a professional CDN:
  * => https://github.com/locize/react-tutorial#step-2---use-the-locize-cdn */
// .use(Backend)

/* detect user language (https://github.com/i18next/i18next-browser-languageDetector):   */
    .use(LanguageDetector)

    /* pass the i18n instance to react-i18next:   */
    .use(initReactI18next)

    /* init i18next (all options: https://www.i18next.com/overview/configuration-options):   */
    .init({

        resources: {
            en: translEN,
            fr: translFR,
        },

        // lng: 'fr', //AppInfo.language? AppInfo.language: 'en',
        fallbackLng: AppInfo.language? AppInfo.language: 'en',  // set 'en' if the site has been designed in english initially
        debug: checkMissingKeys,   // set to true for checking missing keys

        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },

    });


export default i18n;