/* React: */
import React from 'react';
import ReactDOM from "react-dom/client";  // For React 18 compatibility
import reportWebVitals from './reportWebVitals';


/* styles: */
// import 'bootstrap/dist/css/bootstrap.min.css'; // added manually, before './index.css'
import './css/index.css'; /* where sidebars are defined. To clean */

// components
import App from './App';

const root = ReactDOM.createRoot(document.getElementById("root"));

console.log('STRICT MODE TO TURN ON!!!!!!');
// console.log('STRICT MODE ON');

root.render(
//  <React.StrictMode>
    <App />
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
