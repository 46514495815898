/* *****************************************************************
* Translation file for INSEE Bilan carbone app
****************************************************************** */
export const translEN = {
    translation: {
        /*  Header:    */
        'Home': 'Home',
        'Connection': 'Login',
        'Login': 'Login',
        /*  KPI:    */
        'Direction': 'Flow type',
        'Ville': 'City',
        'CODGEO': 'ZIP CODE',
        'Trajets Domicile-Travail': 'Home to work trips',
        'Bilan carbone Moyen': 'Average carbon emission',
        'Bilan carbone Total': 'Total carbon emission',
        'Temps moyen VP': 'Avg Driving travel time',
        'Temps moyen TC': 'Avg Transit travel time',
        '% Hors IDF': '% Outside region',
        /*  Map component:  */
        'Carte interactive': 'Interactive Map',
        'Type de carte': 'Map type',
        'Moyenne ville': 'Average city',
        'Moyenne par commune': 'Average by city',
        'Détail Flux': 'Detailed Flow',
        'Émissions': 'Residents',
        'Attractions': 'Employees',
        'Origines': 'Residents',
        'Destinations': 'Employees',
        'Visualiser': 'Visualise',
        'Légende': 'Legend',
        'Bilans carbone': 'Carbon emissions',
        'Émissions carbone': 'Carbon emissions',
        'Flux pendulaires': 'Commuters flow',
        'Détail par mode': 'Detailed modes',
        'Bilan carbone net total commune en Kg CO2': 'Total City carbon emission in Kg CO2',
        'Bilan carbone moyen par commune en Kg CO2 / déplacement': 'Average City carbon emission in Kg CO2 / trip',
        'Flux journaliers domicile-travail par commune': 'City daily Commuter trips',
        'Flux journaliers domicile-études par commune': 'City daily school trips',
        'Flux journaliers totaux (travail + études) par commune': 'City total daily trips (commuters + Students)',
        'Temps de parcours VP moyen par commune': 'Average city Car travel time',
        'Temps de parcours TC par commune': 'Average city Transit travel time',

        'Bilan carbone net OD en Kg CO2': 'OD carbon emission in Kg CO2',
        'Bilan carbone moyen OD en Kg CO2 / déplacement': 'Average OD carbon emission in Kg CO2 / trip',
        'Flux journaliers domicile-travail OD': 'OD daily Commuter trips',
        'Flux journaliers domicile-études OD': 'OD daily school trips',
        'Flux journaliers totaux (travail + études) OD': 'OD total daily trips (commuters + Students)',
        "Temps de parcours VP moyen sur l'OD": 'Average OD Car travel time',
        "Temps de parcours TC moyen sur l'OD": 'Average OD Transit travel time',
        /*  Charts component:   */
        'Données': 'Data',
        'Données ville': 'City data',
        'Parts modales': 'Mode shares',
        'Bilan Carbone Moyen': 'Average carbon emission',
        'Bilan carbone par déplacement': 'Carbon emission per trip',
        'kg CO2/depl': 'kg CO2/trip',
        'Top 10%': 'Top 10%',
        'Derniers 10%': 'Last 10%',
        'Temps de parcours VP vs TC': 'Car vs Transit travel time',
        'Données de flux détaillées': 'Detailed data',
        'Données Origines / Destinations détaillés': 'Detailed Origin / Destination data',
        'Temps de parcours': 'Travel times',
        'Tps VP': 'Car travel time',
        'Tps TC': 'Transit travel time',
        /*  Data fields:    */
        'depuis': 'from',
        'vers': 'to',
        'depuis et vers': 'from and to',
        'insee_from': 'zip origin',
        'insee_to': 'zip destination',
        'Origin': 'Origin',
        'Destination': 'Destination',
        'Bilan Carbone / depl': 'Carbon emission per trip',
        'Bilan Carbone': 'Carbon emission',
        'flow_tot': 'Commuter + Student flow',
        'mobpro_tot': 'Commuter flow',
        'mobpro_selected_modes': 'Commuter flow',
        'mobsco': 'Students flow',
        'DURATION_driving': 'Driving travel time',
        'DURATION_transit': 'Transit travel time',
        'DISTANCE_driving': 'Driving distance (km)',
        'DISTANCE_transit': 'Transit distance (km)',
        'MAIN_MODE_transit': 'Main transit mode',
        'VP': '# Car trips',
        'TC': '# PT trips',
        '2RM': '# Motor bike trips',
        'Vélo': '# Cycle trips',
        'Marche': '# Walk trips',
        'Pas de transport': '# No transport trips',
        'Tous modes': 'All modes',
        /*  Charts fields:  */
        'max_bc': 'Carbon emission deciles per trip in Kg CO2',
        /*  Modes labels, titres etc.   */
        '_VP': 'Car',
        '_TC': 'PT',
        '_2RM': 'Motor bikes',
        '_Vélo': 'Cycle',
        '_Marche': 'Walk',
        '_Pas de transport': 'na',
        '_Tous modes': 'All modes',
        /*  ODC components standard values: */
        'Download source data': 'Download source data',
    }
};

// export const translEN = {
//     translation: {
//         ui: {
//             'Carte interactive': 'Interactive Map'
//         },
//         fields: {
//             'flow_tot': 'Commuter + Student flow',
//             'mobpro_tot': 'Commuter flow',
//             'mobsco': 'Students flow',
//         },
//         comments: {
//
//         },
//     }
// };