/* *****************************************************************
* CardOdc: Ant design card for single representation: UPDATED ON 24/10/2022
* Props:
*   - title: Crad title that appear on top banner
*   - content: The card content. Can be JSX element
*   - style: the style of the card (height etc.)
*   - cardProps: all the other card props (see https://ant.design/components/card/#API)
*   - childStyle: the style of the inner <div> element
*
* See doc here: https://ant.design/components/card
********************************************************************* */
import {Card} from 'antd';

const styleDef = {
    /* Default: */
    // height: '100vh',
    margin: '1px',  /* margin outside card  */
    marginTop: 12,
    borderRadius: 15, // , overflow: 'hidden'
    overflowX: 'auto', // 'scroll', //
    overflowY: 'auto', // 'scroll', //
    /* Others: */
    // whiteSpace: 'nowrap',   /*  to make sure on same row, remove if wrap: use for KPI banner for example */
};

const cardPropsDef = {
    headStyle: {backgroundColor: 'lightgrey', paddingLeft: 5, height: 5},
    bodyStyle: {color: 'black'},
    size: 'small',
};

const childStyleDef = {
    // overflow: auto,
    width: '100%',
    height: '100%',
    // display: 'inline-block',    /* for row presentation: remove for breaking lines */
    // marginLeft: -40,
};


// export default function CardOdc({title, content, style, cardStyle}) {
export default function CardOdc(props) {
    const title = props.title ? props.title : null;
    const content = props.content ? props.content : null;
    const style = props.style ? {...styleDef, ...props.style} : styleDef;
    const cardProps = props.cardProps ? {...cardPropsDef, ...props.cardProps} : cardPropsDef;
    const childStyle = props.childStyle? {...childStyleDef, ...props.childStyle} : childStyleDef;

    return (
        <Card
            title={title}
            style={style}
            {...cardProps}
        >
            <div style={childStyle}>
                {content}
            </div>
        </Card>
    );
}